import { useState, useEffect, useContext } from 'react';
import { get_ } from 'sf-modules';
import { CommerceContext } from '../../CommerceProvider';
import { useCheckoutFunnel } from './useCheckoutFunnel';
import useGetDeliveryMethods from './useGetDeliveryMethods';
import useSetDeliveryMethod from './useSetDeliveryMethod';
import useSetShippingAddress from './useSetShippingAddress';
import { ECheckoutStepsTypes, TCheckoutFunnels } from '../../types/ECheckoutStepsTypes';

export default function useDeliveryGateway({ skip = false }) {
    const { commerceConfig, checkout: checkoutData } = useContext(CommerceContext);
    const { activeStep, ...funnel } = useCheckoutFunnel();
    const {
        loading: deliveryMethodsLoading,
        data: deliveryMethodsData,
        stockLocation,
        storeHours,
    } = useGetDeliveryMethods({ skip });

    const { setShippingAddress } = useSetShippingAddress();
    const { setDeliveryMethod } = useSetDeliveryMethod();

    const [isInitializing, setIsInitializing] = useState(true);
    const [selectedMethod, setSelectedMethod] = useState(null);

    function selectMethod(method) {
        if (method) setSelectedMethod(method);
    }

    function selectDeliveryMethod(method) {
        if (!method) return;

        switch (method.shippingType) {
            case 'immediate':
                setSelectedMethod(method);
                funnel.updateFunnel(
                    TCheckoutFunnels.immediateDelivery,
                    ECheckoutStepsTypes.DeliveryGateway
                );
                break;

            case 'store_pickup':
                setSelectedMethod(method);
                funnel.updateFunnel(
                    TCheckoutFunnels.storePickUp,
                    ECheckoutStepsTypes.DeliveryGateway
                );
                break;

            // Shipping to address
            default:
                let shouldAskForDeliveryAddress =
                    !get_(checkoutData, ['shippingAddress', 'id']) ||
                    !checkoutData.email ||
                    checkoutData.email === commerceConfig.values.DEFAULT_EMAIL;
                setSelectedMethod(method);
                funnel.updateFunnel(
                    TCheckoutFunnels.default,
                    shouldAskForDeliveryAddress
                        ? ECheckoutStepsTypes.DeliveryGateway
                        : ECheckoutStepsTypes.ShippingMethod
                );
                break;
        }
    }

    /**
     * Set delivery address
     *
     */
    async function setDeliveryGateway({
        selectedMethod,
        address = null,
        formState = null,
    }: {
        selectedMethod: any;
        address?: any;
        formState?: any;
    }) {
        const withImmediateDelivery = selectedMethod && selectedMethod.shippingType === 'immediate';
        const withStorePickup = selectedMethod && selectedMethod.shippingType === 'store_pickup';

        const deliveryAddress = {
            ...((withStorePickup || withImmediateDelivery) && get_(stockLocation, ['address'])),
            ...(formState && formState.values),
            ...address,
        };

        // Callback to set shipping address in checkout object
        let response;
        if (commerceConfig.context !== 'pos' || (!withImmediateDelivery && !withStorePickup)) {
            response = await setShippingAddress({
                address: deliveryAddress,
                addressFormState: formState,
                forceUpdateCheckout: true,
            });

            if (!response)
                return {
                    success: false,
                };
        }

        // Callback to set shipping method in checkout object in case user selected "Store pickup"
        // For automatic selection of shipping method, it will be done in shipping method component
        if (withStorePickup || withImmediateDelivery) {
            response = await setDeliveryMethod({
                id: selectedMethod.id,
                shippingType: selectedMethod.shippingType,
                forceUpdateCheckout: true,
            });

            if (!get_(response, ['success']))
                return {
                    success: false,
                    errors: get_(response, ['errors']),
                };

            if (withImmediateDelivery)
                funnel.updateFunnel(
                    TCheckoutFunnels.immediateDelivery,
                    ECheckoutStepsTypes.PaymentMethod
                );
            else if (withStorePickup)
                funnel.updateFunnel(
                    TCheckoutFunnels.storePickUp,
                    ECheckoutStepsTypes.PickupSchedule
                );

            return {
                success: true,
            };
        }

        funnel.updateFunnel(TCheckoutFunnels.default, ECheckoutStepsTypes.ShippingMethod);
        return {
            success: true,
        };
    }

    useEffect(() => {
        if (!deliveryMethodsLoading && deliveryMethodsData) {
            // Only one delivery method available: we pre-selected it
            if (!selectedMethod && deliveryMethodsData.length === 1) {
                selectDeliveryMethod(deliveryMethodsData[0]);
            } else if (!selectedMethod && deliveryMethodsData.length > 1) {
                let checkoutAddress = get_(checkoutData, ['shippingAddress']);
                let checkoutShippingMethod = get_(checkoutData, ['shippingMethod']);

                // The checkout already have a shipping method assigned
                if (checkoutAddress && checkoutShippingMethod) {
                    let method = deliveryMethodsData.find(
                        (x) => x.id === checkoutShippingMethod.id
                    );
                    selectDeliveryMethod(method ? method : deliveryMethodsData[0]);
                }

                // In Storefront only: if an address is linked to the checkout, we select the first one
                else if (
                    commerceConfig.context === 'storefront' &&
                    checkoutAddress &&
                    !checkoutShippingMethod
                ) {
                    selectDeliveryMethod(deliveryMethodsData[0]);
                }
            }

            setIsInitializing(false);
        }
    }, [deliveryMethodsLoading, deliveryMethodsData]);

    return {
        loading: isInitializing,
        deliveryMethodsData: deliveryMethodsData,
        stockLocation,
        storeHours,
        selectedMethod,
        selectMethod,
        setDeliveryGateway,
    };
}
