import { decodeIdBase64, get_ } from '../tools';

export const formatAddress = ({
    id,
    alias,
    firstName,
    lastName,
    streetAddress1,
    postalCode,
    city,
    externalNumber,
    internalNumber,
    isDefaultShippingAddress,
}) => {
    const formattedAddress =
        streetAddress1 +
        (externalNumber ? ` ${externalNumber}` : '') +
        (internalNumber ? ` Int: ${internalNumber}` : '') +
        (postalCode ? `\n${postalCode} ${city}` : '');

    return {
        id,
        alias,
        formatted_address: formattedAddress,
        person: `${firstName} ${lastName}`,
        link: '/cuenta/mis-direcciones/' + decodeIdBase64(id),
        postalCode,
        isDefault: isDefaultShippingAddress,
    };
};

export const buildFormattedAddress = (address) => {
    if (!address) return '';
    return (
        address.streetAddress1 +
        (address.streetAddress2 ? ` ${address.streetAddress2}` : '') +
        (address.externalNumber ? ` ${address.externalNumber}` : '') +
        (address.internalNumber ? `, ${address.internalNumber}` : '') +
        (address.cityArea ? `, ${address.cityArea}` : '') +
        (address.postalCode ? `, CP ${address.postalCode}` : '') +
        (address.city ? `, ${address.city}` : '') +
        (address.country && address.country.country ? `, ${address.country.country}` : '')
    );
};

export const transformAddress = ({
    alias,
    firstName,
    lastName,
    internalNumber,
    externalNumber,
    streetAddress1,
    cityArea,
    city,
    postalCode,
    countryArea,
    latitude,
    longitude,
    phone,
    references,
    isDefaultShippingAddress,
}) => ({
    alias,
    firstName,
    lastName,
    companyName: 'No aplica',
    streetAddress1,
    streetAddress2: '',
    internalNumber,
    externalNumber,
    city,
    cityArea,
    postalCode,
    country: 'MX',
    countryArea,
    phone: phone ? `+${phone.countryCode}${phone.nationalNumber}` : '',
    latitude,
    longitude,
    references,
    isDefaultShippingAddress,
});

export const reverseTransformAddress = ({
    alias,
    firstName,
    lastName,
    internalNumber,
    externalNumber,
    streetAddress1,
    cityArea,
    city,
    postalCode,
    countryArea,
    latitude,
    longitude,
    phone,
    references,
    isDefaultShippingAddress,
}) => ({
    alias,
    firstName,
    lastName,
    streetAddress1,
    internalNumber,
    externalNumber,
    city,
    cityArea,
    postalCode,
    country: 'MX',
    countryArea,
    phone: {
        nationalNumber: get_(phone, ['nationalNumber'], ''),
        countryCode: get_(phone, ['countryCode'], ''),
    },
    latitude,
    longitude,
    references: references ? references : '',
    isDefaultShippingAddress,
});

export default {
    formatAddress,
    buildFormattedAddress,
    transformAddress,
    reverseTransformAddress,
};
