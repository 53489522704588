import { APP_STATE_TYPES } from '../CommerceProvider';

export default function lastAddedSKUReducer(state, action) {
    switch (action.type) {
        case APP_STATE_TYPES.SET_LAST_ADDED_SKU:
            return action.sku;
        case APP_STATE_TYPES.REMOVE_SKU_FROM_LAST_ADDED:
            return state === action.sku ? null : state;
        default:
            return state;
    }
}
