// import { get_ } from '../tools';

/**
 * Evaluate rule built with durable_rule on PIM side
 */
export default class RulesList {
    constructor(rules) {
        this.rules = rules;
    }

    get() {
        return this.rules;
    }

    getRulesMatchingConditionType(conditionType) {
        if (!this.rules || !this.rules.length) return [];

        return this.rules.filter((rule) => {
            if (!rule || !rule.conditions) return false;

            return rule.conditions.some((condition) => condition.type === conditionType);
        });
    }

    getRulesMatchingBenefitType(benefitType) {
        if (!this.rules || !this.rules.length) return [];

        return this.rules.filter((rule) => {
            if (!rule || !rule.benefits) return false;

            return rule.benefits.some((benefit) => benefit.type === benefitType);
        });
    }
}
