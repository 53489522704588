import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import dynamic from 'next/dynamic';
import styles from './Button.module.scss';

const Link = dynamic(() => import('../Link'));
const LoadingIcon = dynamic(() => import('../../particles/LoadingIcon'), { ssr: false });

const ButtonWrapper = ({
    id,
    href,
    target,
    children,
    className,
    style,
    type,
    disabled,
    onClick,
}) => {
    // Wrap with Link component if has href prop
    if (href)
        return (
            <Link
                id={id}
                href={href}
                onClick={onClick}
                underline={false}
                className={cx(className, 'flex items-center justify-center')}
                style={style}
                disabled={disabled}
                target={target}
            >
                {children}
            </Link>
        );

    return (
        <button
            id={id}
            className={className}
            style={style}
            type={type}
            disabled={disabled}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

const Button = ({
    name,
    className,
    style,
    type,
    variants,
    size,
    disabled,
    loading,
    loadingText,
    children,
    href,
    onClick,
    target,
    ...otherProps
}) => {
    const primary = variants === 'primary' && !disabled && !loading;
    const secondary = variants === 'secondary' && !disabled && !loading;
    const tertiary = variants === 'tertiary';
    const error = variants === 'error' && !disabled && !loading;

    const rootClass = cx('font-body duration-300 ease-out min-h-11 sm:min-h-10', {
        // ROUNDED
        'rounded-xl': variants !== 'custom',
        // PRIMARY
        'bg-primary-button text-ui-white': primary,
        'hover:bg-brand-primary-900 hover:shadow-down': primary,
        // TERTIARY
        'bg-brand-tertiary-500 text-ui-black font-body-regular': tertiary,
        'hover:bg-brand-tertiary-900 hover:shadow-down': tertiary,
        // ERROR
        'bg-ui-transparent border border-ui-error text-ui-error hover:shadow-down': error,
        // SECONDARY
        'bg-ui-white border-solid border border-brand-primary-700 text-brand-primary-700':
            secondary,
        'hover:border-brand-primary-900 hover:shadow-down hover:text-brand-primary-900': secondary,
        // DISABLED & LOADING
        'inline-flex items-center justify-center gap-2 cursor-not-allowed': disabled || loading,
        'border border-ui-gray-500 bg-ui-gray-300 shadow-none text-ui-gray-500':
            (variants === 'secondary' || variants === 'error') && (disabled || loading),
        'bg-ui-gray-300 shadow-none text-ui-gray-900':
            variants === 'primary' && (disabled || loading),
        // SIZE
        [`px-3 ${styles.minWidthXs}`]: size === 'xs',
        [`px-5 ${styles.minWidthSm}`]: size === 'sm',
        [`px-6 ${styles.minWidthMd}`]: size === 'md',
        [`px-7 ${styles.minWidthLg}`]: size === 'lg',
        'w-full px-3 sm:px-4 lg:px-3': size === 'full',
    });

    return (
        <ButtonWrapper
            id={name}
            href={href}
            className={cx(rootClass, className)}
            style={style}
            type={type}
            disabled={disabled}
            onClick={onClick}
            target={target}
            {...otherProps}
        >
            {loading && loadingText ? loadingText : children}
            {loading && <LoadingIcon fill='#4F4F4F' />}
        </ButtonWrapper>
    );
};

Button.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    type: PropTypes.string,
    variants: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'error', 'custom']),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'full', 'custom']),
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.node,
        PropTypes.func,
    ]).isRequired,
    href: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object,
};

Button.defaultProps = {
    type: 'button',
    variants: 'primary',
    size: 'lg',
    disabled: false,
};

export default Button;
