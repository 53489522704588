import { gql } from '@apollo/client';

/**
 * GraphQL Fragment
 *
 */
export const PhoneFields = gql`
    fragment PhoneFields on PhoneDisplay {
        countryCode
        nationalNumber
        asInternational
    }
`;
