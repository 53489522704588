// @ts-nocheck

import React from 'react';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { get_, stringify } from 'sf-modules';
import { trackError } from '../../utils/error';

interface WithRecaptchaProps {
    disabled: boolean;
    children: React.ReactNode;
}

/**
 * RECAPTCHA WRAPPER
 */
function WithRecaptcha({ disabled = false, children }: WithRecaptchaProps) {
    if (disabled || process.env.NEXT_PUBLIC_DEPLOY_ENV === 'local') return children;

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY}
            language='es-419'
        >
            {children}
        </GoogleReCaptchaProvider>
    );
}

/**
 * RECAPTCHA HOOK
 */
function useRecaptchaValidation() {
    const { executeRecaptcha } = useGoogleReCaptcha();

    async function reCaptchaValidation(withReCaptchaValidation) {
        const oReturn = (success, score) => {
            return {
                success: success,
                score: score,
            };
        };

        if (!withReCaptchaValidation || !executeRecaptcha) return oReturn(true, null);

        return await executeRecaptcha('checkout')
            .then(async (captchaToken) => {
                const validateToken = await fetch(`${process.env.NEXT_PUBLIC_URL}/api/recaptcha`, {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: stringify({
                        response: captchaToken,
                    }),
                });

                const parseRes = await validateToken.json();
                const errors = get_(parseRes, ['error-codes'], []);

                if (!parseRes || (errors && errors.length > 0) || parseRes.score <= 0.5) {
                    return oReturn(false, parseRes ? parseRes.score : null);
                }

                return oReturn(true, null);
            })
            .catch((err) => {
                trackError({
                    label: 'Impossible to execute reCaptcha',
                    extras: {
                        reCaptcha_catch: err,
                    },
                });
                return oReturn(true, null);
            });
    }

    return {
        reCaptchaValidation,
    };
}

export { WithRecaptcha, useRecaptchaValidation };
