import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CloseIcon } from 'icons/solid';

const CloseButton = ({ isLoading, isPOS, className, onClick, position, customWidth, srText }) => {
    return (
        <>
            {isLoading ? (
                <span
                    className={cx('text-muted spinner-border spinner-border-sm mt-2 mr-2', {
                        'absolute top-0 right-0': position === 'absolute',
                    })}
                    role='status'
                />
            ) : (
                <button onClick={onClick}>
                    {srText && <span className='sr-only'>{srText}</span>}
                    <CloseIcon
                        className={cx(
                            className,
                            'mx-auto origin-center transform cursor-pointer duration-300 ease-in-out hover:scale-115',
                            customWidth ? customWidth : 'w-6',
                            { 'absolute top-0 right-0': position === 'absolute' },
                            'md:mt-.5 md:mr-.5' === isPOS
                        )}
                    />
                </button>
            )}
        </>
    );
};

CloseButton.defaultProps = {};

CloseButton.propTypes = {
    isLoading: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    position: PropTypes.string,
    customWidth: PropTypes.string,
};

export default CloseButton;
