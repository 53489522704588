import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import CloseButton from '../../assets/animations/CloseButton';

const ModalHeader = ({
    containerClassName,
    wrapperClassName,
    subtitleClassName,
    onClose,
    title,
    titleSize,
    subtitle,
    children,
    icon,
    headerBackground,
    textAlignment,
    hiddenCloseButton,
    type,
}) => {
    const baseClass = cx(
        containerClassName,
        'z-100 rounded-t-md sm:rounded-t-lg sticky top-0 flex flex-col px-4',
        [`${headerBackground ? headerBackground : 'bg-brand-tertiary-500'}`],
        [`${icon && type !== 'full' ? 'pb-6 pt-4' : 'py-3 md:py-4'}`],
        [`${type === 'full' ? 'py-3 md:py-4' : 'md:px-6'}`]
    );

    return (
        <div className={baseClass}>
            <div
                className={cx(wrapperClassName, 'relative flex w-full', {
                    'w-full flex-col md:flex-row md:items-center': type === 'full',
                })}
            >
                <div
                    className={cx('flex w-full', {
                        'sm:text-center': textAlignment === 'center',
                        'md:text-left': textAlignment === 'left',
                        'md:text-right': textAlignment === 'right',
                        'mx-auto max-w-md': icon && type !== 'leftIcon',
                        'flex-col justify-center': !type,
                        'order-2 mt-2 flex-col justify-center md:order-1 md:mt-0': type === 'full',
                        'items-center justify-start sm:mt-2': type === 'leftIcon',
                    })}
                >
                    {icon && icon}

                    {title ? (
                        <h3
                            className={cx(
                                'mb-0 flex-1 font-heading',
                                titleSize ? titleSize : 'text-h4 font-heading-medium lg:text-h3',
                                {
                                    'mt-4': icon && type !== 'leftIcon',
                                    'ml-2 text-left': type === 'leftIcon',
                                }
                            )}
                        >
                            {title}
                        </h3>
                    ) : (
                        <>{children}</>
                    )}

                    {subtitle && (
                        <p
                            className={cx(
                                subtitleClassName,
                                'mt-2 mb-0 font-body text-normal font-body-regular md:block'
                            )}
                        >
                            {subtitle}
                        </p>
                    )}
                </div>

                {typeof onClose === 'function' && !hiddenCloseButton && (
                    <>
                        {type === 'full' ? (
                            <div className='order-1 flex cursor-pointer flex-col self-end text-center font-body text-caption font-body-regular text-ui-black md:order-2 lg:right-0'>
                                <CloseButton onClick={onClose} customWidth='w-6' />
                                <span className='mt-1 hidden text-caption lg:block'>Cerrar</span>
                            </div>
                        ) : (
                            <CloseButton onClick={onClose} position={icon ? 'absolute' : ''} />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

ModalHeader.displayName = 'ModalHeader';

ModalHeader.defaultProps = {};

ModalHeader.propTypes = {
    containerClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
    subtitleClassName: PropTypes.string,
    onClose: PropTypes.func,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    titleSize: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.node,
    headerBackground: PropTypes.string,
    textAlignment: PropTypes.oneOf(['center', 'left', 'right']),
    type: PropTypes.oneOf(['full', 'leftIcon']),
    hiddenCloseButton: PropTypes.bool,
};

export default ModalHeader;
