import { gql } from '@apollo/client';
import { CheckoutFieldsFragment } from '../../fragments/checkout/CheckoutFields.gql';

const CHECKOUT_QUERY = gql`
    query Checkout($token: UUID) {
        checkout(token: $token) {
            ...CheckoutFields
        }
    }
    ${CheckoutFieldsFragment}
`;

export default CHECKOUT_QUERY;
