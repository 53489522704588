import { useState, useContext } from 'react';
import Router from 'next/router';
import { scroller } from 'react-scroll';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import useStateEngine from '../../useStateEngine';
import { get_, cookie } from 'sf-modules';
import { trackError } from '../../utils/error';
import { fnDoMutation } from '../../utils/fnDoMutation';
import useTracker from '../tracking/useTracker';
import { checkout_complete } from '../../store/actions/cartActions';
import { CommerceContext, APP_STATE_TYPES, MODAL_KIND } from '../../CommerceProvider';
import { CheckoutContext } from './CheckoutProvider';
import { useRecaptchaValidation } from '../security/ReCaptcha';
import useCheckout from './useCheckout';
import useCheckoutUpdate from './useCheckoutUpdate';
import useCheckoutEraseOutOfStock from '../../hooks/checkout/useCheckoutEraseOutOfStock';
import usePixelSoicosConversion from '../tracking/usePixelSoicosConversion';
import CHECKOUT_COMPLETE_MUTATION from '../../graphql/mutations/checkout/checkoutComplete.gql';

export default function useCheckoutComplete({ withReCaptchaValidation, withFrontTrackers }) {
    const pixelSoicosRegisterConversion = usePixelSoicosConversion(withFrontTrackers);
    const { reCaptchaValidation } = useRecaptchaValidation();

    const dispatch = useDispatch();
    const { trackEvent } = useTracker();
    const [, dispatchStateEngine] = useStateEngine();
    const { commerceConfig, checkoutReset, checkout: checkoutData } = useContext(CommerceContext);

    const { setIsPlacingOrder } = useContext(CheckoutContext);
    const { checkoutBase64Id } = useCheckout();
    const checkoutUpdate = useCheckoutUpdate();

    const [mutationCheckoutComplete] = useMutation(CHECKOUT_COMPLETE_MUTATION);

    const { loading: modalLoading, eraseOutOfStock } = useCheckoutEraseOutOfStock();

    const [invoiceStatus, setInvoiceStatus] = useState(false);
    const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
    const [errors, setErrors] = useState([]);
    const [loading, setIsLoading] = useState(false);

    // CACHED CHECKOUT LINES
    const cachedCheckoutData = { ...checkoutData };

    const verifyCheckoutErrors = (userIsLoggedIn) => {
        let checkoutErrors = [];

        if (!checkoutData) {
            checkoutErrors.push({
                field: 'checkoutComplete',
                message: 'Ocurrió un error, vuelve a cargar la página antes de reintentar.',
            });
            return checkoutErrors;
        }

        if (!userIsLoggedIn && !acceptTermsAndConditions)
            checkoutErrors.push({
                field: 'acceptTermsAndConditions',
                message: 'Tienes que aceptar los términos y condiciones de compra',
            });

        if (!userIsLoggedIn && !checkoutData.email)
            checkoutErrors.push({
                field: 'email',
                message: 'Necesitamos tu email para poder enviarte el resumen de tu compra',
            });
        else if (
            !userIsLoggedIn &&
            checkoutData.email &&
            checkoutData.email === commerceConfig.values.DEFAULT_EMAIL
        ) {
            trackError({
                label: 'Default Email Was Setted in Checkout',
                extras: { data: checkoutData },
            });
            checkoutErrors.push({
                field: 'email',
                message:
                    'Hubo un problema al agregar tu email. Lo necesitamos para poder enviarte el resumen de tu compra ¿Puedes confirmarlo?',
            });
        }

        if (!checkoutData.shippingMethod)
            checkoutErrors.push({
                field: 'shippingMethod',
                message: 'No seleccionaste tu método de entrega',
            });

        return checkoutErrors;
    };

    const completeCheckout = async (userIsLoggedIn) => {
        setIsLoading(true);

        // UI Errors
        const checkoutErrors = verifyCheckoutErrors(userIsLoggedIn);
        if (checkoutErrors && checkoutErrors.length > 0) {
            setIsLoading(false);
            return {
                orderNumber: null,
                errors: checkoutErrors,
            };
        }

        // For POS, get cashbox ID
        let cashBoxId = null;
        if (commerceConfig.context === 'pos' && commerceConfig.storage.SELECTED_CASHBOX) {
            const cookieData = cookie.get(commerceConfig.storage.SELECTED_CASHBOX, null, true);
            cashBoxId = get_(cookieData, ['cashBox', 'id']);
        }

        // Finalize checkout
        const complete = await fnDoMutation(mutationCheckoutComplete, {
            checkout: checkoutBase64Id,
            cashBoxId: cashBoxId,
            requiresInvoice: invoiceStatus,
        });

        let checkoutResponseErrors = [];

        if (typeof checkoutUpdate === 'function')
            checkoutUpdate({
                newCheckoutData: {
                    errors: get_(complete, ['data', 'checkoutComplete', 'errors'], []),
                },
            });

        // Unhandled error
        if (typeof complete === 'string') {
            checkoutResponseErrors.push({
                field: 'checkoutComplete',
                message:
                    'Ya lo estamos revisando. Mientras, no dudes en contactarnos para que te podamos ayudar a completar tu compra.',
            });
            trackError({
                label: 'Unhandled checkout complete error: ' + complete,
                extras: {
                    checkoutID: checkoutBase64Id,
                    errors: complete,
                },
            });
        }

        const completeErrors = get_(complete, ['data', 'checkoutComplete', 'errors'], []);
        if (completeErrors.length) {
            // Map errors to custom field "checkoutComplete" so they can appear at the end of checkout flow
            const paymentErrors =
                completeErrors.filter((e) => e.field === 'payment' && e.message !== '') || [];
            completeErrors.map((err) =>
                checkoutResponseErrors.push({
                    field: err.field || 'checkoutComplete',
                    message: err.message,
                })
            );

            trackEvent({
                type: 'Checkout Complete Error',
                data: {
                    category: 'Checkout',
                    checkout_id: checkoutBase64Id,
                    errors: completeErrors,
                    value: checkoutData?.totalPrice,
                    deliveryZipCode: get_(checkoutData, ['shippingAddress', 'postalCode']),
                },
            });

            setIsLoading(false);

            if (paymentErrors.length) {
                scroller.scrollTo(`payment_method`, {
                    smooth: true,
                    offset: -70,
                });
            } else {
                scroller.scrollTo(`checkout_summary`, {
                    smooth: true,
                });
            }

            return { errors: checkoutResponseErrors };
        }

        const order = get_(complete, ['data', 'checkoutComplete', 'order']);
        if (!order || !order.publicId) {
            trackError({
                label: 'Checkout complete: missing order ID',
                extras: {
                    response: complete,
                    checkoutID: checkoutBase64Id,
                    email: checkoutData.email,
                },
            });
            return complete;
        }

        setIsPlacingOrder(true);
        return {
            orderNumber: order.publicId,
            totalPaid: order.total,
        };
    };

    const handleOutOfStock = async () => {
        dispatchStateEngine({
            type: APP_STATE_TYPES.TOGGLE_MODAL,
            kind: MODAL_KIND.PRODUCT_OUT_OF_STOCK,
            open: true,
            props: {
                cachedCheckoutData: cachedCheckoutData,
                newCheckoutData: null,
                isLoading: true,
            },
        });
        const newLines = await eraseOutOfStock();

        dispatchStateEngine({
            type: APP_STATE_TYPES.TOGGLE_MODAL,
            kind: MODAL_KIND.PRODUCT_OUT_OF_STOCK,
            open: true,
            props: {
                cachedCheckoutData: cachedCheckoutData,
                newCheckoutData: newLines.newCheckoutData,
                isLoading: modalLoading,
            },
        });
    };

    const handleCheckoutComplete = async ({ userIsLoggedIn }) => {
        setIsLoading(true);

        // Recaptcha validation
        const { success, score } = await reCaptchaValidation(withReCaptchaValidation);
        if (!success) {
            const message =
                score && score <= 0.5
                    ? 'Detectamos actividad sospechosa en tu sesión, por lo cual no pudimos verificar tu compra. Por favor contáctanos al +52 56 1839 4661.'
                    : 'No pudimos verificar tu compra. Por favor contáctanos al +52 56 1839 4661.';
            const trackErrorMsg =
                score && score <= 0.5
                    ? 'reCaptcha too low score: payment rejected'
                    : 'reCaptcha error: payment rejected';
            setErrors([
                {
                    field: 'paymentMethod',
                    message: message,
                },
            ]);
            trackError({
                label: trackErrorMsg,
                extras: {
                    reCaptchaScore: score,
                    checkout: checkoutData,
                },
            });

            setErrors([
                {
                    message: 'Ocurrió un error al procesar la compra. Favor de reintentar.',
                },
            ]);
            setIsLoading(false);
        }

        // Checkout complete
        completeCheckout(userIsLoggedIn).then((result) => {
            if (!result)
                setErrors([
                    {
                        message: 'Ocurrió un error al procesar la compra. Favor de reintentar.',
                    },
                ]);
            else if (!result.orderNumber) {
                setErrors(result.errors || []);

                const stockErrors = result.errors?.filter(
                    (e) =>
                        e?.field &&
                        (e.field.split(':')[0] === 'insufficient_stock' ||
                            e.field.split(':')[0] === 'availability' ||
                            e.field.split(':')[0] === 'stock_out')
                );

                if (stockErrors.length) {
                    handleOutOfStock();
                    return;
                }
            } else {
                setErrors([]);

                // Front trackers
                if (withFrontTrackers) {
                    pixelSoicosRegisterConversion(result.orderNumber, result.totalPaid);
                }

                Router.push(
                    `${commerceConfig.paths.CHECKOUT_SUCCESS}/[order]`,
                    `${commerceConfig.paths.CHECKOUT_SUCCESS}/${result.orderNumber}`
                ).then(() => {
                    checkoutReset();
                    dispatch(checkout_complete());
                });

                return;
            }
            setIsLoading(false);
        });
    };

    return {
        loading,
        errors,

        invoiceStatus,
        setInvoiceStatus,

        acceptTermsAndConditions,
        setAcceptTermsAndConditions,

        handleCheckoutComplete,
    };
}
