import { useEffect } from 'react';
import * as PropTypes from 'prop-types';
import { trackError } from '../../utils/error';
import PageError from 'designkit/src/components/webkit/layouts/PageError';
import ErrorAlert from '@/yema-designkit/webkit/molecules/ErrorAlert';

const Error500 = ({
    title,
    context,
    customIcon,
    buttonText,
    buttonOnClick,
    extrasToTrack,
    errorMessage,
    fullPage,
    cardFooterComponent,
    errorInsideOfComponent,
}) => {
    useEffect(() => {
        if (extrasToTrack)
            trackError({
                label: 'Unexpected error',
                extras: extrasToTrack,
            });
    }, []);

    if (errorInsideOfComponent)
        return (
            <ErrorAlert
                title={title}
                errorMessage={errorMessage}
                buttonText={buttonText}
                buttonOnClick={buttonOnClick}
            />
        );

    return (
        <PageError
            title={title}
            fullPage={fullPage}
            context={context}
            customIcon={customIcon}
            cardFooterComponent={cardFooterComponent}
        >
            {extrasToTrack && (
                <ErrorAlert
                    title={extrasToTrack?.errorType}
                    errorMessage={extrasToTrack?.errorDescription}
                />
            )}
        </PageError>
    );
};

Error500.defaultProps = {
    buttonText: 'Volver a intentar',
};

Error500.propTypes = {
    title: PropTypes.string.isRequired,
    extrasToTrack: PropTypes.object,
    buttonText: PropTypes.string,
    errorInsideOfComponent: PropTypes.bool,
    buttonOnClick: PropTypes.func,
};

export default Error500;
