import { gql } from '@apollo/client';

const CHECKOUT_LOCATION_SCHEDULE_MUTATION = gql`
    mutation CheckoutLocationSchedule($checkout: ID!) {
        checkoutLocationSchedule(checkoutId: $checkout) {
            schedule {
                id
                day
                opening
                closing
                isOpen
                dayDisplay
                delivery {
                    start
                    end
                }
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_LOCATION_SCHEDULE_MUTATION;
