import { get_ } from 'sf-modules';

function timeConvert24to12(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time.splice(-1, 1); // Remove seconds
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
}

export default function parseStoreHours(hours) {
    if (!hours || !hours.length) return [];

    let parsedHours = [];
    hours.map((h) => {
        let lastRecordedOpenning = get_(parsedHours, [parsedHours.length - 1, 'opening']);
        let lastRecordedClosing = get_(parsedHours, [parsedHours.length - 1, 'closing']);
        if (!h || !h.isOpen) return;

        if (h.opening === lastRecordedOpenning && h.closing === lastRecordedClosing) {
            parsedHours[parsedHours.length - 1].days.push(h.dayDisplay);
        } else {
            parsedHours.push({
                days: [h.dayDisplay],
                opening: h.opening,
                closing: h.closing,
            });
        }
    });

    let displayHours = [];
    parsedHours.map((h) => {
        if (!h.days || !h.days.length) return;

        let daysDisplay =
            h.days.length <= 1 ? h.days[0] : `${h.days[0]} a ${h.days[h.days.length - 1]}`;

        let o = timeConvert24to12(h.opening);
        let c = timeConvert24to12(h.closing);

        displayHours.push(`${daysDisplay}: ${o} a ${c}`);
    });

    return displayHours;
}
