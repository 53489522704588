import { gql } from '@apollo/client';

export const RuleFieldsFragment = gql`
    fragment RuleFields on CartRule {
        id
        title
        description
        priority
        applyLimiter
        benefits @include(if: $withBenefits) {
            id
            evaluationType
            discountString
            objects
            includeAll
            itemQuantity
            type
        }
        conditions @include(if: $withConditions) {
            id
            evaluationType
            operator
            type
            objects
            itemQuantity
            includeAll
        }
    }
`;
