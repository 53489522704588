import { useMemo } from 'react';
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import storeStateReducer from './reducers/storeStateReducer';
import authReducer from './reducers/authReducer';
import cartReducer from './reducers/cartReducer';

let store;

const bindMiddleware = (middleware) => {
    if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'local') {
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};

const reducers = combineReducers({
    storeState: storeStateReducer,
    authentication: authReducer,
    cart: cartReducer,
});

function initStore(initialState) {
    return createStore(reducers, initialState, bindMiddleware([multi, thunk]));
}

export const initializeStore = (preloadedState) => {
    let _store = store ? store : initStore(preloadedState);

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState,
        });
        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if (typeof window === 'undefined') return _store;
    // Create the store once in the client
    if (!store) store = _store;

    return _store;
};

export function useStore(initialState) {
    return useMemo(() => initializeStore(initialState), [initialState]);
}
