import { get_ } from '../tools';

function getAvailabilityPzKg(product, availability, saleByWeight) {
    // Simulates availability while all is initializing
    if (!product || !availability) return { pzAvailable: 100, kgAvailable: 100 };

    const checkoutMaxQty =
        Math.min(availability.checkoutMaxQuantity, availability.itemsAvailable) -
        (product.productsGifted || 0);

    let pzAvailable =
        saleByWeight && product.stepperIncrement?.value
            ? Math.floor(checkoutMaxQty / product.stepperIncrement.value)
            : checkoutMaxQty;

    let kgAvailable = saleByWeight ? checkoutMaxQty : null;

    if (product.stepperIncrement?.value > kgAvailable) return { pzAvailable: 0, kgAvailable: 0 };

    return { pzAvailable, kgAvailable };
}

export const getCheckoutMaxQuantity = (stock, checkoutMaxQuantity) => {
    if (Number.isNaN(parseInt(stock)) && !Number.isNaN(parseInt(checkoutMaxQuantity)))
        return checkoutMaxQuantity;
    if (Number.isNaN(parseInt(checkoutMaxQuantity)) && !Number.isNaN(parseInt(stock))) return stock;
    if (Number.isNaN(parseInt(stock)) && Number.isNaN(parseInt(checkoutMaxQuantity))) return 0;
    return Math.min(stock, checkoutMaxQuantity);
};

export function parseAvailability(zipCode, product, bundleOrProductAvailability = null) {
    const stock = get_(bundleOrProductAvailability, ['itemsAvailable'], 50);
    const availability = {
        checkoutMaxQuantity: getCheckoutMaxQuantity(stock, product.checkoutMaxQuantity),
        availableOnZipCode: !bundleOrProductAvailability
            ? true
            : bundleOrProductAvailability.availableOnZipCode,
        itemsAvailable: stock,
        status: get_(bundleOrProductAvailability, ['status']),
    };

    const saleByWeight = product?.sellingType && product.sellingType !== 'por-pieza';
    const { pzAvailable, kgAvailable } = getAvailabilityPzKg(product, availability, saleByWeight);

    if (
        availability.itemsAvailable === 0 ||
        (saleByWeight && kgAvailable < product?.stepperIncrement?.value)
    )
        availability.status = 'stock-out';
    if (!availability.availableOnZipCode) availability.status = 'unavailable';
    if (zipCode) availability.zipCode = zipCode;

    return {
        ...availability,
        pzAvailable,
        kgAvailable,
    };
}

export function getProductBreadcrumb(product, isBundle = false) {
    const AISLES_INITIAL_STATE = [
        {
            link: '/',
            title: 'Inicio',
        },
    ];

    const getMainAisle = () => {
        const mainAisle = get_(product, ['aisles'], []).find((aisle) => aisle.main === true);
        return mainAisle ? mainAisle : get_(product, ['aisles', 0]);
    };

    if (!product) return AISLES_INITIAL_STATE;

    const breadcrumb = [...AISLES_INITIAL_STATE];

    let aisles = isBundle ? get_(product, ['bundleAisle']) : getMainAisle();

    if (aisles && aisles.firstLevel && aisles.firstLevel.name && aisles.firstLevel.slug) {
        breadcrumb.push({
            link: '/c/' + aisles.firstLevel.slug,
            title: aisles.firstLevel.name,
        });

        if (aisles.secondLevel && aisles.secondLevel.name && aisles.secondLevel.slug)
            breadcrumb.push({
                link: '/c/' + aisles.firstLevel.slug + '/' + aisles.secondLevel.slug,
                title: aisles.secondLevel.name,
            });

        if (aisles.thirdLevel && aisles.thirdLevel.name && aisles.thirdLevel.slug)
            breadcrumb.push({
                link: '/c/' + aisles.secondLevel.slug + '/' + aisles.thirdLevel.slug,
                title: aisles.thirdLevel.name,
            });
    }

    breadcrumb.push({
        route: (isBundle ? '/k/' : '/p/') + product.slug,
        title: product.name,
    });

    return breadcrumb;
}

export const getBlobs = (blobs = []) => {
    if (!blobs || blobs.length === 0) {
        return [];
    }

    return blobs.map((blob) => {
        return {
            borderColor: blob.borderColor ?? '',
            borderRadius: blob.borderRadius ?? '',
            borderStyle: blob.borderStyle ?? '',
            borderWidth: blob.borderWidth ?? '',
            color: blob.color ?? '',
            fontColor: blob.fontColor ?? '',
            word: blob.word ?? '',
        };
    });
};

export const getCertifications = (certifications = []) => {
    if (!certifications || certifications.length === 0) {
        return [];
    }

    return certifications.map((certification) => {
        return {
            name: certification.name ?? '',
            description: certification.description ?? '',
            external: certification.external ?? '',
            logoUrl: certification.logoUrl ?? '',
        };
    });
};

export const getFacets = (facets = []) => {
    if (!facets || facets.length === 0) return [];

    return facets
        .filter((facet) => facet.isMain && facet.value?.name)
        .map((facet) => {
            return {
                isMain: facet.isMain,
                name: facet.value.name,
                description: facet.description ?? '',
            };
        });
};

export const getReviews = (reviews = []) => {
    if (!reviews || reviews.length === 0) {
        return [];
    }

    return reviews.map((review) => {
        return {
            content: review.node.content ?? '',
            created: review.node.created ?? '',
            isVerified: review.node.isVerified ?? true,
            rating: review.node.rating ?? 0,
            reviewerName: review.node.reviewerName ?? '',
            title: review.node.title ?? '',
        };
    });
};

export default {
    parseAvailability,
    getCheckoutMaxQuantity,
    getProductBreadcrumb,
    getBlobs,
    getCertifications,
    getFacets,
    getReviews,
};
