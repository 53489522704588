import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';
import { InformationIcon, WarningIcon, CloseCircleIcon } from 'icons/solid';

const Icon = ({ icon, variant, iconClassName }) => {
    if (icon && icon !== '') return icon;

    switch (variant) {
        case 'error':
            return <CloseCircleIcon className={iconClassName + ' text-ui-error'} />;
        case 'warning':
        case 'max-limit':
            return <WarningIcon className={iconClassName} />;
        case 'informative':
        default:
            return <InformationIcon className={iconClassName} />;
    }
};

const Alert = ({
    variant,
    icon,
    iconClassName,
    textClassName,
    title,
    text,
    children,
    className,
}) => {
    const rootClass = cx(className, 'border-l-8 font-body text-ui-black', {
        'bg-brand-tertiary-100 border-ui-warning': variant === 'warning',
        'bg-ui-error border-ui-error bg-opacity-10 ': variant === 'error',
        'bg-ui-alert border-ui-alert bg-opacity-10 ': variant === 'max-limit',
        'bg-brand-secondary-700 border-brand-secondary-700 bg-opacity-10 ':
            variant === 'informative',
    });

    return (
        <div className={rootClass}>
            <div className={`flex ${children ? 'mb-2' : ''}`}>
                <Icon icon={icon} variant={variant} iconClassName={iconClassName} />
                <p className={cx(textClassName, 'mb-0 text-inherit font-w-inherit')}>
                    {title && (
                        <>
                            <span>{title}</span>
                            <br />
                        </>
                    )}
                    {text && <span className='font-body-regular'>{text}</span>}
                </p>
            </div>

            {children}
        </div>
    );
};

Alert.defaultProps = {
    variant: 'informative',
    className: 'p-2 my-4',
    iconClassName: 'shrink-0 w-6 mr-2',
};

Alert.propTypes = {
    variant: PropTypes.oneOf(['warning', 'error', 'informative', 'max-limit']),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    iconClassName: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.node,
    className: PropTypes.string,
};

export default Alert;
