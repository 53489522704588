// @ts-nocheck

import React, { useState, useEffect, useContext } from 'react';
import { useRouter } from 'next/router';
import { get_ } from 'sf-modules';
import { CartProblemIcon } from 'icons/solid';
import { fnDoMutation } from '../../utils/fnDoMutation';
import { checkout_update } from '../../store/actions/cartActions';
import { useSelector, useDispatch } from '../../StoreClient';
import { useMutation } from '../../APIClient';
import useStateEngine from '../../useStateEngine';
import useStore from '../store/useStore';
import { APP_STATE_TYPES, CommerceContext, MODAL_KIND } from '../../CommerceProvider';
import CHECKOUT_UPDATE_OR_CREATE_MUTATION from '../../graphql/mutations/checkout/checkoutUpdateOrCreate.gql';

export default function usePOSConfig({
    signOut,
    selectedPos,
    setSelectedPos,
    selectedStore,
    setSelectedStore,
}) {
    const router = useRouter();

    const auth = useSelector(({ authentication }) => authentication);
    const checkoutBase64Id = useSelector(({ cart }) => get_(cart, ['checkoutID']));

    const { commerceConfig } = useContext(CommerceContext);
    const dispatchHook = useDispatch();

    const { setUpdatePos } = useStore();
    const [{ catalog }, dispatch] = useStateEngine();

    const [loadingPos, setLoadingPos] = useState(false);
    const [loadingStores, setLoadingStores] = useState(false);

    const [mutationInitializeEmptyCheckout] = useMutation(CHECKOUT_UPDATE_OR_CREATE_MUTATION);

    // Set default or first POS
    useEffect(() => {
        if (catalog && catalog.createdAt) {
            let currentPOS = selectedPos || catalog?.pos;
            let currentStore = selectedStore || catalog?.store;

            if (!selectedPos || selectedPos.id !== currentPOS?.id) setSelectedPos(currentPOS);

            if (!selectedStore || selectedStore.id !== currentStore?.id)
                setSelectedStore(currentStore);

            if (!currentPOS) {
                dispatch({
                    type: APP_STATE_TYPES.TOGGLE_MODAL,
                    kind: MODAL_KIND.ALERT,
                    open: true,
                    props: {
                        modalTitleIcon: (
                            <CartProblemIcon className='text-brand-tertiary-700 mx-auto block w-20' />
                        ),
                        modalTitle: 'Faltan configuraciones para esa cuenta de POS',
                        modalTextNode: (
                            <p className='mb-0 text-center'>
                                El POS no ha sido inicializado correctamente. Favor de iniciar
                                sesión de nuevo y seleccionar nuevamente el POS y la caja.
                            </p>
                        ),
                        confirmText: 'Entendido y cerrar sesión',
                        onConfirmClick: () => {
                            signOut();
                            router.reload();
                        },
                        disableCloseModal: true,
                    },
                });
            }
        }
    }, [catalog]);

    const handleConfirmPosAndStore = async (newPos, newStore = null) => {
        if (newPos.id) {
            if (!get_(newPos, ['stockLocation', 'address', 'postalCode'])) {
                let stockLocationName = get_(newPos, ['stockLocation', 'name']);
                dispatch({
                    type: APP_STATE_TYPES.TOGGLE_MODAL,
                    kind: MODAL_KIND.ALERT,
                    open: true,
                    props: {
                        modalTitleIcon: (
                            <CartProblemIcon className='text-brand-tertiary-700 mx-auto block w-20' />
                        ),
                        modalTitle: 'Faltan configuraciones para esa cuenta de POS',
                        modalTextNode: (
                            <p className='mb-0 text-center'>
                                {stockLocationName
                                    ? `La ubicación de stock ${stockLocationName} no tiene dirección configurada`
                                    : 'Esa cuenta de POS no tiene ubicación de stock configurada'}
                                <br />
                                <br />
                                Solicita la configuración correcta en el canal #pos de Slack e
                                inicia sesión de nuevo.
                            </p>
                        ),
                        confirmText: 'Entendido y cerrar sesión',
                        onConfirmClick: () => {
                            signOut();
                            router.reload();
                        },
                        disableCloseModal: true,
                    },
                });
                return false;
            }

            setLoadingPos(true);
            setLoadingStores(true);

            let newCheckoutBase64Id = checkoutBase64Id;
            if (!checkoutBase64Id) {
                const posResponsible = get_(selectedPos, ['responsible', 'email'], auth?.email);
                await fnDoMutation(mutationInitializeEmptyCheckout, {
                    token: '',
                    lines: [],
                    email:
                        posResponsible && posResponsible !== ''
                            ? posResponsible
                            : commerceConfig.values.DEFAULT_EMAIL,
                    zipCode: get_(selectedPos, ['stockLocation', 'address', 'postalCode']),
                    withStoreFields: true,
                }).then((res) => {
                    newCheckoutBase64Id = get_(res, [
                        'data',
                        'checkoutUpdateOrCreate',
                        'checkout',
                        'id',
                    ]);
                    if (newCheckoutBase64Id) dispatchHook(checkout_update(newCheckoutBase64Id));
                });
            }

            return await setUpdatePos({
                catalog: catalog,
                dispatch: dispatch,
                checkoutBase64Id: newCheckoutBase64Id,
                zipCode: get_(newPos, ['stockLocation', 'address', 'postalCode']),
                addressId: get_(newPos, ['stockLocation', 'address', 'id']),
                posName: get_(newPos, ['name']),
                posId: newPos.id,
                storeId: get_(newStore, ['id']) || get_(newPos, ['defaultStore', 'id']),
            }).then((res) => {
                const newAssignedStore = get_(res, [
                    'data',
                    'checkoutPosUpdate',
                    'checkout',
                    'store',
                ]);
                if (newAssignedStore?.id) {
                    setSelectedPos(newPos);
                    setSelectedStore(newAssignedStore);
                }

                setLoadingPos(false);
                setLoadingStores(false);
                return true;
            });
        }
    };

    return {
        loadingPos: loadingPos,
        loadingStores: loadingPos || loadingStores,
        handleConfirmPosAndStore,
    };
}
