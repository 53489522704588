import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { get_ } from 'sf-modules';
import { fnDoMutation } from '../../utils/fnDoMutation';
import useCheckout from './useCheckout';
import useCheckoutUpdate from './useCheckoutUpdate';
import CHECKOUT_EMAIL_UPDATE_MUTATION from '../../graphql/mutations/checkout/checkoutEmailUpdate.gql';

export default function useSetCheckoutEmail() {
    const authState = useSelector(({ authentication }) => authentication);

    const { checkoutBase64Id } = useCheckout();
    const checkoutUpdate = useCheckoutUpdate();

    const [checkoutEmailUpdate] = useMutation(CHECKOUT_EMAIL_UPDATE_MUTATION);

    const setCheckoutEmail = async ({
        email = null,
        forceUpdateCheckout = false,
    }: {
        email?: string;
        forceUpdateCheckout?: boolean;
    }) => {
        const authEmail = Boolean(authState && authState.token) ? authState.email : null;
        const emailToSet = email ? email : authEmail;

        if (!emailToSet) return false;

        return await fnDoMutation(checkoutEmailUpdate, {
            checkout: checkoutBase64Id,
            email: emailToSet,
        }).then((res) => {
            if (forceUpdateCheckout)
                checkoutUpdate({
                    newCheckoutData: get_(res, ['data', 'checkoutEmailUpdate', 'checkout']),
                    updateLines: false,
                });

            return res;
        });
    };

    return {
        setCheckoutEmail,
    };
}
