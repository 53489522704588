import { stringify, time } from 'sf-modules';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';

/** SHIPPING DELIVERY OPTIONS */
const SHIPPING_DELIVERY_OPTIONS_DAYS_MAX = 2;
const SHIPPING_DELIVERY_MINUTES_INTERVAL = 90;
const STOCK_LOCATION_OPENING_HOUR = 9;
const STOCK_LOCATION_CLOSING_HOUR = 21;

/**
 * Format delivery moment & stringify it
 * @param start
 * @param end
 * @returns {string}
 */
const getStringifiedMoment = (start, end) =>
    stringify({
        deliveryStart: dayjs(start).format(),
        deliveryEnd: dayjs(end).format(),
    });

/**
 * Build list of possible delivery time range
 * @param schedule Schedule of store location opening & closing hours
 * @param scheduledDeliveryDaysLimit
 * @return {[]|*[]}
 */
export function getDeliveryScheduleList({ schedule, scheduledDeliveryDaysLimit = null }) {
    if (!schedule || !schedule.length) return [];

    const scheduleList = [];
    const nowStrictDate = new Date();
    const nowRoundedDate = time.getRoundedDate(10, nowStrictDate);
    const isTheSameDay = dayjs(nowStrictDate).isSame(nowRoundedDate, 'day');
    const now = isTheSameDay ? nowRoundedDate : nowStrictDate;
    now.setMinutes(now.getMinutes());

    const START_DAY_COUNT = 1;
    let MAX_DAYS_OPTIONS = scheduledDeliveryDaysLimit || SHIPPING_DELIVERY_OPTIONS_DAYS_MAX;

    // Generate options for MAX_DAYS_OPTIONS number of options
    for (let i = START_DAY_COUNT; i <= MAX_DAYS_OPTIONS; i++) {
        let currentDate = new Date(now);

        // Set current date to next day
        currentDate.setDate(currentDate.getDate() + (i - START_DAY_COUNT));

        let dayNb = currentDate.getDay();
        dayNb = dayNb === 0 ? 7 : dayNb;

        // Get schedule for this day
        const thisDaySchedule = schedule.find((e) => e.day === dayNb);
        const thisDayDeliveryOptions = thisDaySchedule.delivery;

        if (!thisDaySchedule || !thisDayDeliveryOptions) return [];
        if (!thisDaySchedule.isOpen) {
            if (MAX_DAYS_OPTIONS <= 15) MAX_DAYS_OPTIONS += 1;
            continue;
        }

        // Get first delivery option of day
        const [initDeliveryHours, initDeliveryMinutes, initDeliverySeconds = '00'] =
            thisDayDeliveryOptions[0]?.start.split(':') || [];

        // Format day
        let textFormattedDay = new Intl.DateTimeFormat('es-MX', {
            month: 'short',
            weekday: 'long',
            day: '2-digit',
        }).format(currentDate);

        if (currentDate.getDay() === now.getDay()) {
            textFormattedDay = 'hoy';
        } else {
            // Set delivery start time to next day
            currentDate = time.setTimeHour(
                currentDate,
                initDeliveryHours,
                initDeliveryMinutes,
                initDeliverySeconds
            );
            if (currentDate.getDay() === now.getDay() + 1) textFormattedDay = 'mañana';
        }

        // Generate available delivery moments in this day
        thisDaySchedule.delivery.forEach((deliveryOption) => {
            const [startDeliveryHours, startDeliveryMinutes, startDeliverySeconds = '00'] =
                deliveryOption?.start.split(':') || [];

            const [endDeliveryHours, endDeliveryMinutes, endDeliverySeconds = '00'] =
                deliveryOption?.end.split(':') || [];

            const startDeliveryTime = time.setTimeHour(
                new Date(currentDate),
                startDeliveryHours,
                startDeliveryMinutes,
                startDeliverySeconds
            );

            const endDeliveryTime = time.setTimeHour(
                new Date(currentDate),
                endDeliveryHours,
                endDeliveryMinutes,
                endDeliverySeconds
            );

            const start = time.getScheduleFormattedTime(dayjs(startDeliveryTime));
            const end = time.getScheduleFormattedTime(dayjs(endDeliveryTime));

            const moment = {
                text:
                    textFormattedDay === 'hoy' && scheduleList.length <= 0
                        ? 'hoy, lo antes posible'
                        : `${textFormattedDay}, ${start} - ${end}`,
                id: getStringifiedMoment(startDeliveryTime, endDeliveryTime),
            };

            scheduleList.push(moment);
        });
    }

    return scheduleList;
}

/**
 * Guess next delivery available moment
 *  -> this is a hack in case Saleor give no schedule moment
 *  -> //@TODO LUDO: should be fixed in Saleor
 * @returns {string}
 */
export function guessNextDeliveryMoment() {
    const now = time.getRoundedDate(30, new Date());
    const hour = now.getHours();

    if (hour > STOCK_LOCATION_CLOSING_HOUR) now.setDays(now.getDays + 1);

    if (hour < STOCK_LOCATION_OPENING_HOUR || hour > STOCK_LOCATION_CLOSING_HOUR)
        now.setHours(STOCK_LOCATION_OPENING_HOUR + 1);

    const endInterval = new Date(now);
    endInterval.setMinutes(now.getMinutes() + SHIPPING_DELIVERY_MINUTES_INTERVAL);

    return getStringifiedMoment(now, endInterval);
}

export const getScheduleDate = (date) => (date ? date : guessNextDeliveryMoment());

/**
 * Check if selected schedule moment is past
 * @param moment
 * @returns {boolean}
 */
export function isScheduleMomentPast(moment) {
    const momentDate = new Date(moment);
    const nextSlotDate = new Date();
    nextSlotDate.setMinutes(nextSlotDate.getMinutes());

    return momentDate >= nextSlotDate;
}
