import transformBundleFromPIM from './bundleFromPIM';
import transformProductFromPIM from './productFromPIM';

const transformProductOrBundleFromPIM = (
    isBundle,
    bundleOrProduct,
    availability = null,
    nullIfDoesNotExists = false
) => {
    if (isBundle)
        return transformBundleFromPIM({
            bundle: bundleOrProduct,
            availability: availability,
            nullIfDoesNotExists: nullIfDoesNotExists,
        });

    return transformProductFromPIM({
        product: bundleOrProduct,
        availability: availability,
        nullIfDoesNotExists: nullIfDoesNotExists,
    });
};

export default transformProductOrBundleFromPIM;
