import money from '../money';

export const PRODUCT_EXAMPLE = {
    pricing: {
        formattedPrice: money.formatMoney(95),
        price: 95,
        formattedPriceUndiscounted: money.formatMoney(100),
        priceUndiscounted: 100,
        discount: money.getDiscount(95, 100),
        formattedTotalPrice: money.formatMoney(230),
    },
    availability: {
        availableOnZipCode: true,
        checkoutMaxQuantity: 791,
        itemsAvailable: 791,
        status: 'available',
    },
    backgroundColor: '',
    brand: 'Yema',
    description:
        'Nuestro Bocadillo de Dátil y Coco sabor a Frambuesa es 100% natural, sin gluten y sin aditivos artificiales. Ideal para cualquier momento del día.',
    isAvailable: true,
    isBundle: false,
    longDescription:
        '¡Déjate sorprender por su delicioso sabor a frambuesa!\n' +
        '\n' +
        'Nuestro Bocadillo de Dátil y Coco sabor a Frambuesa puede consumirse en cualquier momento del día como colación o postre.\n' +
        '\n' +
        'Disfruta un momento dulce, y compártelo con tu familia y amigos.',
    name: 'Bocadillo de Dátil y Coco Sabor a Frambuesa',
    quantity: 25,
    customerQuantity: 25,
    productsGifted: 0,
    seoDescription:
        'Nuestro Bocadillo de Dátil y Coco sabor a Frambuesa es 100% natural, sin gluten y sin aditivos artificiales. Ideal para cualquier momento del día.',
    seoTitle: 'Bocadillo de Dátil y Coco Sabor a Frambuesa',
    sku: '1234',
    slug: 'bocadillo-de-datil-y-coco-sabor-a-frambuesa',
    stock: 100, // @TODO Ludo: should deprecate this field
    aisles: [
        {
            as: '/c/comida',
            title: 'Comida',
        },
        {
            as: '/c/comida/botanas',
            title: 'Botanas',
        },
        {
            as: '/c/cotanas/galletas',
            title: 'Galletas',
        },
    ],
    blobs: [
        {
            color: 'rgba(248,145,106,0.3)',
            borderColor: null,
            borderRadius: '10px',
            borderStyle: null,
            borderWidth: null,
            fontColor: '',
            word: 'Bocadillo',
        },
        {
            color: 'rgba(246,178,143,1)',
            borderColor: null,
            borderRadius: '20px',
            borderStyle: null,
            borderWidth: null,
            fontColor: '',
            word: 'de',
        },
        {
            color: 'rgba(184,84,7,1)',
            borderColor: null,
            borderRadius: '',
            borderStyle: null,
            borderWidth: null,
            fontColor: 'rgba(255,255,255,1)',
            word: 'D\u00e1til',
        },
        {
            color: 'rgba(252,177,130,1)',
            borderColor: null,
            borderRadius: '25px',
            borderStyle: null,
            borderWidth: null,
            fontColor: '',
            word: 'y',
        },
        {
            color: 'rgba(197,133,79,1)',
            borderColor: null,
            borderRadius: '10px',
            borderStyle: null,
            borderWidth: null,
            fontColor: 'rgba(255,255,255,1)',
            word: 'Coco',
        },
        {
            color: '',
            borderColor: null,
            borderRadius: '25px',
            borderStyle: 'solid',
            borderWidth: '1px',
            fontColor: '',
            word: 'Sabor',
        },
        {
            color: '',
            borderColor: null,
            borderRadius: '10px',
            borderStyle: 'solid',
            borderWidth: '1px',
            fontColor: '',
            word: 'a',
        },
        {
            color: 'rgba(250,5,82,1)',
            borderColor: null,
            borderRadius: '',
            borderStyle: null,
            borderWidth: null,
            fontColor: 'rgba(255,255,255,1)',
            word: 'Frambuesa',
        },
    ],
    certifications: [
        {
            value: 'Vegetariano',
            logoUrl:
                'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/certificate_e4693e88-39b7-418d-b7c8-398baf2bab79',
            name: 'Vegetariano',
        },
        {
            value: 'Sin gluten',
            logoUrl:
                'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/certificate_5c913646-fedc-4ac8-8958-4d800213c78d',
            name: 'Acelmex (Sin Gluten)',
        },
    ],
    facets: [
        {
            name: { value: '100% natural' },
            description:
                'Todos los ingredientes que conforman este producto provienen de fuentes naturales. \u00a1Disfruta lo sabroso de manera natural!',
            isMain: true,
        },
        {
            name: { value: 'Sin grasas trans' },
            description: '',
            isMain: true,
        },
        {
            name: { value: 'Canad\u00e1' },
            description:
                'Canad\u00e1 es un pa\u00eds muy avanzado en desarrollo de productos better-for-you. Cuando no encontramos alg\u00fan producto o calidad en M\u00e9xico, nos volteamos a pa\u00edses que van desarrollando productos de tendencia saludable. Estamos muy emocionados de traerte este producto innovador para que lo disfrutes aqu\u00ed en M\u00e9xico.',
            isMain: true,
        },
        {
            name: { value: 'Sin saborizante artificial' },
            description:
                'El sabor natural proviene de una planta (fruta, especia, ra\u00edz, etc.) o de un animal (carne, mariscos, etc.), mientras que un sabor artificial es creado desde cero de manera sint\u00e9tica. Este producto no contiene saborizantes artificiales.',
            isMain: true,
        },
        {
            name: { value: 'Certificaci\u00f3n sin gluten' },
            description:
                'Este producto es certificado sin gluten por la ACELMEX, la cual garantiza que en su composici\u00f3n o proceso no contiene trazos de gluten. Algunas personas son al\u00e9rgicas (celiacas) y otras intolerantes al gluten - la prote\u00edna que se encuentra en el trigo, la cebada y el centeno, entre otros.',
            isMain: true,
        },
        {
            name: { value: 'Sin conservador artificial' },
            description:
                'Los conservadores ayudan a la preservaci\u00f3n de los productos, retardando el crecimiento de microorganismos que provocan su descomposici\u00f3n. Sin embargo, diversos estudios demuestran que ciertos conservadores artificiales pueden propiciar enfermedades como diabetes y c\u00e1ncer. Estamos orgullosos que este producto no lleve ni un conservador artificial.',
            isMain: true,
        },
    ],
    format: {
        mainSlug: 'netContent',
        mainValue: '160 g',
        netContent: '160 g',
        unitCost: 0,
        unitCount: 0,
        unitPricingBaseMeasure: '',
        unitPricingBaseMeasureUnit: '',
    },
    images: {
        hoverUrl: '',
        mainAlt: '',
        mainUrl:
            'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/bocadillo-datil-frambuesa-1-1_c0c819fd-1cd5-4abe-9086-9defe9d6fb10',
        items: [
            {
                alt: '',
                url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/bocadillo-datil-frambuesa-1-1_c0c819fd-1cd5-4abe-9086-9defe9d6fb10',
            },
            {
                alt: '',
                url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/bocadillo-datil-frambuesa-2-1_f23fad58-33c2-46e3-a90a-e17c22abf2b1',
            },
            {
                alt: '',
                url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/bocadillo-frambuesa_7a685d5e-e092-4372-96c1-050359100bfd',
            },
        ],
    },
    ingredients: [
        {
            name: 'D\u00e1til',
        },
        {
            name: 'Arroz inflado integral',
        },
        {
            name: 'Mantequilla',
        },
        {
            name: 'Aceite de coco',
        },
        {
            name: 'Polvo de frambuesa deshidratada',
        },
        {
            name: 'Coco',
        },
        {
            name: 'Jugo de ca\u00f1a',
        },
        {
            name: 'Agua',
        },
        {
            name: 'Harina de arroz',
        },
        {
            name: 'Tocoferoles concentrados',
        },
        {
            name: 'Sal de mar',
        },
    ],
    parsedUnitPricingBaseMeasure: null,
    products: [],
    reviews: {
        average: 4.75,
        count: 8,
        oneStarReviewCount: 0,
        twoStarReviewCount: 0,
        threeStarReviewCount: 1,
        fourStarReviewCount: 0,
        fiveStarReviewCount: 7,
        items: [
            {
                reviewerName: 'Marylob',
                rating: 3.0,
                title: 'Lugar',
                content: 'es entrega a domicilio\n',
                isVerified: false,
                created: '2020-06-16T18:55:17.042869-05:00',
            },
            {
                reviewerName: 'Emmanuel',
                rating: 5.0,
                title: 'Gran sabor',
                content: 'MUUUY RICO, altamente recomendable. Sin az\u00facar.',
                isVerified: true,
                created: '2020-05-27T16:16:32.103888-05:00',
            },
            {
                reviewerName: 'Araceli L. ',
                rating: 5.0,
                title: 'Gran sabor',
                content:
                    'Es muy rico en todos los postres que lo he utilizado, le aporta un rico e intenso sabor. Lo recomiendo ampliamente!  Y lo mejor es que es un alimento libre en energ\u00eda. :)',
                isVerified: true,
                created: '2020-05-27T11:25:54.174210-05:00',
            },
            {
                reviewerName: 'Araceli',
                rating: 5.0,
                title: 'Deliiiiii ',
                content: 'MUY RICO, es como muy esponjoso... jaja :)',
                isVerified: true,
                created: '2020-05-21T14:00:52.129841-05:00',
            },
            {
                reviewerName: 'Paulina',
                rating: 5.0,
                title: 'Delicioso para bebidas y postres',
                content:
                    'Es delicioso y adem\u00e1s lo utiliz\u00f3 para mis smoothies y hasta en postres',
                isVerified: true,
                created: '2020-05-20T10:41:16.140264-05:00',
            },
            {
                reviewerName: 'Marcos',
                rating: 5.0,
                title: 'Muy bueno para smoothies',
                content:
                    'Con dos cucharadas en un smoothie de banano, se vuelve mucho mas rico y sano',
                isVerified: true,
                created: '2020-05-18T11:45:48.356734-05:00',
            },
            {
                reviewerName: 'Fer',
                rating: 5.0,
                title: 'Mi superfood favorito',
                content: 'Mi fiel aliado todas las ma\u00f1anas en mis licuados!',
                isVerified: false,
                created: '2020-05-14T15:07:43.304022-05:00',
            },
            {
                reviewerName: 'Cynthia ',
                rating: 5.0,
                title: 'Es riqu\u00edsimo ',
                content:
                    'Sabe delicioso con licuados, es la segunda vez que lo compro porque se me acaba rapid\u00edsimo! ',
                isVerified: true,
                created: '2020-05-13T18:11:04.486218-05:00',
            },
        ],
    },
    route: {
        as: '/p/bocadillo-de-datil-y-coco-sabor-a-frambuesa',
    },
};

export default PRODUCT_EXAMPLE;
