import { isNumber } from '../tools';

function truncate(value) {
    if (Math.trunc) return Math.trunc(value);

    if (!isNumber(value)) return NaN;

    if (value > 0) return Math.floor(value);

    return Math.ceil(value);
}

export default {
    truncate,
};
