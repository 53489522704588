import { get_ } from 'sf-modules';

export default function checkoutVoucher(checkout) {
    if (!checkout || !checkout.id) return { voucher: null };

    return {
        voucher: get_(checkout, ['voucherCode'])
            ? {
                  voucherCode: get_(checkout, ['voucherCode'], ''),
                  voucherName: get_(checkout, ['discountName'], ''),
                  voucherAmount: get_(checkout, ['voucherDiscount']),
              }
            : {
                  voucherCode: null,
                  voucherName: '',
              },
    };
}
