import { gql } from '@apollo/client';

export const PaymentFieldsFragment = gql`
    fragment PaymentFields on Payment {
        id
        token
        cashPaymentBreakdown @client
        paymentType
        paymentMethod {
            id
            name
            type
        }
        type
        gateway
        total {
            amount
        }
        capturedAmount {
            amount
        }
        billingEmail
        chargeStatus
        isActive
        isSinglePayment
        created
    }
`;
