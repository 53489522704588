/**
 * In case Segment is deactivated, Intercom can be enabled in a standalone
 * mode uncommenting following code. Else, Intercom will be automatically
 * integrated through Segment.
 */
export default function WithIntercom({ children }) {
    return children;
}

// import { useEffect, useContext } from 'react';
// import dynamic from 'next/dynamic';
// import { localstorage, stringify } from 'sf-modules';
// import { CommerceContext } from '../../CommerceProvider';
// import { useSelector } from '../../StoreClient';
//
// const LiveChatLoaderProvider = dynamic<any>(() =>
//     import('react-live-chat-loader').then((mod) => mod.LiveChatLoaderProvider)
// );
//
// const Intercom = dynamic(() => import('react-live-chat-loader').then((mod) => mod.Intercom));
//
// const KEY_INTERCOM_HASH = 'y_hash';
//
// declare global {
//     interface Window {
//         intercomSettings: any;
//     }
// }
// export default function WithIntercom({ children }) {
//
//     const { commerceConfig } = useContext(CommerceContext);
//     const authInfos = useSelector(({ authentication }) => authentication);
//
//     /** Set Intercom settings & boot */
//     const bootIntercom = (settings = {}, reboot: boolean = false) => {
//         window.intercomSettings = {
//             api_base: 'https://api-iam.intercom.io',
//             app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
//             ...settings,
//         };
//
//         if (reboot) global?.Intercom('boot');
//     };
//
//     const setIntercomSettings = async (reboot: boolean = false) => {
//         if (authInfos.id) {
//             let hash = !reboot ? localstorage.get(KEY_INTERCOM_HASH) : null;
//             if (hash) {
//                 bootIntercom(
//                     {
//                         email: authInfos.email,
//                         user_id: authInfos.id,
//                         user_hash: hash,
//                     },
//                     reboot
//                 );
//             } else {
//                 await fetch('/api/segment/identify', {
//                     method: 'post',
//                     headers: {
//                         Accept: 'application/json',
//                         'Content-Type': 'application/json',
//                     },
//                     body: stringify({ id: authInfos.id.toString() }),
//                 })
//                     .then((res) => res.json())
//                     .then((res) => {
//                         if (res.hash) {
//                             bootIntercom(
//                                 {
//                                     email: authInfos.email,
//                                     user_id: authInfos.id,
//                                     user_hash: res.hash,
//                                 },
//                                 reboot
//                             );
//                             localstorage.set(KEY_INTERCOM_HASH, res.hash);
//                         }
//                     });
//             }
//         } else {
//             bootIntercom({}, reboot);
//         }
//     };
//
//     useEffect(() => {
//         if (!authInfos || commerceConfig.context === 'pos') return;
//
//         let hasBooted = global?.Intercom?.booted;
//         let hasSettings = window?.intercomSettings;
//         if (hasBooted && hasSettings) {
//             let intercomUserId = hasSettings.user_id || null;
//             let authUserId = authInfos.id || null;
//             if (intercomUserId !== authUserId) {
//                 setIntercomSettings(true);
//             }
//         }
//     }, [authInfos]);
//
//     /* For Intercom, in case Segment is disconnected */
//     return (
//         <LiveChatLoaderProvider
//             provider='intercom'
//             providerKey={process.env.NEXT_PUBLIC_INTERCOM_APP_ID}
//             idlePeriod={1000}
//             beforeInit={async () => await setIntercomSettings()}
//         >
//             <>
//                 {children}
//                 <Intercom />
//             </>
//         </LiveChatLoaderProvider>
//     );
// }
