import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { ChatBubbleSadIcon } from 'icons/solid';
import Modal from '../../layouts/Modal';
import ModalBody from '../../atoms/ModalBody';
import Button from '../../atoms/Button';

const ClickToChat = ({ children }) => {
    const [showModal, setShowModal] = useState(false);

    const handleClick = () => {
        if (window && window.Intercom) window.Intercom('show');
        else setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    return (
        <>
            <a // eslint-disable-line jsx-a11y/anchor-is-valid
                className='cursor-pointer underline'
                onClick={handleClick}
            >
                {children}
            </a>

            {showModal ? (
                <Modal
                    onClose={handleCloseModal}
                    isOpen={showModal}
                    title='¿Necesitas ayuda? No pudimos iniciar nuestro chat de soporte'
                    icon={<ChatBubbleSadIcon className='mx-auto block w-14' />}
                >
                    <ModalBody className='text-center'>
                        <p>
                            Parece que una extensión de tu navegador está bloqueando nuestro chat.
                            Lo puedes desactivar y recargar la página para poder contactarnos.
                        </p>
                        <Button className='mt-2' size='sm' onClick={handleCloseModal}>
                            Entendido
                        </Button>
                    </ModalBody>
                </Modal>
            ) : (
                ''
            )}
        </>
    );
};

ClickToChat.defaultProps = {};

ClickToChat.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ClickToChat;
