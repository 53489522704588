import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { LinesBasicFieldsFragment } from '../../fragments/checkout/LinesBasicFields.gql';
import { LinesExtendedFieldsFragment } from '../../fragments/checkout/LinesExtendedFields.gql';

const CHECKOUT_POS_UPDATE_MUTATION = gql`
    ${CheckoutBasicFieldsFragment}
    ${LinesBasicFieldsFragment}
    ${LinesExtendedFieldsFragment}
    mutation CheckoutPosUpdate($checkoutId: ID!, $posId: ID!, $storeId: ID, $erase: Boolean) {
        checkoutPosUpdate(
            checkoutId: $checkoutId
            posId: $posId
            storeId: $storeId
            eraseLines: $erase
        ) {
            checkout {
                ...CheckoutBasicFields
                pointOfSale {
                    id
                    name
                    defaultAlgoliaIndex
                    active
                    isDefault
                    isDefaultForUser
                    stockLocation {
                        id
                        name
                        address {
                            id
                            postalCode
                        }
                    }
                }
                store {
                    id
                    name
                    algoliaIndex
                }
                lines {
                    ...LinesBasicFields
                    ...LinesExtendedFields
                }
            }
            errors {
                field
                message
            }
        }
    }
`;

export default CHECKOUT_POS_UPDATE_MUTATION;
