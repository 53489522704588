import { SET_STORE_AS_INITIALIZED } from '../types';

const initialState = {
    isInitialized: false,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case SET_STORE_AS_INITIALIZED:
            return { isInitialized: true };

        default:
            return state;
    }
}
