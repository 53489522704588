/**
 * Check if an URL is internal or external
 * @param url
 * @returns {boolean}
 */
function isExternalURL(url = '') {
    const pattern = /^(((http|https):\/\/)|(mailto:))/;
    return pattern.test(url);
}

const handleRedirect = (link, router = null) => {
    return () => {
        if (router && !isExternalURL(link)) router.push(link);
        else if (link) window.location.href = link;
    };
};

export default {
    isExternalURL,
    handleRedirect,
};
