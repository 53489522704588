const APP_TYPE = process.env.NEXT_PUBLIC_APP_TYPE || 'storefront';

export enum ECheckoutStepsTypes {
    // StoreChannel = 'store_channel',
    DeliveryGateway = 'delivery_gateway',
    PickupSchedule = 'pickup_schedule',
    ShippingMethod = 'shipping_method',
    DeliverySchedule = 'delivery_schedule',
    PaymentMethod = 'payment_method',
    CheckoutComplete = 'checkout_complete',
}

export const TCheckoutFunnels = {
    storefront: {
        default: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.ShippingMethod,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
        shippingOptionsWithSchedule: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.ShippingMethod,
            ECheckoutStepsTypes.DeliverySchedule,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
        quickDeliveryWithSchedule: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.DeliverySchedule,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
        storePickUp: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.PickupSchedule,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
    },
    pos: {
        default: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.ShippingMethod,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
        shippingOptionsWithSchedule: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.ShippingMethod,
            ECheckoutStepsTypes.DeliverySchedule,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
        quickDeliveryWithSchedule: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.DeliverySchedule,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
        storePickUp: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.PickupSchedule,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
        immediateDelivery: [
            ECheckoutStepsTypes.DeliveryGateway,
            ECheckoutStepsTypes.PaymentMethod,
            ECheckoutStepsTypes.CheckoutComplete,
        ],
    },
}[APP_TYPE];
