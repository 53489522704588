import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { get_ } from 'sf-modules';
import { fnDoMutation } from '../../utils/fnDoMutation';
import useCheckout from './useCheckout';
import useCheckoutUpdate from './useCheckoutUpdate';
import CHECKOUT_APPLY_RULES_MUTATION from '../../graphql/mutations/checkout/checkoutApplyRules.gql';

/**
 * Apply cart rules for given checkout
 */
export default function useCartRulesApply(props) {
    const { initialLoadingState = true } = props || {};

    const { checkoutToken } = useCheckout();
    const checkoutUpdate = useCheckoutUpdate();

    const [isApplyingCartRules, setIsApplyingCartRules] = useState(initialLoadingState);
    const [mutationCheckoutApplyRules] = useMutation(CHECKOUT_APPLY_RULES_MUTATION);

    const applyRulesToCheckout = async (newCheckoutToken?: string) => {
        setIsApplyingCartRules(true);
        if (newCheckoutToken || checkoutToken) {
            return await fnDoMutation(mutationCheckoutApplyRules, {
                checkout: newCheckoutToken || checkoutToken,
            }).then((res) => {
                if (typeof checkoutUpdate === 'function')
                    checkoutUpdate({
                        newCheckoutData: get_(res, ['data', 'checkoutApplyRules', 'checkout']),
                        updateLines: true,
                    });
                setIsApplyingCartRules(false);
                return res;
            });
        } else {
            return null;
        }
    };

    return [isApplyingCartRules, applyRulesToCheckout] as const;
}
