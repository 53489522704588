import { useContext } from 'react';
import { CommerceContext } from './CommerceProvider';

export default function useCommerce() {
    const { apolloClient, commerceConfig, checkout, checkoutUpdate, checkoutReset } =
        useContext(CommerceContext);

    return {
        apolloClient,
        commerceConfig,
        checkout,
        checkoutUpdate,
        checkoutReset,
    };
}
