export const PRODUCT_EMPTY = {
    availability: {
        checkoutMaxQuantity: 0,
        availableOnZipCode: false,
        itemsAvailable: 0,
        status: 'unavailable',
    },
    pricing: {},
    backgroundColor: '',
    brand: '',
    description: '',
    isAvailable: false,
    isBundle: false,
    longDescription: '',
    name: 'Este producto ya no está en nuestro catálogo',
    quantity: 0,
    quantityDisplay: '',
    seoDescription: '',
    seoTitle: '',
    sku: '',
    slug: '',
    aisles: [],
    blobs: [],
    certifications: [],
    facets: [],
    format: {
        mainSlug: '',
        mainValue: '',
        netContent: '',
        unitCost: '',
        unitCount: '',
        unitPricingBaseMeasure: '',
        unitPricingBaseMeasureUnit: '',
    },
    images: {
        hoverUrl: '',
        mainAlt: '',
        mainUrl: '',
        items: [],
    },
    ingredients: [],
    parsedUnitPricingBaseMeasure: null,
    products: [],
    reviews: {
        average: 0,
        count: 0,
        oneStarReviewCount: 0,
        twoStarReviewCount: 0,
        threeStarReviewCount: 0,
        fourStarReviewCount: 0,
        fiveStarReviewCount: 0,
        items: [],
    },
    route: {
        as: '',
    },
};

export default PRODUCT_EMPTY;
