import React, {
    ReactChildren,
    ReactChild,
    createContext,
    useContext,
    useState,
    useEffect,
} from 'react';
import { get_, isNumber } from 'sf-modules';
import useCommerce from '../../useCommerce';
import useTracker from '../tracking/useTracker';
import { ECheckoutStepsTypes, TCheckoutFunnels } from '../../types/ECheckoutStepsTypes';

interface FunnelStepperInterface {
    steps: any[];
    activeStep: string;
    activeStepPosition: number;
    initFunnelStep: Function;
    nextFunnelStep: Function;
    previousFunnelStep: Function;
    skipToFunnelStep: Function;
    isStepReached: Function;
    isStepValidated: Function;
    isStepLast: Function;
    updateFunnel: Function;
}

const FunnelStepperContext = createContext<FunnelStepperInterface>({
    steps: [],
    activeStep: undefined,
    activeStepPosition: undefined,
    initFunnelStep: () => {},
    nextFunnelStep: () => {},
    previousFunnelStep: () => {},
    skipToFunnelStep: () => {},
    isStepReached: () => {},
    isStepValidated: () => {},
    isStepLast: () => {},
    updateFunnel: undefined,
});

function CheckoutFunnelProvider({
    children,
    steps,
    checkoutBase64Id,
}: {
    children: ReactChildren | ReactChild;
    steps: any[];
    checkoutBase64Id: string;
}) {
    const { checkout } = useCommerce();
    const { trackEvent } = useTracker();
    const [step, setStep] = useState<number | undefined>();
    const [funnelSteps, setFunnelSteps] = useState<any[]>(steps);

    const handleStepper = (newStep) => {
        if (!isNumber(newStep)) setStep(0);

        if (!funnelSteps || newStep < 0 || newStep >= funnelSteps.length) return;

        if (step !== newStep) setStep(newStep);
    };

    const initFunnelStep = () => handleStepper(0);
    const previousFunnelStep = () => handleStepper(step - 1);

    const nextFunnelStep = (currentStep = null) => {
        if (!currentStep) handleStepper(step + 1);
        else {
            let currentStepIndex = funnelSteps?.findIndex((x) => x === currentStep);
            if (currentStepIndex === step) handleStepper(step + 1);
        }
    };

    const skipToFunnelStep = (queriedStep) => {
        let queriedStepIndex = funnelSteps?.findIndex((x) => x === queriedStep);
        handleStepper(queriedStepIndex);
    };

    const isStepReached = (queriedStep) => {
        let queriedStepIndex = funnelSteps?.findIndex((x) => x === queriedStep);
        return queriedStepIndex >= 0 && step >= queriedStepIndex;
    };

    const isStepValidated = (queriedStep) => {
        let queriedStepIndex = funnelSteps?.findIndex((x) => x === queriedStep);
        return queriedStepIndex >= 0 && step > queriedStepIndex;
    };

    const isStepLast = (queriedStep) => {
        let queriedStepIndex = funnelSteps?.findIndex((x) => x === queriedStep);
        return queriedStepIndex >= 0 && queriedStepIndex === funnelSteps.length - 1;
    };

    const updateFunnel = (newFunnel, newStep) => {
        setFunnelSteps(newFunnel);
        let queriedStepIndex = newFunnel?.findIndex((x) => x === newStep);
        handleStepper(queriedStepIndex);
    };

    useEffect(() => {
        if (isNumber(step) && funnelSteps && funnelSteps.length && step < funnelSteps.length) {
            trackEvent({
                type: 'Checkout Step Viewed',
                data: {
                    category: 'Checkout',
                    step: get_(funnelSteps, [step]),
                    order_id: get_(checkout, ['token']),
                    label: get_(checkout, ['token']),
                    value: Math.round(get_(checkout, ['totalPrice'])),
                    coupon: get_(checkout, ['discountName']) || '',
                    currency: 'MXN',
                    shipping: get_(checkout, ['shippingPrice', 'gross', 'amount']),
                    shippingDiscount: get_(checkout, ['shippingDiscount']),
                    campaignDiscount: get_(checkout, ['campaignDiscount']),
                    voucherDiscount: get_(checkout, ['voucherDiscount']),
                    checkoutLines: get_(checkout, ['parsedLines']),
                },
            });
        }
    }, [step, funnelSteps]);

    // Reset funnel steps on store change
    useEffect(() => {
        // On store change, reset funnel step
        if (step) {
            updateFunnel(TCheckoutFunnels.default, ECheckoutStepsTypes.DeliveryGateway);
        }
    }, [checkout && checkout.store && checkout.store.id]);

    return (
        <FunnelStepperContext.Provider
            value={{
                steps: funnelSteps,
                activeStep: get_(funnelSteps, [step]),
                activeStepPosition: step,
                initFunnelStep,
                nextFunnelStep,
                previousFunnelStep,
                skipToFunnelStep,
                isStepReached,
                isStepValidated,
                isStepLast,
                updateFunnel,
            }}
        >
            {children}
        </FunnelStepperContext.Provider>
    );
}

function useCheckoutFunnel() {
    return useContext(FunnelStepperContext);
}

export { CheckoutFunnelProvider, useCheckoutFunnel };
