import React, {
    useState,
    useEffect,
    useContext,
    createContext,
    ReactChild,
    ReactChildren,
} from 'react';
import { useRouter } from 'next/router';
import usePOSConfig from './usePOSConfig';

interface PosContextInterface {
    configuration: {
        loadingPos: boolean;
        loadingStores: boolean;
        selectedPos: any | null;
        selectedStore: any | null;
        handleConfirmPosAndStore: Function | null;
    };
    shouldApplyCartRules: boolean;
    setShouldApplyCartRules: Function | null;
    shouldClearAllBeforeLogout: boolean;
    setShouldClearAllBeforeLogout: Function | null;
}

export const PosContext = createContext<PosContextInterface>({
    configuration: {
        loadingPos: true,
        loadingStores: true,
        selectedPos: null,
        selectedStore: null,
        handleConfirmPosAndStore: null,
    },
    shouldApplyCartRules: false,
    setShouldApplyCartRules: null,
    shouldClearAllBeforeLogout: false,
    setShouldClearAllBeforeLogout: null,
});

export default function PosProvider({
    signOut,
    children,
}: {
    signOut: Function;
    children: ReactChildren | ReactChild;
}) {
    const router = useRouter();
    const [selectedPos, setSelectedPos] = useState(null);
    const [selectedStore, setSelectedStore] = useState(null);
    const [shouldApplyCartRules, setShouldApplyCartRules] = useState(true);
    const [shouldClearAllBeforeLogout, setShouldClearAllBeforeLogout] = useState(false);

    const { loadingPos, loadingStores, handleConfirmPosAndStore } = usePOSConfig({
        signOut,
        selectedPos,
        setSelectedPos,
        selectedStore,
        setSelectedStore,
    });

    useEffect(() => {
        if (router.asPath === '/carrito/pago') {
            setShouldApplyCartRules(false);
        }
    }, [router.asPath]);

    return (
        <PosContext.Provider
            value={{
                configuration: {
                    loadingPos,
                    loadingStores,
                    selectedPos,
                    selectedStore,
                    handleConfirmPosAndStore,
                },
                shouldApplyCartRules,
                setShouldApplyCartRules,
                shouldClearAllBeforeLogout,
                setShouldClearAllBeforeLogout,
            }}
        >
            {children}
        </PosContext.Provider>
    );
}

export const usePos = () => useContext(PosContext);
