import { gql } from '@apollo/client';
import { ProductFieldsForCheckoutFragment } from '../products/ProductFieldsForCheckout.gql';
import { BundleFieldsForCheckoutFragment } from '../products/BundleFieldsForCheckout.gql';

export const LinesExtendedFieldsFragment = gql`
    ${ProductFieldsForCheckoutFragment}
    ${BundleFieldsForCheckoutFragment}
    fragment LinesExtendedFields on CheckoutLine {
        billedQuantity
        productsDiscounted
        totalPrice
        variantPricing {
            price
            priceUndiscounted
            baseMeasurePrice
            baseMeasurePriceUndiscounted
        }
        bundlePricing {
            price
            priceUndiscounted
        }
        variant {
            id
            product {
                ...ProductFieldsForCheckout
            }
        }
        bundle {
            ...BundleFieldsForCheckout
        }
    }
`;
