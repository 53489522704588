// @ts-nocheck

import React, { ReactChild, ReactChildren, createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { ApolloClient } from '@apollo/client';
import { get_, links, decodeIdBase64 } from 'sf-modules';
import { trackError } from '../../utils/error';
import useCommerce from '../../useCommerce';
import ICheckoutLocalVoucher from '../../types/ICheckoutLocalVoucher';
import CHECKOUT_QUERY from '../../graphql/queries/checkout/checkout.gql';

interface CheckoutContextInterface {
    apolloClient: ApolloClient;
    loading: boolean;
    error: any;
    checkoutBase64Id: string;
    checkoutToken: string;
    isPlacingOrder: boolean;
    setIsPlacingOrder: Function;
    voucher: ICheckoutLocalVoucher;
    checkoutSetError: Function;
    voucherStateUpdate: Function;
    paymentMethod: any;
    setPaymentMethod: Function;
}

export const CheckoutContext = createContext<CheckoutContextInterface>({
    apolloClient: undefined,
    loading: true,
    error: null,
    checkoutBase64Id: null,
    checkoutToken: null,
    isPlacingOrder: false,
    setIsPlacingOrder: () => {},
    voucher: undefined,
    checkoutSetError: () => {},
    voucherStateUpdate: () => {},
    paymentMethod: null,
    setPaymentMethod: () => {},
});

export default function CheckoutProvider({
    apolloClient,
    checkoutBase64Id,
    children,
}: {
    apolloClient: ApolloClient;
    checkoutBase64Id: string;
    children: ReactChildren | ReactChild;
}) {
    const router = useRouter();
    const { checkout, checkoutUpdate, checkoutReset } = useCommerce();

    const [ids, setIds] = useState<{ base64: string; token: string }>({
        base64: null,
        token: null,
    });
    const [isPlacingOrder, setIsPlacingOrder] = useState<boolean>(false);
    const [flowState, setFlowState] = useState<{
        loading: boolean;
        error: any;
    }>({ loading: true, error: null });
    const [voucher, setVoucher] = useState<ICheckoutLocalVoucher>({
        voucher: null,
        errors: [],
    });
    const [paymentMethod, setPaymentMethod] = useState<object | null>(null);

    useEffect(() => {
        if (!checkoutBase64Id) {
            checkoutReset();
            setVoucher({ voucher: null, errors: [] });
            setFlowState({ loading: false, error: null });
            return;
        }

        // Set checkout IDs
        let token = decodeIdBase64(checkoutBase64Id);
        setIds({ base64: checkoutBase64Id, token: token });
    }, [checkoutBase64Id]);

    useEffect(() => {
        if (!ids || !ids.token) return;

        initCheckoutSteps();
    }, [ids]);

    /**
     * Run all required init before starting checkout steps
     */
    const initCheckoutSteps = async () => {
        checkoutFetch().then((res) => {
            if (res) {
                setFlowState({ loading: false, error: null });
            }
        });
    };

    /**
     * Fetch checkout from Saleor
     */
    const checkoutFetch = async () => {
        if (!ids || !ids.token) return;

        try {
            return await apolloClient
                .query({
                    query: CHECKOUT_QUERY,
                    variables: { token: ids.token },
                })
                .then((response) => {
                    let chkt = get_(response, ['data', 'checkout']);
                    if (!chkt || !chkt.id) {
                        setFlowState({
                            loading: false,
                            error: {
                                title: 'No pudimos recuperar los productos de tu carrito',
                                errorType: 'Ocurrió un error de comunicación con nuestro servidor',
                                errorDescription:
                                    '¡Lo sentimos! Puedes volver a llenar tu carrito e intentar de nuevo.',
                                buttonText: 'Regresar al catálogo',
                                buttonOnClick: links.handleRedirect('/s', router),
                            },
                        });
                        return false;
                    }

                    checkoutUpdate({ newCheckoutData: chkt });
                    return true;
                });
        } catch (e) {
            trackError({
                label: 'Fetch checkout error',
                extras: {
                    location: 'CheckoutProvider > checkoutFetch',
                    checkoutIds: ids.toString(),
                    error: e.toString(),
                },
            });
            setFlowState({
                loading: false,
                error: {
                    title: 'Estamos teniendo problemas de comunicación con nuestro servidor',
                    errorType: 'Ocurrió un error de comunicación',
                    errorDescription: 'Puedes volver a intentar.',
                    buttonText: 'Volver a intentar',
                    buttonOnClick: () => router.push(router.pathname),
                },
            });
            return false;
        }
    };

    const checkoutSetError = (err) => {
        setFlowState({ loading: false, error: err });
    };

    /**
     * Update local voucher state with user voucher code input and error response from Saleor
     * @param voucherState
     */
    const voucherStateUpdate = (voucherState: ICheckoutLocalVoucher) => {
        setVoucher(voucherState);
    };

    return (
        <CheckoutContext.Provider
            value={{
                apolloClient,
                loading: flowState.loading,
                error: flowState.error,
                checkoutBase64Id: ids.base64,
                checkoutToken: ids.token,
                isPlacingOrder,
                setIsPlacingOrder,
                voucher,
                checkoutSetError,
                voucherStateUpdate,
                paymentMethod,
                setPaymentMethod,
            }}
        >
            {children}
        </CheckoutContext.Provider>
    );
}
