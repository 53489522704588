import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import * as PropTypes from 'prop-types';
import useMedia from 'use-media';
import { useMutation } from '@yema/commerce/APIClient';
import { useSelector } from '@yema/commerce/StoreClient';
import useStore from '@yema/commerce/hooks/store/useStore';
import { fnDoMutation } from '@yema/commerce/utils/fnDoMutation';
import useTracker from '@yema/commerce/hooks/tracking/useTracker';
import { APP_STATE_TYPES, MODAL_KIND } from '@yema/commerce/CommerceProvider';
import { removeUnavailableCheckoutLines } from '@yema/commerce/store/actions/cartActions';
import { useStateEngine } from '@yema/commerce';
import { get_, decodeIdBase64, localstorage } from 'sf-modules';
import LoaderLogo from '@/yema-designkit/webkit/atoms/Loaders/LoaderLogo';
import { CATALOG_LS } from 'constants/constants';
import { PATH_CART } from 'constants/paths';
import CHECKOUT_SHIPPING_ADDRESS_REMOVE_MUTATION from '@yema/commerce/graphql/mutations/checkout/checkoutShippingAddressRemove.gql';

const Loader = () => (
    <LoaderLogo position='fixed' overlayColor='rgba(255, 247, 246, .7)' withOverlay active />
);

/* eslint-disable react/display-name */
const Login = dynamic(() => import('./Login'), {
    loading: () => <Loader />,
});
const ModalSignUpSuccess = dynamic(
    () => import('@/yema-designkit/webkit/organisms/ModalSignUpSuccess'),
    { loading: () => <Loader /> }
);
const ModalResetPassword = dynamic(() => import('./password/ModalResetPassword'), {
    loading: () => <Loader />,
});
const ModalAlert = dynamic(() => import('@/yema-designkit/webkit/organisms/ModalAlert'), {
    loading: () => <Loader />,
});
const ModalProductNotAvailable = dynamic(
    () => import('@/yema-designkit/webkit/organisms/ModalProductNotAvailable'),
    { loading: () => <Loader /> }
);
const ModalOutOfStock = dynamic(
    () => import('@yema/commerce/components/checkout/ModalOutOfStock'),
    { loading: () => <Loader /> }
);
const ModalZipCode = dynamic(() => import('./catalog/ModalZipCode'), {
    loading: () => <Loader />,
});
const ModalRequestInvoice = dynamic(() => import('./invoice/ModalRequestInvoice'), {
    loading: () => <Loader />,
});

/* eslint-enable react/display-name */
const ASK_FOR_DELIVERY_LOCATION_AT_MOUNT = false;

const areOldSettings = (settingsDate) => {
    if (!settingsDate) return true;
    const oneDay = 60 * 60 * 24 * 1000;
    return new Date() - settingsDate > oneDay;
};

const ModalEngineRender = () => {
    const router = useRouter();
    const checkoutBase64Id = useSelector(({ cart }) => cart.checkoutID);
    const isMobile = useMedia({ maxWidth: 640 });

    const { updateCheckoutStore, setZipCodeAndUpdateStore } = useStore();
    const [{ modal, catalog, pipeCart, unavailableProducts }, dispatch] = useStateEngine();
    const { trackEvent } = useTracker();
    const [isLoading, setIsLoading] = useState(false);

    const [mutationRemoveShippingAddress] = useMutation(CHECKOUT_SHIPPING_ADDRESS_REMOVE_MUTATION);

    const resetShippingAddress = async () => {
        if (checkoutBase64Id)
            await fnDoMutation(mutationRemoveShippingAddress, {
                checkout: checkoutBase64Id,
            });
    };

    /** Set zipClode */
    const handleSetZipCode = async (zipCode, addressId = null) => {
        setIsLoading(true);
        setZipCodeAndUpdateStore({
            catalog,
            dispatch,
            zipCode,
            addressId,
            checkoutBase64Id,
            resetCheckoutShippingAddress: resetShippingAddress,
            pipeCart,
        }).then(() => {
            dispatch({
                type: APP_STATE_TYPES.TOGGLE_MODAL,
                kind: MODAL_KIND.CHANGE_DELIVERY_LOCATION,
                open: false,
            });

            // Close modals
            setIsLoading(false);
        });
    };

    useEffect(() => {
        if (unavailableProducts && unavailableProducts.length) {
            dispatch({
                type: APP_STATE_TYPES.TOGGLE_MODAL,
                kind: MODAL_KIND.PRODUCT_NOT_AVAILABLE,
                open: true,
            });
        }
    }, [unavailableProducts]);

    const handleInitCatalogSettings = () => {
        // Auto init catalog with default settings
        if (!ASK_FOR_DELIVERY_LOCATION_AT_MOUNT && !get_(catalog, ['createdAt'])) {
            handleSetZipCode(null);
            return;
        }

        if (
            ASK_FOR_DELIVERY_LOCATION_AT_MOUNT &&
            !get_(catalog, ['zipCode']) &&
            areOldSettings(get_(catalog, ['createdAt'])) &&
            !router.pathname.startsWith('/cuenta') &&
            !router.pathname.startsWith('/blog')
        ) {
            dispatch({
                type: APP_STATE_TYPES.TOGGLE_MODAL,
                kind: MODAL_KIND.CHANGE_DELIVERY_LOCATION,
                open: true,
            });
        }
    };

    useEffect(() => {
        if (catalog && catalog.index && !catalog.store?.id) {
            // Required field store.id introduced in @yema/commerce v2.5.10
            dispatch({ type: APP_STATE_TYPES.CATALOG_SETTINGS_DELETE });
            return;
        }

        if (!catalog || !catalog.index) {
            const savedSettings = localstorage.get(CATALOG_LS);
            if (savedSettings && savedSettings.index) {
                dispatch({
                    type: APP_STATE_TYPES.CATALOG_RESET_SETTINGS,
                    settings: savedSettings,
                });
            }
        }

        const setCatalogSettings = setTimeout(() => {
            handleInitCatalogSettings();
        }, 3000);

        return () => {
            clearTimeout(setCatalogSettings);
        };
    }, [catalog]);

    /**
     *
     * RENDER MODALS
     */

    if (!modal.opened) return <></>;

    if (isLoading) return <Loader />;

    switch (modal.kind) {
        case MODAL_KIND.LOGIN:
            return (
                <Login
                    isModal={true}
                    isModalOpened={true}
                    isMobile={isMobile}
                    onCloseModal={() => dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL })}
                    allowRegister={false}
                    {...modal.props}
                />
            );

        case MODAL_KIND.SIGNUP_SUCCESS:
            return (
                <ModalSignUpSuccess
                    isOpen={true}
                    onClose={() => dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL })}
                    {...modal.props}
                />
            );

        case MODAL_KIND.RESET_PASSWORD:
            return (
                <ModalResetPassword
                    isOpen={true}
                    onClose={() => dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL })}
                    {...modal.props}
                />
            );

        case MODAL_KIND.ALERT:
            return (
                <ModalAlert
                    isOpen={true}
                    isMobile={isMobile}
                    onClose={() => dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL })}
                    {...modal.props}
                />
            );

        case MODAL_KIND.PRODUCT_NOT_AVAILABLE:
            return (
                <ModalProductNotAvailable
                    checkoutToken={decodeIdBase64(checkoutBase64Id)}
                    newZipCode={catalog.zipCode}
                    products={unavailableProducts}
                    isMobile={isMobile}
                    isOpen={true}
                    onOpen={() => {
                        trackEvent({
                            type: 'Products Unavailable On Zipcode',
                            data: {
                                category: 'Cart',
                                label: catalog.zipCode,
                                cart_id: decodeIdBase64(checkoutBase64Id),
                                rawProducts: unavailableProducts,
                            },
                        });
                    }}
                    onClose={() => {
                        if (checkoutBase64Id) {
                            removeUnavailableCheckoutLines();
                            updateCheckoutStore(catalog.zipCode, checkoutBase64Id, true);
                        }
                        if (!catalog || !catalog.createdAt) {
                            dispatch({
                                type: APP_STATE_TYPES.CATALOG_SET_SETTINGS,
                                createdAt: new Date(),
                            });
                        }
                        dispatch({ type: APP_STATE_TYPES.CART_EMPTY_QUEUE });
                        dispatch({
                            type: APP_STATE_TYPES.CART_EMPTY_UNAVAILABLE_PRODUCTS,
                        });
                        if (router.pathname === '/carrito/pago') router.reload();
                        else dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL });
                    }}
                    handleChangeDeliveryZone={() => {
                        if (router.pathname === '/carrito/pago') {
                            resetShippingAddress().then(() =>
                                dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL })
                            );
                        } else {
                            dispatch({
                                type: APP_STATE_TYPES.TOGGLE_MODAL,
                                kind: MODAL_KIND.CHANGE_DELIVERY_LOCATION,
                                open: true,
                            });
                        }
                    }}
                />
            );

        case MODAL_KIND.PRODUCT_OUT_OF_STOCK:
            return (
                <ModalOutOfStock
                    isOpen={true}
                    onClose={() => dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL })}
                    isMobile={isMobile}
                    handleSecondaryButton={() => {
                        router.push(PATH_CART);
                        dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL });
                    }}
                    handlePrimaryButton={() => {
                        dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL });
                    }}
                    {...modal.props}
                />
            );

        case MODAL_KIND.CHANGE_DELIVERY_LOCATION:
            return <ModalZipCode isOpen={true} setZipCode={handleSetZipCode} isMobile={isMobile} />;

        case MODAL_KIND.REQUEST_INVOICE:
            return (
                <ModalRequestInvoice
                    isOpen={true}
                    onClose={() => dispatch({ type: APP_STATE_TYPES.CLOSE_MODAL })}
                    // isMobile={isMobile}
                    {...modal.props}
                />
            );

        default:
            return '';
    }
};

ModalEngineRender.defaultProps = {
    isMobile: false,
};

ModalEngineRender.propTypes = {
    isMobile: PropTypes.bool,
};

export default React.memo(ModalEngineRender);
