export interface ICommerceConfig {
    context: string | null;
    storage: {
        CATALOG_INDEX: string | null;
        CATALOG_LS: string | null;
        SELECTED_CASHBOX: string | null;
    };
    values: {
        GOOGLE_MAPS_API_KEY: string | null;
        DEFAULT_EMAIL: string | null;
        BREAKPOINT: string | null;
        BREAKPOINT_MD: string | null;
    };
    paths: {
        PATH_TERMS: string | null;
        PATH_CART: string | null;
        CHECKOUT_SUCCESS: string | null;
    };
}

export const DCommerceConfig = {
    context: 'storefront',
    storage: {
        CATALOG_INDEX: null,
        CATALOG_LS: null,
        SELECTED_CASHBOX: null,
    },
    values: {
        GOOGLE_MAPS_API_KEY: null,
        DEFAULT_EMAIL: null,
        BREAKPOINT: null,
        BREAKPOINT_MD: null,
    },
    paths: {
        PATH_TERMS: null,
        PATH_CART: null,
        CHECKOUT_SUCCESS: null,
    },
};
