import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from './CheckoutBasicFields.gql';
import { CheckoutUserFieldsFragment } from './CheckoutUserFields.gql';
import { CheckoutPricingFieldsFragment } from './CheckoutPricingFields.gql';
import { CheckoutExtendedFieldsFragment } from './CheckoutExtendedFields.gql';
import { LinesBasicFieldsFragment } from './LinesBasicFields.gql';
import { LinesExtendedFieldsFragment } from './LinesExtendedFields.gql';
import { LinesAppliedBenefitsFieldsFragment } from './LinesAppliedBenefitsFields.gql';

export const CheckoutFieldsFragment = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutUserFieldsFragment}
    ${CheckoutPricingFieldsFragment}
    ${CheckoutExtendedFieldsFragment}
    ${LinesBasicFieldsFragment}
    ${LinesExtendedFieldsFragment}
    ${LinesAppliedBenefitsFieldsFragment}
    fragment CheckoutFields on Checkout {
        ...CheckoutBasicFields
        ...CheckoutUserFields
        ...CheckoutPricingFields
        ...CheckoutExtendedFields
        lines {
            ...LinesBasicFields
            ...LinesExtendedFields
            ...LinesAppliedBenefitsFields
        }
    }
`;
