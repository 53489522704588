import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { CheckoutPricingFieldsFragment } from '../../fragments/checkout/CheckoutPricingFields.gql';
import { CheckoutExtendedFieldsFragment } from '../../fragments/checkout/CheckoutExtendedFields.gql';

const CHECKOUT_SCHEDULE_DELIVERY_UPDATE_MUTATION = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutPricingFieldsFragment}
    ${CheckoutExtendedFieldsFragment}
    mutation CheckoutScheduledDeliveryUpdate(
        $checkout: ID!
        $schedule: CheckoutScheduledDeliveryInput!
    ) {
        checkoutScheduledDeliveryUpdate(checkoutId: $checkout, input: $schedule) {
            checkout {
                ...CheckoutBasicFields
                ...CheckoutPricingFields
                ...CheckoutExtendedFields
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_SCHEDULE_DELIVERY_UPDATE_MUTATION;
