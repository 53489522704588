import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { CheckoutContext } from './CheckoutProvider';
import { fnDoMutation } from '../../utils/fnDoMutation';
import { checkout_reset } from '../../store/actions/cartActions';
import { CHECKOUT_CUSTOMER_DETACH_MUTATION } from '../../graphql/mutations/checkout/checkoutCustomerDetach.gql';

export default function useCheckoutReset() {
    const { checkoutBase64Id } = useContext(CheckoutContext);
    const dispatch = useDispatch();
    const [mutationCheckoutCustomerDetach] = useMutation(CHECKOUT_CUSTOMER_DETACH_MUTATION);

    const checkoutReset = () => {
        if (checkoutBase64Id)
            fnDoMutation(mutationCheckoutCustomerDetach, {
                checkout: checkoutBase64Id,
            });

        dispatch(checkout_reset());
    };

    return checkoutReset;
}
