export const SET_STORE_AS_INITIALIZED = 'SET_STORE_AS_INITIALIZED';

export const AUTHENTICATE = 'authenticate';
export const DEAUTHENTICATE = 'deauthenticate';
export const SET_GUEST_CUSTOMER = 'set_guest_customer';

export const IDENTIFY_USER_EMAIL = 'identify_user_email';

export const CHECKOUT_UPDATE = 'checkout_update';
export const CHECKOUT_RESTORE = 'checkout_restore';
export const CHECKOUT_RESET = 'checkout_reset';
