import { localstorage, cookie } from 'sf-modules';
import { APP_STATE_TYPES, INITIAL_APP_STATE } from '../CommerceProvider';

export default function catalogReducer(state, action, storageConfig) {
    const { CATALOG_LS, CATALOG_INDEX } = storageConfig;

    let settings;
    switch (action.type) {
        case APP_STATE_TYPES.CATALOG_SETTINGS_DELETE:
            if (CATALOG_LS) localstorage.remove(CATALOG_LS);
            if (CATALOG_INDEX) cookie.remove(CATALOG_INDEX);
            return INITIAL_APP_STATE.catalog;

        case APP_STATE_TYPES.CATALOG_RESET_SETTINGS:
            let init = INITIAL_APP_STATE.catalog;
            let values = action.settings;
            return {
                createdAt: values.createdAt || init.createdAt,
                zipCode: values.zipCode || init.zipCode,
                addressId: values.addressId || init.addressId,
                store:
                    values?.store ||
                    // Ensure backward compatibility before launching @yema/commerce v2.5.10
                    (values?.storeId ? { id: values.storeId, name: values.storeName } : init.store),
                pos:
                    values?.pos ||
                    // Ensure backward compatibility before launching @yema/commerce v2.5.10
                    (values?.posId ? { id: values.posId, name: values.posName } : init.pos),
                index: values.index || init.index,
            };

        case APP_STATE_TYPES.CATALOG_SET_SETTINGS:
            settings = {
                createdAt: action.createdAt,
                zipCode: action.zipCode || state.zipCode,
                addressId: action.addressId,
                store: action.store,
                pos: action.pos,
                index: action.index,
            };
            if (CATALOG_LS) localstorage.set(CATALOG_LS, settings);
            if (CATALOG_INDEX)
                cookie.set(
                    CATALOG_INDEX,
                    btoa(action.index),
                    7,
                    process.env.NEXT_PUBLIC_DEPLOY_ENV !== 'local'
                );
            return {
                ...state,
                ...settings,
            };

        case APP_STATE_TYPES.CATALOG_ADD_SETTINGS:
            settings = {
                ...state,
                ...action,
            };
            if (CATALOG_LS) localstorage.set(CATALOG_LS, settings);
            if (CATALOG_INDEX && settings?.index)
                cookie.set(
                    CATALOG_INDEX,
                    btoa(settings.index),
                    7,
                    process.env.NEXT_PUBLIC_DEPLOY_ENV !== 'local'
                );
            return settings;

        default:
            return state;
    }
}
