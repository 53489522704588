import { APP_STATE_TYPES } from '../CommerceProvider';

export default function configReducer(state, action) {
    switch (action.type) {
        case APP_STATE_TYPES.CONFIG_SET:
            return {
                ...state,
                ...action.config,
            };

        default:
            return state;
    }
}
