import { stringify } from 'sf-modules';
import useScript, { ScriptStatus } from 'designkit/src/hooks/useScript';
import { trackError } from '../../utils/error';

const SOICOS_CONVERSION_SCRIPT_LINK = 'https://ad.soicos.com/soicosjs.php?s=.js';
const SOICOS_PID = 11885;

const usePixelSoicosConversion = (withFrontTrackers) => {
    const [scriptIsReady, scriptErrorCode] = useScript(
        withFrontTrackers ? SOICOS_CONVERSION_SCRIPT_LINK : ''
    );

    const pixelSoicosRegisterConversion = (orderPublicId, totalPaid) => {
        if (!withFrontTrackers) return;

        if (scriptIsReady && scriptErrorCode !== ScriptStatus.ERROR && window && window.soicos) {
            // Documentación SOICOS:
            // event = En este parámetro enviaremos únicamente un numero entero.
            // Mandaremos 8 cuando el total sea menor o igual a $500 o 9 cuando
            // sea superior a $500. Esto sirve para tener un control sobre las
            // comisiones por venta que se les pagará.
            const eventNumber = totalPaid <= 500 ? 8 : 9;

            const obj = {
                pid: SOICOS_PID,
                data: '',
                trans: {
                    orderID: orderPublicId,
                    store_name: 'YEMA',
                    total: totalPaid,
                    currency: 'MXN',
                    event: eventNumber,
                },
            };

            if (
                process.env.NEXT_PUBLIC_DEPLOY_ENV === 'production' ||
                process.env.NEXT_PUBLIC_DEPLOY_ENV === 'staging'
            )
                window.soicos.registerConversion(obj);
            else
                console.info(
                    'Pixel Soicos: on prod env., would call registerConversion with params: ',
                    stringify(obj)
                );
        } else {
            trackError({
                label: 'Could not call SOICOS registerConversion',
                extras: {
                    scriptIsReady: scriptIsReady,
                    scriptErrorCode: scriptErrorCode,
                    orderPublicId: orderPublicId,
                    totalPaid: totalPaid,
                },
            });
        }
    };

    return pixelSoicosRegisterConversion;
};

export default usePixelSoicosConversion;
