import { gql } from '@apollo/client';

const CHECKOUT_COMPLETE_MUTATION = gql`
    mutation CheckoutComplete($checkout: ID!, $cashBoxId: ID, $requiresInvoice: Boolean!) {
        checkoutComplete(
            checkoutId: $checkout
            cashBoxId: $cashBoxId
            requiresInvoice: $requiresInvoice
        ) {
            order {
                id
                publicId
                userEmail
                shippingMethodName
                discountAmount {
                    amount
                }
                total
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_COMPLETE_MUTATION;
