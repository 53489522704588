import { gql } from '@apollo/client';
import { PhoneFields } from './Phone.gql';

/**
 * GraphQL Fragment
 *
 */
export const AddressFieldsFragment = gql`
    ${PhoneFields}
    fragment AddressFields on Address {
        id
        alias
        addressCopy
        firstName
        lastName
        companyName
        streetAddress1
        streetAddress2
        internalNumber
        externalNumber
        city
        cityArea
        postalCode
        country {
            code
            country
            vat {
                countryCode
                standardRate
            }
        }
        countryArea
        phone {
            ...PhoneFields
        }
        latitude
        longitude
        isDefaultBillingAddress
        isDefaultShippingAddress
        references
    }
`;
