import { APP_STATE_TYPES } from '../CommerceProvider';

export default function checkoutReducer(state, action) {
    switch (action.type) {
        case APP_STATE_TYPES.CHKT_SET_PAID_AMOUNT:
            return {
                ...state,
                paidAmount: action.paidAmount,
            };
        case APP_STATE_TYPES.CHKT_RESET_PAID_AMOUNT:
            return {
                ...state,
                paidAmount: 0,
            };
        default:
            return state;
    }
}
