import { APP_STATE_TYPES } from '../CommerceProvider';

export default function pipeCartReducer(state, action) {
    const updateProductInPipe = (queue, productToEnqueue, payload = null) => {
        index = queue.findIndex((p) => p.rawId === productToEnqueue.rawId);
        if (index !== -1) queue[index] = { ...queue[index], ...payload };
        else queue.push({ rawId: productToEnqueue.rawId, ...payload });
    };

    let copyState;
    let index;

    switch (action.type) {
        case APP_STATE_TYPES.CART_ENQUEUE_PRODUCTS_ARRAY:
            copyState = [...state];
            action.products.map((productToEnqueue) =>
                updateProductInPipe(copyState, productToEnqueue)
            );
            return copyState;

        case APP_STATE_TYPES.CART_ENQUEUE_PRODUCT:
            copyState = [...state];
            updateProductInPipe(copyState, action.product, action);
            return copyState;

        case APP_STATE_TYPES.CART_DEQUEUE_PRODUCT:
            copyState = [...state];
            index = copyState.findIndex((product) => product.rawId === action.rawId);
            if (index !== -1) copyState.splice(index, 1);
            return copyState;

        case APP_STATE_TYPES.CART_EMPTY_QUEUE:
            return [];

        default:
            return state;
    }
}
