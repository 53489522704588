import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { CheckoutUserFieldsFragment } from '../../fragments/checkout/CheckoutUserFields.gql';

const CHECKOUT_EMAIL_UPDATE_MUTATION = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutUserFieldsFragment}
    mutation CheckoutEmailUpdate($checkout: ID!, $email: String!, $phone: String) {
        checkoutEmailUpdate(checkoutId: $checkout, email: $email, phone: $phone) {
            checkout {
                ...CheckoutBasicFields
                ...CheckoutUserFields
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_EMAIL_UPDATE_MUTATION;
