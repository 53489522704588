import { gql } from '@apollo/client';

export const CheckoutStoreFieldsFragment = gql`
    fragment CheckoutStoreFields on Checkout {
        store {
            id
            name
            active
            algoliaIndex
            defaultOnPos
        }
    }
`;
