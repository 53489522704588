import { useContext } from 'react';
import * as Sentry from '@sentry/nextjs';
import { stringify } from 'sf-modules';
import { TrackerContext } from './TrackerProvider';

const appName = process.env.APP_NAME || 'unnamed-app';
const appVersion = process.env.APP_VERSION || 'no-version';

export function getAnonymousId() {
    let anonymousId;

    if (typeof global?.analytics?.user === 'function')
        anonymousId = global.analytics.user().anonymousId();
    else if (typeof global?.Intercom === 'function') anonymousId = global.Intercom('getVisitorId');

    return anonymousId;
}

async function clientDetection(adBlockDetected) {
    try {
        // IP Address
        let userIpAddress = await fetch('/api/gip')
            .then((res) => res.json())
            .then((res) => (res ? res.ip : ''));

        let unknown = '-';

        // screen
        // let width;
        // let height;
        // let screenSize = '';
        // if (screen.width) {
        //     width = (screen.width) ? screen.width : '';
        //     height = (screen.height) ? screen.height : '';
        //     screenSize += '' + width + " x " + height;
        // }

        // browser
        let nVer = navigator.appVersion;
        let nAgt = navigator.userAgent;
        // let browser = navigator.appName;
        // let version = '' + parseFloat(navigator.appVersion);
        // let majorVersion = parseInt(navigator.appVersion, 10);
        // let nameOffset, verOffset, ix;

        // device
        let deviceType = /\((.*?);/.exec(nVer)[1];

        // // Opera
        // if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
        //   browser = 'Opera';
        //   version = nAgt.substring(verOffset + 6);
        //   if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        //     version = nAgt.substring(verOffset + 8);
        //   }
        // }
        // // Opera Next
        // if ((verOffset = nAgt.indexOf('OPR')) !== -1) {
        //   browser = 'Opera';
        //   version = nAgt.substring(verOffset + 4);
        // }
        // // Legacy Edge
        // else if ((verOffset = nAgt.indexOf('Edge')) !== -1) {
        //   browser = 'Microsoft Legacy Edge';
        //   version = nAgt.substring(verOffset + 5);
        // }
        // // Edge (Chromium)
        // else if ((verOffset = nAgt.indexOf('Edg')) !== -1) {
        //   browser = 'Microsoft Edge';
        //   version = nAgt.substring(verOffset + 4);
        // }
        // // MSIE
        // else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
        //   browser = 'Microsoft Internet Explorer';
        //   version = nAgt.substring(verOffset + 5);
        // }
        // // Chrome
        // else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
        //   browser = 'Chrome';
        //   version = nAgt.substring(verOffset + 7);
        // }
        // // Safari
        // else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
        //   browser = 'Safari';
        //   version = nAgt.substring(verOffset + 7);
        //   if ((verOffset = nAgt.indexOf('Version')) !== -1) {
        //     version = nAgt.substring(verOffset + 8);
        //   }
        // }
        // // Firefox
        // else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
        //   browser = 'Firefox';
        //   version = nAgt.substring(verOffset + 8);
        // }
        // // MSIE 11+
        // else if (nAgt.indexOf('Trident/') !== -1) {
        //   browser = 'Microsoft Internet Explorer';
        //   version = nAgt.substring(nAgt.indexOf('rv:') + 3);
        // }
        // // Other browsers
        // else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        //   browser = nAgt.substring(nameOffset, verOffset);
        //   version = nAgt.substring(verOffset + 1);
        //   if (browser.toLowerCase() == browser.toUpperCase()) {
        //     browser = navigator.appName;
        //   }
        // }
        // // trim the version string
        // if ((ix = version.indexOf(';')) !== -1) version = version.substring(0, ix);
        // if ((ix = version.indexOf(' ')) !== -1) version = version.substring(0, ix);
        // if ((ix = version.indexOf(')')) !== -1) version = version.substring(0, ix);

        // majorVersion = parseInt('' + version, 10);
        // if (isNaN(majorVersion)) {
        //   version = '' + parseFloat(navigator.appVersion);
        //   majorVersion = parseInt(navigator.appVersion, 10);
        // }

        // mobile version
        // let mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

        // cookie
        // let cookieEnabled = (navigator.cookieEnabled) ? true : false;

        // if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
        //   document.cookie = 'testcookie';
        //   cookieEnabled = (document.cookie.indexOf('testcookie') !== -1) ? true : false;
        // }

        // system
        let os = unknown;
        let clientStrings = [
            { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
            { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
            { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
            { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
            { s: 'Windows Vista', r: /Windows NT 6.0/ },
            { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
            { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
            { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
            { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
            { s: 'Windows 98', r: /(Windows 98|Win98)/ },
            { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
            {
                s: 'Windows NT 4.0',
                r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/,
            },
            { s: 'Windows CE', r: /Windows CE/ },
            { s: 'Windows 3.11', r: /Win16/ },
            { s: 'Android', r: /Android/ },
            { s: 'Open BSD', r: /OpenBSD/ },
            { s: 'Sun OS', r: /SunOS/ },
            { s: 'Chrome OS', r: /CrOS/ },
            { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
            { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
            { s: 'Mac OS X', r: /Mac OS X/ },
            {
                s: 'Mac OS',
                r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/,
            },
            { s: 'QNX', r: /QNX/ },
            { s: 'UNIX', r: /UNIX/ },
            { s: 'BeOS', r: /BeOS/ },
            { s: 'OS/2', r: /OS\/2/ },
            {
                s: 'Search Bot',
                r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
            },
        ];
        for (let id in clientStrings) {
            let cs = clientStrings[id];
            if (cs.r.test(nAgt)) {
                os = cs.s;
                break;
            }
        }

        let osVersion = unknown;

        if (/Windows/.test(os)) {
            osVersion = /Windows (.*)/.exec(os)[1];
            os = 'Windows';
        }

        switch (os) {
            case 'Mac OS':
            case 'Mac OS X':
            case 'Android':
                osVersion =
                    /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(
                        nAgt
                    )[1];
                break;

            case 'iOS':
                let parseOosVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
                osVersion =
                    parseOosVersion[1] + '.' + parseOosVersion[2] + '.' + (parseOosVersion[3] || 0);
                break;
        }

        // Context
        return {
            ip: userIpAddress,
            userAgent: navigator.userAgent,
            adBlocker: adBlockDetected,
            // mobile: mobile,
            // cookies: cookieEnabled,
            app: {
                name: appName,
                version: appVersion,
                namespace: '',
                build: '',
            },
            device: {
                type: deviceType,
            },
            os: {
                name: os,
                version: osVersion,
            },
            // screen: {
            //   width: width,
            //   height: height
            // },
            // browser: {
            //   browser: browser,
            //   browserVersion: version,
            //   browserMajorVersion: majorVersion,
            // }
        };
    } catch (e) {
        console.error(e);
        return {};
    }
}

export default function useTracker() {
    const { listeners, trackingHistory, setTrackingHistory } = useContext(TrackerContext);

    function pageView(url) {
        if (!url || url === '') return;

        if (process.env.NEXT_PUBLIC_ANALYTICS_DEBUG === 'true') {
            const debugTrackData = [
                {
                    event: 'Page Viewed',
                    code: 'PAGE_VIEWED',
                    data: url,
                },
            ];
            console.table(debugTrackData);
        }

        if (global && global.analytics) global.analytics.page(url);
    }

    function identify(user) {
        if (!user || !user.id) {
            // Clear currently set user
            Sentry.configureScope((scope) => scope.setUser(null));
            return;
        }

        const { id, email } = user;
        Sentry.setUser({ id: id, email: email });

        if (process.env.NEXT_PUBLIC_ANALYTICS_DEBUG === 'true') {
            const debugTrackData = [
                {
                    event: 'Identify',
                    code: id,
                    data: user,
                },
            ];
            console.table(debugTrackData);
        }

        if (!global || !global.analytics) return;

        fetch('/api/segment/identify', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify({ id: id.toString() }),
        })
            .then((res) => res.json())
            .then((res) => {
                global.analytics.identify(
                    id,
                    {
                        email: user.email,
                        name: user.firstName,
                    },
                    {
                        integrations: {
                            Intercom: {
                                user_hash: res.hash,
                            },
                        },
                    }
                );
            });
    }

    function trackEvent(event) {
        const { type } = event || {};

        if (!type) return null;

        listeners?.map((listener) => {
            if (typeof listener !== 'function') return;

            let listenerType = listener.eventType;
            let wildcard = listenerType === '*' || typeof listenerType === 'undefined';

            if (
                wildcard ||
                (typeof listenerType === 'string' && listenerType === type) ||
                (Array.isArray(listenerType) && listenerType.includes(type))
            ) {
                const saveEvent = listener(event, trackingHistory);

                if (saveEvent) {
                    setTrackingHistory((t) => [...t, saveEvent]);
                }
            }
        });
    }

    async function getUserTrackingIds({ adBlockDetected, savedContext }) {
        // Segment User ID
        let segmentAnonymousId = getAnonymousId();

        // Google Analytic User ID
        let userGATracking = null;
        if (global.ga) {
            await global.ga(function (tracker) {
                const userGAId = tracker.get('clientId');
                userGATracking = userGAId ? { 'Google Analytics': { clientId: userGAId } } : null;
            });
        }

        // Facebook ids, required to keep tracking consistent between front & back and FB Pixel & FB Conversions API
        // https://segment.com/blog/facebook-conversions-api-segment/
        // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
        let userFBTracking = null;
        if (window.document.cookie) {
            let fbp = /_fbp=(fb\.\d+\.\d+\.\d+)/.exec(window.document.cookie);
            let fbc = /_fbc=(fb\.\d+\.\d+\.\S+);/.exec(window.document.cookie);
            if ((fbp && fbp[1]) || (fbc && fbc[1])) {
                userFBTracking = {
                    'Facebook Pixel': {
                        ...(fbp && fbp[1] ? { fbp: fbp[1] } : null),
                        ...(fbc && fbc[1] ? { fbc: fbc[1] } : null),
                    },
                };
            }
        }

        // Client context
        const context = {
            Context: savedContext || (await clientDetection(adBlockDetected)),
        };

        return {
            trackingId: segmentAnonymousId || '',
            integrationsData: {
                ...context,
                ...userGATracking,
                ...userFBTracking,
            },
        };
    }

    return {
        pageView,
        identify,
        trackEvent,
        getUserTrackingIds,
    };
}
