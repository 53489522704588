import { useContext } from 'react';
import { CheckoutContext } from './CheckoutProvider';

export default function useCheckout() {
    const { loading, error, checkoutBase64Id, checkoutToken, paymentMethod, setPaymentMethod } =
        useContext(CheckoutContext);

    return {
        loading,
        error,
        checkoutBase64Id,
        checkoutToken,
        paymentMethod,
        setPaymentMethod,
    };
}
