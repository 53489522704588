// @ts-nocheck

import React, { useReducer, ReactChildren, ReactChild, useState, useEffect } from 'react';
import createSharedReactContext from 'create-shared-react-context';
import { ApolloProvider, ApolloClient } from '@apollo/client';
import { Provider } from 'react-redux';
import { get_, dataTransformers } from 'sf-modules';
import { ChatBubbleSadIcon } from 'icons/solid';
import TrackerProvider from './hooks/tracking/TrackerProvider';
import { useApollo } from './lib/apollo';
import { useStore } from './store';
import reducers from './commerce/reducers';
import { ICommerceConfig, DCommerceConfig } from './types/ICommerceConfig';

export const MODAL_KIND = {
    LOGIN: 'LOGIN',
    SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
    RESET_PASSWORD: 'RESET_PASSWORD',
    ALERT: 'ALERT',
    REQUIRE_SUPPORT: 'REQUIRE_SUPPORT',
    RESET_CHECKOUT: 'RESET_CHECKOUT',
    ADD_PRODUCT_BY_WEIGHT: 'ADD_PRODUCT_BY_WEIGHT',
    CHANGE_DELIVERY_LOCATION: 'CHANGE_DELIVERY_LOCATION',
    PRODUCT_NOT_AVAILABLE: 'PRODUCT_NOT_AVAILABLE',
    PRODUCT_OUT_OF_STOCK: 'PRODUCT_OUT_OF_STOCK',
    SCAN_PRODUCT: 'SCAN_PRODUCT',
    CASH_PAYMENT: 'CASH_PAYMENT',
    SELECT_CREDIT_OR_DEBIT: 'SELECT_CREDIT_OR_DEBIT',
    MULTIPLE_PAYMENT_METHODS: 'MULTIPLE_PAYMENT_METHODS',
    STORE_SELECT: 'STORE_SELECT',
    CASH_BOX_DIFFERENCE_IN_TOTALS: 'CASH_BOX_DIFFERENCE_IN_TOTALS',
    PROMOTIONS_LIST: 'PROMOTIONS_LIST',
    CASHBOX_SELECT: 'CASHBOX_SELECT',
    REQUEST_INVOICE: 'REQUEST_INVOICE',
    EDIT_PAYMENT_METHOD: 'EDIT_PAYMENT_METHOD',
};

export const APP_STATE_TYPES = {
    TOGGLE_MODAL: 'TOGGLE_MODAL',
    CLOSE_MODAL: 'CLOSE_MODAL',

    CONFIG_SET: 'CONFIG_SET',

    CATALOG_RESET_SETTINGS: 'CATALOG_RESET_SETTINGS',
    CATALOG_SET_SETTINGS: 'CATALOG_SET_SETTINGS',
    CATALOG_ADD_SETTINGS: 'CATALOG_ADD_SETTINGS',
    CATALOG_SETTINGS_DELETE: 'CATALOG_SETTINGS_DELETE',

    CART_ENQUEUE_PRODUCTS_ARRAY: 'CART_ENQUEUE_PRODUCTS_ARRAY',
    CART_ENQUEUE_PRODUCT: 'CART_ENQUEUE_PRODUCT',
    CART_DEQUEUE_PRODUCT: 'CART_DEQUEUE_PRODUCT',
    CART_EMPTY_QUEUE: 'CART_EMPTY_QUEUE',

    CART_SET_UNAVAILABLE_PRODUCTS: 'CART_SET_UNAVAILABLE_PRODUCTS',
    CART_EMPTY_UNAVAILABLE_PRODUCTS: 'CART_EMPTY_UNAVAILABLE_PRODUCTS',
    CART_COPY_TO_UNAVAILABLE_PRODUCTS: 'CART_COPY_TO_UNAVAILABLE_PRODUCTS',

    CHKT_SET_PAID_AMOUNT: 'CHKT_SET_PAID_AMOUNT',
    CHKT_RESET_PAID_AMOUNT: 'CHKT_RESET_PAID_AMOUNT',

    SET_LAST_ADDED_SKU: 'SET_LAST_ADDED_SKU',
    REMOVE_SKU_FROM_LAST_ADDED: 'REMOVE_SKU_FROM_LAST_ADDED',
};

const INITIAL_ADDING_PRODUCT = {
    rawId: null,
    product: null,
    quantity: null,
    setCountersCallback: null,
    addToCartCallback: null,
};

export const INITIAL_APP_STATE = {
    config: {
        promoHeader: {
            visible: false,
        },
    },
    modal: {
        kind: '',
        opened: false,
        props: null,
    },
    catalog: {
        createdAt: null,
        zipCode: null,
        addressId: null,
        store: null,
        pos: null,
        index: null,
        addingProduct: INITIAL_ADDING_PRODUCT,
    },
    pipeCart: [],
    unavailableProducts: [],
    checkout: { paidAmount: 0 },
    lastAddedSKU: null,
};

/** COMMERCE CONTEXT */
interface CommerceContextInterface {
    apolloClient: ApolloClient;
    commerceConfig: ICommerceConfig;
    stateMachine: any | null;
    checkout: any | null;
    checkoutUpdate: Function;
    checkoutReset: Function;
}
export const CommerceContext = createSharedReactContext<CommerceContextInterface>(
    {
        apolloClient: undefined,
        commerceConfig: DCommerceConfig,
        stateMachine: INITIAL_APP_STATE,
        checkout: undefined,
        checkoutUpdate: undefined,
        checkoutReset: undefined,
    },
    'yemaCommerceContext'
);

/** COMMERCE PROVIDER */
export default function CommerceProvider({
    pageProps,
    commerceConfig,
    children,
}: {
    pageProps: any;
    commerceConfig: ICommerceConfig;
    children: ReactChildren | ReactChild;
}) {
    const store = useStore(pageProps.initialReduxState);
    const apolloClient = useApollo(pageProps);
    const stateMachine = useReducer(reducers(commerceConfig.storage), INITIAL_APP_STATE);
    const [_, dispatch] = stateMachine;

    const [checkout, setCheckout] = useState<object | null>(null);

    useEffect(() => {
        // Test session storage access
        try {
            sessionStorage.setItem('yx', 'ok');
        } catch (e) {
            dispatch({
                type: APP_STATE_TYPES.TOGGLE_MODAL,
                kind: MODAL_KIND.ALERT,
                open: true,
                props: {
                    modalTitleIcon: (
                        <ChatBubbleSadIcon className='text-brand-tertiary-900 mx-auto block w-20' />
                    ),
                    modalTitle: 'Las cookies están desactivadas',
                    modalTextNode: (
                        <p className='mb-0 text-center'>
                            Para una mejor experiencia de compra, recomendamos activar las cookies.
                            En el caso contrario, todas las funcionalidades de YEMA no estarán
                            disponibles.
                        </p>
                    ),
                    confirmText: 'Entendido',
                },
            });
            return null;
        }
    }, []);

    /**
     * Update checkout object with new checkout data. Useful for example after calling a mutation, to update context
     * @param newCheckoutData
     * @param updateLines
     */
    const checkoutUpdate = async ({
        newCheckoutData,
        updateLines = true,
    }: {
        newCheckoutData: any;
        updateLines?: boolean;
    }) => {
        if (typeof newCheckoutData !== 'object') return;

        const newCheckout = { ...newCheckoutData };
        if (!updateLines && newCheckout) delete newCheckout.lines;

        let parsedLines;
        if (newCheckout && newCheckout.lines) {
            parsedLines = get_(newCheckout, ['lines'], []).map((l) =>
                dataTransformers.transformCheckoutLineFromPIM(l, newCheckout)
            );
        }

        //@TODO Ludo: edge case where it seems that value is not replaced by null
        // For example after calling removeShippingAddress, should update shippingAddress to null, but old value sticks here
        setCheckout((checkout) => ({
            ...checkout,
            ...newCheckout,
            ...(parsedLines && parsedLines.length && { parsedLines }),
        }));
    };

    const checkoutReset = () => {
        setCheckout(null);
    };

    if (!apolloClient) return <div className='bg-ui-gray-700 h-screen' />;

    return (
        <CommerceContext.Provider
            value={{
                apolloClient,
                commerceConfig,
                stateMachine,
                checkout,
                checkoutUpdate,
                checkoutReset,
            }}
        >
            <Provider store={store}>
                <ApolloProvider client={apolloClient}>
                    <TrackerProvider>{children}</TrackerProvider>
                </ApolloProvider>
            </Provider>
        </CommerceContext.Provider>
    );
}
