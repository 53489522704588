export async function fnDoMutation(
    cb,
    variables,
    optimisticResponse = undefined,
    update = undefined,
    timeout = undefined
) {
    const timeoutValue = timeout ? { timeout: timeout } : {};
    return await cb({
        context: {
            ...timeoutValue,
        },
        variables: variables,
        optimisticResponse: optimisticResponse,
        update: update,
    })
        .then((res) => res)
        .catch((err) => {
            // Error tracking is handled by apollo-link-error
            return err && err.message ? err.message : err;
        });
}
