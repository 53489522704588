import configReducer from './configReducer';
import modalReducer from './modalReducer';
import catalogReducer from './catalogReducer';
import pipeCartReducer from './pipeCartReducer';
import unavailableProductsReducer from './unavailableProductsReducer';
import checkoutReducer from './checkoutReducer';
import lastAddedSKUReducer from './lastAddedSKUReducer';

const reducers =
    (storageConfig) =>
    ({ config, modal, catalog, pipeCart, unavailableProducts, checkout, lastAddedSKU }, action) => {
        // middleware goes here

        return {
            config: configReducer(config, action),
            modal: modalReducer(modal, action),
            catalog: catalogReducer(catalog, action, storageConfig),
            pipeCart: pipeCartReducer(pipeCart, action),
            unavailableProducts: unavailableProductsReducer(pipeCart, unavailableProducts, action),
            checkout: checkoutReducer(checkout, action),
            lastAddedSKU: lastAddedSKUReducer(lastAddedSKU, action),
        };
    };

export default reducers;
