import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { get_, stringify, money, time } from 'sf-modules';
import { trackError } from '../../utils/error';
import { fnDoMutation } from '../../utils/fnDoMutation';
import {
    getDeliveryScheduleList,
    getScheduleDate,
    isScheduleMomentPast,
} from '../../utils/fnDeliverySchedule';
import useCommerce from '../../useCommerce';
import useCheckout from './useCheckout';
import useCheckoutUpdate from './useCheckoutUpdate';
import { useCheckoutFunnel } from './useCheckoutFunnel';
import CHECKOUT_LOCATION_SCHEDULE_MUTATION from '../../graphql/mutations/checkout/checkoutLocationSchedule.gql';
import CHECKOUT_SCHEDULE_DELIVERY_UPDATE_MUTATION from '../../graphql/mutations/checkout/checkoutScheduleDeliveryUpdate.gql';

export default function useDeliverySchedule() {
    const { nextFunnelStep } = useCheckoutFunnel();
    const { checkout: checkoutData } = useCommerce();
    const { checkoutBase64Id } = useCheckout();
    const checkoutUpdate = useCheckoutUpdate();

    const [loading, setLoading] = useState(true);
    const [isSyncing, setIsSyncing] = useState(false);

    const [selectedDeliveryMethodId, setSelectedDeliveryMethodId] = useState(null);
    const [availableDeliverySchedules, setAvailableDeliverySchedules] = useState([]);
    const [deliveryWindows, setDeliveryWindows] = useState([]);
    const [selectedSchedule, setSelectedSchedule] = useState(null);

    const [formattedSchedule, setFormattedSchedule] = useState({
        date: null,
        price: null,
        time: null,
    });

    const [mutationLocationSchedule] = useMutation(CHECKOUT_LOCATION_SCHEDULE_MUTATION);
    const [mutationScheduleDelivery] = useMutation(CHECKOUT_SCHEDULE_DELIVERY_UPDATE_MUTATION);

    const setShippingSchedule = async (schedule) => {
        if (!schedule || !schedule.dateId) return false;
        const data = await fnDoMutation(mutationScheduleDelivery, {
            checkout: checkoutBase64Id,
            schedule: JSON.parse(schedule.dateId),
        });

        const errors = get_(data, ['data', 'checkoutScheduledDeliveryUpdate', 'errors']);
        if (errors && errors.length > 0) {
            trackError({
                label: 'Bad settings for shipping method in Saleor',
                extras: {
                    errors: errors,
                    firstErrorField: get_(errors, [0, 'field']),
                    firstErrorMessage: get_(errors, [0, 'message']),
                    checkoutID: checkoutBase64Id,
                },
            });
        } else {
            checkoutUpdate({
                newCheckoutData: get_(data, [
                    'data',
                    'checkoutScheduledDeliveryUpdate',
                    'checkout',
                ]),
                updateLines: false,
            });
        }

        // Return true even in case of error to not block the process
        return true;
    };

    const handleSelectDeliverySchedule = (schedule) => {
        setSelectedSchedule(schedule);
    };

    const handleConfirmDeliverySchedule = async () => {
        setIsSyncing(true);

        let schedule = selectedSchedule;
        if (!schedule || !schedule.dateId) {
            const dateId = getScheduleDate(get_(availableDeliverySchedules, [0, 'id'], null));
            schedule = { dateId: dateId };
            setSelectedSchedule(schedule);
        }

        setShippingSchedule(schedule).then((res) => {
            if (res) nextFunnelStep();

            setIsSyncing(false);
        });
    };

    const calculateShippingPrice = (price = null) => {
        if (get_(checkoutData, ['hasFreeShipping'], false)) return 'Envío gratis';

        let p = price ? price : get_(checkoutData, ['shippingMethod', 'price', 'amount']);
        if (!p) return 'Precio no indicado';
        if (p === 0) return 'Envío gratis';
        if (p >= 0) return money.formatMoney(p);
    };

    const getStockLocationSchedule = async () => {
        const response = await fnDoMutation(mutationLocationSchedule, {
            checkout: checkoutBase64Id,
        });
        const schedules = get_(response, ['data', 'checkoutLocationSchedule', 'schedule'], []);

        const windows = getDeliveryScheduleList({
            schedule: schedules,
            scheduledDeliveryDaysLimit: get_(checkoutData, [
                'shippingMethod',
                'scheduledDeliveryDaysLimit',
            ]),
        });

        setAvailableDeliverySchedules(schedules);
        setDeliveryWindows(windows);

        if (!schedules || !schedules.length) {
            trackError({
                label: 'No Schedule Location Found',
                extras: {
                    data: stringify(response),
                    checkoutID: checkoutBase64Id,
                },
            });
        }

        return true;
    };

    const getShippingDate = () => {
        if (checkoutData && checkoutData.deliveryEnd && checkoutData.deliveryStart) {
            if (!isScheduleMomentPast(checkoutData.deliveryStart)) return 'Lo antes posible';

            return time.getDateMoment(checkoutData.deliveryEnd);
        }

        return get_(checkoutData, ['shippingMethod', 'name'], '');
    };

    const getShippingTime = (scheduledDeliveryDaysLimit = null) => {
        if (!scheduledDeliveryDaysLimit && checkoutData && checkoutData.deliveryEnd) {
            if (!isScheduleMomentPast(checkoutData.deliveryStart)) return '';

            const start = time.getScheduleFormattedTime(checkoutData.deliveryStart);
            const end = time.getScheduleFormattedTime(checkoutData.deliveryEnd);
            return `${start} - ${end}`;
        }

        if (!get_(checkoutData, ['shippingMethod', 'supportsScheduledDelivery'])) return '';

        const maxDeliveryDays = scheduledDeliveryDaysLimit
            ? scheduledDeliveryDaysLimit
            : get_(checkoutData, ['shippingMethod', 'scheduledDeliveryDaysLimit']);

        return maxDeliveryDays ? `Hasta ${maxDeliveryDays} días` : '';
    };

    const getShippingPrice = () => {
        const method = get_(checkoutData, ['shippingMethod', 'price', 'amount'], '');
        return money.formatMoney(method);
    };

    useEffect(() => {
        // Keep delivery method ID to triggers schedule refetch on change
        if (checkoutData && (!availableDeliverySchedules || !availableDeliverySchedules.length)) {
            let id = get_(checkoutData, ['shippingMethod', 'id']);
            if (id !== selectedDeliveryMethodId) setSelectedDeliveryMethodId(id);
        }

        setFormattedSchedule({
            date: getShippingDate(),
            price: getShippingPrice(),
            time: getShippingTime(),
        });
    }, [checkoutData]);

    useEffect(() => {
        if (selectedDeliveryMethodId) getStockLocationSchedule().then(() => setLoading(false));
    }, [selectedDeliveryMethodId]);

    return {
        loading,
        isSyncing,
        deliveryWindows,
        selectedSchedule,
        formattedSchedule,
        calculateShippingPrice,
        handleSelectDeliverySchedule,
        handleConfirmDeliverySchedule,
    };
}
