import { useState, useEffect } from 'react';
import { get_, time, money } from 'sf-modules';
import useCommerce from '../../useCommerce';
import useSetDeliveryMethod from './useSetDeliveryMethod';
import { useCheckoutFunnel } from './useCheckoutFunnel';
import { isScheduleMomentPast } from '../../utils/fnDeliverySchedule';
import { ECheckoutStepsTypes, TCheckoutFunnels } from '../../types/ECheckoutStepsTypes';

function getShippingTime(scheduledDeliveryDaysLimit = null, checkoutData) {
    if (!scheduledDeliveryDaysLimit && checkoutData && checkoutData.deliveryEnd) {
        if (!isScheduleMomentPast(checkoutData.deliveryStart)) return '';

        const start = time.getScheduleFormattedTime(checkoutData.deliveryStart);
        const end = time.getScheduleFormattedTime(checkoutData.deliveryEnd);
        return `${start} - ${end}`;
    }

    if (!get_(checkoutData, ['shippingMethod', 'supportsScheduledDelivery'])) return '';

    const maxDeliveryDays = scheduledDeliveryDaysLimit
        ? scheduledDeliveryDaysLimit
        : get_(checkoutData, ['shippingMethod', 'scheduledDeliveryDaysLimit']);

    return maxDeliveryDays ? `Hasta ${maxDeliveryDays} días` : '';
}

function calculateShippingPrice(price = null) {
    if (price && price.amount >= 0) {
        if (price.amount === 0) return 'Envío gratis';
        return money.formatMoney(price.amount);
    }
    return 'Precio no indicado';
}

function areSameMethods(l1, l2) {
    if (!l1 || !l1.length) return get_(l1, ['length']) === get_(l2, ['length']);

    for (let i = 0; i < l1.length; i++) {
        let id1 = get_(l1, [i, 'id']);
        let id2 = get_(l2, [i, 'id']);
        if (id1 !== id2) return false;
    }

    return true;
}

export default function useShippingMethod() {
    const { activeStep, ...funnel } = useCheckoutFunnel();
    const { checkout: checkoutData } = useCommerce();
    const { availableShippingMethods } = checkoutData;
    const { setDeliveryMethod } = useSetDeliveryMethod();

    const [isInitializing, setIsInitializing] = useState(true);
    const [shippingMethods, setShippingMethods] = useState([]);

    function filterAvailableDeliveryMethods(methods) {
        return methods?.filter(
            (x) => x.shippingType !== 'store_pickup' && x.shippingType !== 'immediate'
        );
    }

    async function setShippingMethod(selectedMethod) {
        if (!selectedMethod) return false;

        const response = await setDeliveryMethod({
            id: selectedMethod.id,
            shippingType: selectedMethod.shippingType,
            forceUpdateCheckout: true,
        });

        // Update funnel according to selected shipping method
        const availableMethods = filterAvailableDeliveryMethods(
            get_(response, ['availableShippingMethods'], [])
        );
        const deliveryType = get_(response, ['shippingMethod', 'shippingType']);
        const supportsScheduledDelivery = get_(response, [
            'shippingMethod',
            'supportsScheduledDelivery',
        ]);

        if (availableMethods && availableMethods.length === 1 && supportsScheduledDelivery)
            funnel.updateFunnel(
                TCheckoutFunnels.quickDeliveryWithSchedule,
                ECheckoutStepsTypes.DeliverySchedule
            );
        else if (supportsScheduledDelivery && deliveryType === 'store_pickup')
            funnel.updateFunnel(TCheckoutFunnels.storePickUp, ECheckoutStepsTypes.PickupSchedule);
        else if (supportsScheduledDelivery)
            funnel.updateFunnel(
                TCheckoutFunnels.shippingOptionsWithSchedule,
                ECheckoutStepsTypes.DeliverySchedule
            );
        else {
            funnel.updateFunnel(TCheckoutFunnels.default, ECheckoutStepsTypes.PaymentMethod);
        }

        return true;
    }

    useEffect(() => {
        // Parse available shipping methods
        let methods = filterAvailableDeliveryMethods(availableShippingMethods);

        if (areSameMethods(methods, shippingMethods)) return;

        methods?.map((x) => {
            return {
                ...x,
                description: getShippingTime(
                    x.scheduledDeliveryDaysLimit ? x.scheduledDeliveryDaysLimit : null,
                    checkoutData
                ),
                priceAmount: calculateShippingPrice(x.price),
            };
        });
        setShippingMethods(methods);

        // Auto select shipping method
        if (methods && methods.length === 1) {
            setShippingMethod(methods[0]).then(() => {
                setIsInitializing(false);
            });
        } else {
            setIsInitializing(false);
        }
    }, [availableShippingMethods]);

    return {
        loading: isInitializing,
        shippingMethods,
        setShippingMethod,
    };
}
