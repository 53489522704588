import { useEffect, useState } from 'react';

export const ScriptStatus = Object.freeze({
    LOADING: 'loading',
    READY: 'loaded',
    ERROR: 'error',
});

/**
 * Hook to load an external script. Returns true once the script has finished loading.
 *
 * @param url {string} url The external script to load
 * */
export default function useScript(url) {
    const [status, setStatus] = useState(ScriptStatus.LOADING);

    useEffect(() => {
        let script =
            typeof window !== 'undefined' && document.querySelector(`script[src="${url}"]`);

        if (!script && typeof window !== 'undefined') {
            script = document.createElement('script');
            script.src = url;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);

            script.onerror = () => {
                if (script) script.setAttribute('data-status', ScriptStatus.ERROR);
            };
            script.onload = () => {
                if (script) script.setAttribute('data-status', ScriptStatus.READY);
            };
        } else if (script.hasAttribute('data-status')) {
            setStatus(script.getAttribute('data-status'));
        }

        const eventHandler = (e) => {
            setStatus(e.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR);
        };

        // Add load event listener
        script.addEventListener('load', eventHandler);
        script.addEventListener('error', eventHandler);

        return () => {
            if (script) {
                script.removeEventListener('load', eventHandler);
                script.removeEventListener('error', eventHandler);
            }
        };
    }, [url]);

    return [status === ScriptStatus.READY, status];
}
