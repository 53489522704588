import { get_ } from '../tools';
import RulesList from './RulesList';
import RuleSet from './RuleSet';

function getFirstApplicableRule(rules, currentGtin) {
    /**
     * Get first applicable rule matching these conditions:
     * type === ITEMS_IN_CART
     * && (!includeAll || (objects && objects.length === 1 && objects[0] === MY_PRODUCT_GTIN)
     */

    if (!rules || !rules.length) return null;

    const matchingRules = rules.filter((rule) => {
        if (!rule || !rule.conditions) return false;

        return rule.conditions.some((condition) => {
            return (
                condition.type === 'ITEMS_IN_CART' &&
                (!condition.includeAll || condition?.objects?.includes(currentGtin))
            );
        });
    });

    return get_(matchingRules, [0]);
}

export default {
    getFirstApplicableRule,
    RulesList,
    RuleSet,
};
