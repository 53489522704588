import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { CheckoutPricingFieldsFragment } from '../../fragments/checkout/CheckoutPricingFields.gql';
import { CheckoutUserFieldsFragment } from '../../fragments/checkout/CheckoutUserFields.gql';
import { CheckoutExtendedFieldsFragment } from '../../fragments/checkout/CheckoutExtendedFields.gql';
import { LinesBasicFieldsFragment } from '../../fragments/checkout/LinesBasicFields.gql';
import { LinesExtendedFieldsFragment } from '../../fragments/checkout/LinesExtendedFields.gql';
import { LinesAppliedBenefitsFieldsFragment } from '../../fragments/checkout/LinesAppliedBenefitsFields.gql';

const CHECKOUT_VOUCHER_UPDATE_MUTATION = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutPricingFieldsFragment}
    ${CheckoutUserFieldsFragment}
    ${CheckoutExtendedFieldsFragment}
    ${LinesBasicFieldsFragment}
    ${LinesExtendedFieldsFragment}
    ${LinesAppliedBenefitsFieldsFragment}
    mutation CheckoutVoucherUpdate($checkout: ID!, $voucher: String) {
        checkoutUpdateVoucher(checkoutId: $checkout, voucherCode: $voucher) {
            checkout {
                ...CheckoutBasicFields
                ...CheckoutUserFields
                ...CheckoutPricingFields
                ...CheckoutExtendedFields
                lines {
                    ...LinesBasicFields
                    ...LinesExtendedFields
                    ...LinesAppliedBenefitsFields
                }
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_VOUCHER_UPDATE_MUTATION;
