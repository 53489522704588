import { gql } from '@apollo/client';
import { CheckoutFieldsFragment } from '../../fragments/checkout/CheckoutFields.gql';
import { RuleFieldsFragment } from '../../fragments/checkout/RuleFields.gql';

const CHECKOUT_APPLY_RULES_MUTATION = gql`
    ${CheckoutFieldsFragment}
    ${RuleFieldsFragment}
    mutation CheckoutApplyRules(
        $checkout: String
        $withBenefits: Boolean = false
        $withConditions: Boolean = false
    ) {
        checkoutApplyRules(token: $checkout) {
            checkout {
                ...CheckoutFields
                cartRules {
                    ...RuleFields
                }
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_APPLY_RULES_MUTATION;
