const base64 = require('base-64');

const set = (key, value, withEncoding = true) => {
    try {
        sessionStorage.setItem(key, withEncoding ? base64.encode(JSON.stringify(value)) : value);
    } catch (e) {
        return null;
    }
};

const get = (key, withEncoding = true) => {
    try {
        if (!withEncoding) return sessionStorage.getItem(key);

        const base64Value = sessionStorage.getItem(key);
        if (!base64Value) return null;
        const decode = base64.decode(base64Value);
        return JSON.parse(decode);
    } catch (e) {
        return null;
    }
};

const remove = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (e) {
        return null;
    }
};

// const clear = () => {
//   sessionStorage.clear();
// };

export default { set, get, remove };
