import { gql } from '@apollo/client';

/**
 * GraphQL Fragment
 *
 */
export const ProductFieldsForCheckoutFragment = gql`
    fragment ProductFieldsForCheckout on Product {
        id
        gtin
        name
        slug
        brand
        isBundle
        stockUnit
        checkoutMaxQuantity
        mainImage
        sellingType
        sellingValue
        sellingUnit
        weightPerPieceValue
        weightPerPieceUnit
        incrementValue
        incrementUnit
        applicableRules {
            id
            title
            benefits {
                id
                discountString
                type
            }
        }

        plmId
        rawId
        analyticsId

        sku @client
        href @client
        mainImageAlt @client
        stepperIncrement @client
    }
`;
