import { gql } from '@apollo/client';

export const CheckoutPricingFieldsFragment = gql`
    fragment CheckoutPricingFields on Checkout {
        subtotalPrice
        merchandiseAmount
        merchandiseDiscountedAmount
        campaignDiscount
        hasFreeShipping
        shippingDiscount
        voucherDiscount
        voucherCode
        discountName
        discountAmount {
            amount
        }
        shippingMethod {
            id
            name
            shippingType
            price {
                amount
            }
            requiresCustomerAddress
            supportsScheduledDelivery
            scheduledDeliveryDaysLimit
            deliveryPromise
        }
        shippingPrice {
            gross {
                amount
            }
        }
        ruleDiscounts {
            amount
            ruleTitle
        }
    }
`;
