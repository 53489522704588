// resource for handling cookies taken from here:
// https://github.com/carlos-peru/next-with-api/blob/master/lib/session.js

import cookie from 'js-cookie';
import stringify from '../stringify';
const base64 = require('base-64');

const getCookieFromBrowser = (key) => cookie.get(key);

const getCookieFromServer = (key, req) => {
    if (!req.headers.cookie) return undefined;

    const rawCookie = req.headers.cookie.split(';').find((c) => c.trim().startsWith(`${key}=`));
    if (!rawCookie) return undefined;

    return rawCookie.split('=')[1];
};

const set = (key, value, expire, secure, encode = false) => {
    if (typeof window !== 'undefined') {
        const cookieData = encode ? btoa(encodeURIComponent(stringify(value))) : value;
        cookie.set(key, cookieData, {
            expires: expire,
            path: '/',
            secure: secure,
            SameSite: 'Lax',
        });
    }
};

const remove = (key) => {
    if (!key) return undefined;
    if (typeof window !== 'undefined')
        cookie.remove(key, {
            expires: 1,
        });
};

const get = (key, req, decode = false) => {
    if (!key) return undefined;
    const cookieData =
        typeof window !== 'undefined'
            ? getCookieFromBrowser(key)
            : req
            ? getCookieFromServer(key, req)
            : undefined;

    return decode && cookieData
        ? JSON.parse(decodeURIComponent(base64.decode(cookieData)))
        : cookieData;
};

export default { set, get, remove };
