import { gql } from '@apollo/client';

export const LinesForAnalyticsFieldsFragment = gql`
    fragment LinesForAnalyticsFields on CheckoutLine {
        totalPrice
        variantPricing {
            price
            priceUndiscounted
            baseMeasurePrice
            baseMeasurePriceUndiscounted
        }
        bundlePricing {
            price
            priceUndiscounted
        }
        variant {
            id
            product {
                id
                name
                slug
                brand
            }
        }
        bundle {
            id
            name
            slug
            sku
        }
    }
`;
