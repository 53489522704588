const base64 = require('base-64');

const set = (key, value) => {
    try {
        const base64Value = base64.encode(JSON.stringify(value));
        localStorage.setItem(key, base64Value);
    } catch (e) {
        return null;
    }
};

const get = (key) => {
    try {
        const base64Value = localStorage.getItem(key);
        if (!base64Value) return null;
        const decode = base64.decode(base64Value);
        return JSON.parse(decode);
    } catch (e) {
        return null;
    }
};

const remove = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        return null;
    }
};

// const clear = () => {
//   localStorage.clear();
// };

export default { set, get, remove };
