import { IDENTIFY_USER_EMAIL, AUTHENTICATE, DEAUTHENTICATE, SET_GUEST_CUSTOMER } from '../types';

const initialState = {
    id: null,
    token: null,
    isStaff: false,
    email: '',
    isStaff: false,
    firstName: '',
    authMethod: '',
    init: false,
};

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case IDENTIFY_USER_EMAIL:
            return {
                ...initialState,
                email: action.payload.email,
                init: true,
            };
        case AUTHENTICATE:
            return {
                id: action.payload.id,
                token: action.payload.token,
                isStaff: !!action.payload.isStaff,
                email: action.payload.email,
                firstName: action.payload.firstName,
                authMethod: action.payload.authMethod,
                init: true,
            };
        case DEAUTHENTICATE:
            return {
                ...initialState,
                init: true,
            };
        case SET_GUEST_CUSTOMER:
            return {
                ...initialState,
                init: true,
            };
        default:
            return state;
    }
}
