import PropTypes from 'prop-types';
import Alert from '../Alert';
import Button from '../../atoms/Button';
import ClickToChat from '../../atoms/ClickToChat';

const ErrorAlert = ({ title, errorMessage, buttonText, buttonOnClick }) => (
    <div className='flex flex-col justify-center'>
        <Alert
            variant='error'
            title={title}
            text={
                <>
                    {typeof errorMessage === 'string'
                        ? errorMessage
                        : Array.isArray(errorMessage)
                        ? errorMessage.map((m) => m.message).join(' ')
                        : ''}
                    <br />
                    Si el problema sigue, <ClickToChat>escríbenos por chat.</ClickToChat>
                </>
            }
        />
        {buttonText && (
            <Button className='mx-auto' onClick={buttonOnClick} size='sm' type='button'>
                {buttonText}
            </Button>
        )}
    </div>
);

ErrorAlert.defaultProps = {
    title: 'Error',
    buttonText: 'Volver a intentar',
    buttonOnClick: () => window.location.reload(),
};

ErrorAlert.propTypes = {
    title: PropTypes.string,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    buttonText: PropTypes.string,
    buttonOnClick: PropTypes.func,
};

export default ErrorAlert;
