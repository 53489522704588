import { gql } from '@apollo/client';
import { AddressFieldsFragment } from '../generic/AddressFields.gql';
import { PaymentFieldsFragment } from './PaymentFields.gql';

export const CheckoutExtendedFieldsFragment = gql`
    ${AddressFieldsFragment}
    ${PaymentFieldsFragment}
    fragment CheckoutExtendedFields on Checkout {
        store {
            id
            name
            active
            algoliaIndex
            defaultOnPos
            stockLocation {
                id
                name
                type
                address {
                    ...AddressFields
                }
                officeHours(first: 7) {
                    edges {
                        node {
                            id
                            dayDisplay
                            isOpen
                            opening
                            closing
                        }
                    }
                }
            }
        }
        payments {
            ...PaymentFields
        }
        created
        shippingAddress {
            ...AddressFields
        }
        availableShippingMethods {
            id
            name
            shippingType
            price {
                amount
            }
            requiresCustomerAddress
            supportsScheduledDelivery
            scheduledDeliveryDaysLimit
            deliveryPromise
        }
        deliveryStart
        deliveryEnd
        cartRules {
            id
            title
            description
            priority
            conditions {
                id
                type
                objects
            }
        }
    }
`;
