import { useMemo } from 'react';
import Router from 'next/router';
import { ApolloClient, ApolloLink, HttpLink, gql } from '@apollo/client';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { RetryLink } from 'apollo-link-retry';
import { onError } from 'apollo-link-error';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import get_ from 'lodash/get';
import { cookie, localstorage } from 'sf-modules';
import Queue from '../utils/queue';
import { trackError } from '../utils/error';
import { resetCheckout } from '../store/actions/cartActions';
import { cache, createNewCache } from './cache';
import { getAnonymousId } from '../hooks/tracking/useTracker';

const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';
const APOLLO_CONTEXT_API_CMS = process.env.NEXT_PUBLIC_API_CONTEXT_CMS || 'cms_api';
const AUTH_COOKIE = process.env.NEXT_PUBLIC_COOKIE_AUTH || 'y_usr';

// On the client, we store the Apollo Client in the following variable.
// This prevents the client from reinitializing between page transitions.
let globalApolloClient = null;
const timeoutLink = new ApolloLinkTimeout(25000);
const isBrowser = typeof window !== 'undefined';
const base64 = require('base-64');

export const bufferedGqlResponses =
    process.env.NEXT_PUBLIC_APP_TYPE === 'pos' ? new Queue(10) : null;

const apiAfterware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        const ctx = operation?.getContext() || null;
        const apiUrl = ctx?.response?.url || null;

        // For debugging purpose on POS
        if (process.env.NEXT_PUBLIC_APP_TYPE === 'pos' && response)
            bufferedGqlResponses.enqueue(response);

        // Catch expired/not found checkout ID
        if (
            apiUrl === process.env.NEXT_PUBLIC_API_ECOM_URL &&
            operation.variables &&
            (operation.variables.token || operation.variables.checkoutId)
        ) {
            let errorMessage = get_(
                Object.values(get_(response, ['data'])),
                [0, 'errors', 0, 'message'],
                ''
            );
            if (
                errorMessage === 'Checkout con ese ID no existe' ||
                errorMessage.startsWith("Couldn't resolve to a node")
            ) {
                trackError({
                    label: "Checkout ID has been reset from user's browser",
                    extras: {
                        checkoutToken: operation.variables.token,
                        checkoutId: operation.variables.checkoutId,
                        error: errorMessage,
                    },
                });
                resetCheckout();
                Router.reload(); // Reload is required to purge redux store
            }
        }

        return response;
    });
});

const errorLink = onError(
    ({
        networkError,
        graphQLErrors,
        operation,
        // response,
        // forward
    }) => {
        if (graphQLErrors) {
            let fullErrorMessage = '';
            graphQLErrors.map((err) => (fullErrorMessage = fullErrorMessage + err.message + '\n'));
            fullErrorMessage = fullErrorMessage + (networkError?.message || '');

            trackError({
                error: new Error(fullErrorMessage),
                extras: {
                    operationName: get_(operation, ['operationName']),
                    variables: operation.variables,
                },
                tags: {
                    operationName: get_(operation, ['operationName']),
                },
                path: graphQLErrors[0].path,
            });

            if (networkError) networkError.message = fullErrorMessage;
        } else if (networkError) {
            trackError({
                error: networkError,
                extras: {
                    operationName: get_(operation, ['operationName']),
                    variables: operation.variables,
                },
                tags: {
                    operationName: get_(operation, ['operationName']),
                },
            });
        }
    }
);

// Create link to Wagtail API
const APIWagtailLink = timeoutLink.concat(
    new HttpLink({
        uri: process.env.NEXT_PUBLIC_API_CMS_URL,
        credentials: 'same-origin',
        fetch: !isBrowser && fetch,
    })
);

// Create Second Link
const APIEcomLink = timeoutLink.concat(
    new HttpLink({
        uri: process.env.NEXT_PUBLIC_API_ECOM_URL,
        credentials: 'same-origin',
        fetch: !isBrowser && fetch,
    })
);

const apiMiddleware = new ApolloLink(async (operation, forward) => {
    const ctx = operation.getContext();
    const authInfos = cookie.get(AUTH_COOKIE);
    const token = !authInfos ? '' : JSON.parse(decodeURIComponent(base64.decode(authInfos))).token;

    const headerAuth =
        ctx.clientName !== APOLLO_CONTEXT_API_CMS && token
            ? { authorization: `JWT ${token}` }
            : null;

    const headerOrigin =
        ctx.clientName !== APOLLO_CONTEXT_API_CMS
            ? { 'Y-Origin': process.env.NEXT_PUBLIC_APP_TYPE }
            : null;

    const anonymousId = getAnonymousId();

    const headerAnonymousId =
        anonymousId && ctx.clientName !== APOLLO_CONTEXT_API_CMS
            ? { 'Y-Anonymous-Id': anonymousId }
            : null;

    // @TODO Ludo: should move CATALOG_LS to .env file
    const catalogSettings = localstorage.get(
        process.env.NEXT_PUBLIC_APP_TYPE === 'pos' ? 'yp_pos_catalog' : 'y_catalog'
    );
    let headerStoreId =
        ctx.clientName !== APOLLO_CONTEXT_API_CMS && catalogSettings?.store?.id
            ? { 'Y-Store': catalogSettings.store.id }
            : null;

    // We allow here to publicly add CF token & secret since our app is behind CF Access.
    // In case the app should be publicly accessible, this would not be the correct way to
    // pass cloudflare access protection for API.
    const cfAccessToken =
        process.env.NEXT_PUBLIC_CLOUDFLARE_TOKEN_CLIENT &&
        ctx.clientName !== APOLLO_CONTEXT_API_CMS &&
        process.env.NEXT_PUBLIC_API_ECOM_URL.startsWith('https')
            ? {
                  'CF-Access-Client-Id': process.env.NEXT_PUBLIC_CLOUDFLARE_TOKEN_CLIENT,
                  'CF-Access-Client-Secret': process.env.NEXT_PUBLIC_CLOUDFLARE_TOKEN_SECRET,
              }
            : {};

    operation.setContext(({ headers = {} }) => ({
        headers: {
            'Accept-Language': 'es',
            accept: 'application/json',
            ...headers,
            ...headerAuth,
            ...headerOrigin,
            ...headerStoreId,
            ...headerAnonymousId,
            ...cfAccessToken,
        },
    }));

    return forward(operation);
});

function createApolloClient(existingCache) {
    return new ApolloClient({
        connectToDevTools:
            isBrowser &&
            (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'local' ||
                process.env.NEXT_PUBLIC_DEPLOY_ENV === 'development'),
        ssrMode: !isBrowser, // Disables forceFetch on the server (so queries are only run once)
        link: ApolloLink.from([
            apiMiddleware,
            errorLink,
            apiAfterware,
            new RetryLink({
                delay: {
                    initial: 600,
                    max: Infinity,
                    jitter: true,
                },
                attempts: {
                    max: 3,
                    retryIf: (error, _operation) => !!error,
                },
            }).split(
                // Routes the query to the proper client
                (operation) => operation.getContext().clientName === APOLLO_CONTEXT_API_CMS,
                APIWagtailLink,
                APIEcomLink
            ),
        ]),
        // Network requests are not fired when importing same cache instance & prevent revalidating ISR pages with fresh content
        // Solution explained in link is to create new cache on SSR requests
        // https://github.com/apollographql/apollo-client/issues/9066#issuecomment-1192852656
        cache: isBrowser ? existingCache || cache : createNewCache(),
        typeDefs: gql`
            enum AddressTypeEnum {
                BILLING
                SHIPPING
            }
            enum CartRuleObjectTypes {
                CHECKOUT
                PRODUCT
                BUNDLE
                PAYMENT_METHOD
                SHIPPING_METHOD
                ZIP_CODE
            }
        `,
        // defaultOptions: {
        //     query: {
        //         fetchPolicy: 'no-cache',
        //         errorPolicy: 'all'
        //     },
        // },
    });
}

export function initializeApollo(initialState = null) {
    const _apolloClient = globalApolloClient ?? createApolloClient();

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the initialState from getStaticProps/getServerSideProps in the existing cache
        const data = merge(existingCache, initialState, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [
                ...sourceArray,
                ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s))),
            ],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!globalApolloClient) globalApolloClient = _apolloClient;

    return _apolloClient;
}

export function addApolloState(client, pageProps) {
    if (pageProps?.props) {
        pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();
    }

    return pageProps;
}

export function useApollo(pageProps) {
    const state = pageProps[APOLLO_STATE_PROP_NAME];
    return useMemo(() => initializeApollo(state), [state]);
}
