import { useState, useEffect } from 'react';
import { get_ } from 'sf-modules';
import { trackError } from '../../utils/error';
import * as transform from '../../transformers';
import useStateEngine from '../../useStateEngine';
import useCommerce from '../../useCommerce';
import useCheckoutError from './useCheckoutError';

export default function useGetDeliveryMethods({ skip = false }) {
    const [{ catalog }] = useStateEngine();

    const { setCheckoutError } = useCheckoutError();
    const { checkout: checkoutData } = useCommerce();

    const [loading, setLoading] = useState(true);
    const [deliveryOptions, setDeliveryOptions] = useState([]);
    const [storeHours, setStoreHours] = useState([]);

    useEffect(() => {
        if (!skip) {
            let store = get_(checkoutData, ['store']);

            if (!store) {
                trackError({
                    label: 'checkoutData has no store information',
                    extras: {
                        location: 'useGetDeliveryMethods > useEffect',
                        zipCode: get_(catalog, ['zipCode']),
                        data: checkoutData,
                        store: store,
                    },
                });
                setCheckoutError({
                    withCheckoutReset: false,
                    title: '¡Ups!',
                    errorType: 'Ocurrió un error con tu carrito',
                    errorDescription:
                        'No dudes en contactarnos para que te podamos ayudar a completar tu pedido',
                });
                setLoading(false);
                return;
            }

            if (
                !checkoutData ||
                !checkoutData.availableShippingMethods ||
                !checkoutData.availableShippingMethods.length
            ) {
                setDeliveryOptions([]);
            } else {
                let deliveryMethods = get_(checkoutData, ['availableShippingMethods'], []);
                let hasStorePickupOption = deliveryMethods.find(
                    (x) => x.shippingType === 'store_pickup'
                );
                if (hasStorePickupOption) {
                    // Parse store hours
                    let hours = get_(
                        checkoutData,
                        ['store', 'stockLocation', 'officeHours', 'edges'],
                        []
                    ).map((h) => h.node);
                    setStoreHours(transform.parseStoreHours(hours));
                }

                setDeliveryOptions(deliveryMethods);
                setLoading(false);
            }
        }
    }, [
        skip,
        checkoutData && checkoutData.availableShippingMethods,
        checkoutData && checkoutData.store && checkoutData.store.stockLocation,
    ]);

    return {
        loading,
        data: deliveryOptions,
        stockLocation: get_(checkoutData, ['store', 'stockLocation']),
        storeHours: storeHours,
    };
}
