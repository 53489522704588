import { get_ } from 'sf-modules';
import { APP_STATE_TYPES } from '../CommerceProvider';

export default function unavailableProductsReducer(pipeCartState, state, action) {
    switch (action.type) {
        case APP_STATE_TYPES.CART_SET_UNAVAILABLE_PRODUCTS:
            return action.products;

        case APP_STATE_TYPES.CART_COPY_TO_UNAVAILABLE_PRODUCTS:
            let enqueuedProducts = [...state];
            let alreadyEnqueuedIndex = enqueuedProducts.findIndex((p) => p.rawId === action.rawId);
            let unavailableProduct = pipeCartState.find((p) => p.rawId === action.rawId);

            if (!unavailableProduct?.product) return enqueuedProducts;

            let unavailableQty = unavailableProduct.previousQuantity
                ? unavailableProduct.quantity - unavailableProduct.previousQuantity
                : unavailableProduct.quantity;
            let dataToEnqueue = {
                ...unavailableProduct.product,
                customerQuantity: unavailableQty,
                quantity: unavailableQty,
            };

            if (alreadyEnqueuedIndex > -1) {
                enqueuedProducts[alreadyEnqueuedIndex] = dataToEnqueue;
                return enqueuedProducts;
            }

            return [dataToEnqueue, ...enqueuedProducts];

        case APP_STATE_TYPES.CART_EMPTY_UNAVAILABLE_PRODUCTS:
            return [];

        default:
            return state;
    }
}
