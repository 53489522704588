import { get_, isNumber } from '../tools';

/**
 * Format money with currency
 * @param amount
 * @param keys
 * @param freeLabel
 * @returns {string|*}
 */
export function formatMoney(amount, keys = null, freeLabel = null) {
    let parsedAmount = typeof amount === 'string' ? parseFloat(amount) : amount;

    if (!isNumber(parsedAmount) && !keys) return '';

    if (keys) {
        let retrieveAmount = get_(parsedAmount, keys, 0);
        return isNumber(retrieveAmount) ? formatMoney(retrieveAmount, null, freeLabel) : '';
    }

    if (parsedAmount === 0 && freeLabel) return freeLabel;

    return 'MXN ' + Number(parsedAmount).toFixed(2);
}

/**
 * Calculate discount in % between given prices
 * @param price
 * @param priceUndiscounted
 * @returns {string|null}
 */
export function getDiscount(price, priceUndiscounted) {
    const formatDiscount = (value) => (value > 0 ? `-${value}%` : null);

    if (!price || !priceUndiscounted) return null;

    if (priceUndiscounted > price)
        return formatDiscount((100 - (100 * price) / priceUndiscounted).toFixed(0));

    return null;
}

export function parseUndiscountedPrice(price, priceUndiscounted, nullIfSame = true) {
    if (price !== null && priceUndiscounted !== null && priceUndiscounted > price)
        return formatMoney(priceUndiscounted);

    if (!nullIfSame && price === priceUndiscounted) return formatMoney(priceUndiscounted);

    return null;
}

export const isMoneyValue = (val) => val && val !== '' && val !== 'MXN 0.00' && val !== 'MXN -0.00';

export default {
    formatMoney,
    getDiscount,
    parseUndiscountedPrice,
    isMoneyValue,
};
