import { cookie } from 'sf-modules';
import { CHECKOUT_UPDATE, CHECKOUT_RESTORE, CHECKOUT_RESET } from '../types';

const initialState = {
    checkoutID: false,
};

export default function cartReducer(state = initialState, action) {
    switch (action.type) {
        case CHECKOUT_RESET:
            return initialState;

        case CHECKOUT_RESTORE:
            return {
                checkoutID: action.payload.checkoutID
                    ? action.payload.checkoutID
                    : initialState.checkoutID,
            };

        case CHECKOUT_UPDATE:
            cookie.set(
                process.env.NEXT_PUBLIC_COOKIE_CHECKOUT_ID,
                action.payload.checkoutID,
                30,
                process.env.NEXT_PUBLIC_DEPLOY_ENV !== 'local'
            );
            return { checkoutID: action.payload.checkoutID };

        default:
            return state;
    }
}
