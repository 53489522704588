import { gql } from '@apollo/client';

const STORE_QUERY = gql`
    query Store($zipCode: String) {
        store(zipCode: $zipCode) {
            id
            active
            name
            algoliaIndex
        }
    }
`;

export default STORE_QUERY;
