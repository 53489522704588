import { useMutation } from '@apollo/client';
import { get_ } from 'sf-modules';
import { fnDoMutation } from '../../utils/fnDoMutation';
import useCheckout from './useCheckout';
import useCheckoutUpdate from './useCheckoutUpdate';
import CHECKOUT_SHIPPING_ADDRESS_REMOVE_MUTATION from '../../graphql/mutations/checkout/checkoutShippingAddressRemove.gql';

export default function useRemoveShippingAddress() {
    const { checkoutBase64Id } = useCheckout();
    const checkoutUpdate = useCheckoutUpdate();
    const [mutationRemoveShippingAddress] = useMutation(CHECKOUT_SHIPPING_ADDRESS_REMOVE_MUTATION);

    async function removeShippingAddress(checkoutId = null) {
        let checkoutIdToUpdate = checkoutId || checkoutBase64Id;
        if (checkoutIdToUpdate) {
            const response = await fnDoMutation(mutationRemoveShippingAddress, {
                checkout: checkoutIdToUpdate,
            });

            if (typeof checkoutUpdate === 'function')
                checkoutUpdate({
                    newCheckoutData: get_(response, ['checkoutShippingAddressRemove', 'checkout']),
                });
        }
    }

    return removeShippingAddress;
}
