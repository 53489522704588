import { APP_STATE_TYPES } from '../CommerceProvider';

export default function modalReducer(state, action) {
    switch (action.type) {
        case APP_STATE_TYPES.TOGGLE_MODAL:
            if (action.open || (!action.open && state.kind === action.kind))
                return {
                    ...state,
                    kind: action.kind,
                    opened: action.open,
                    props: action.props,
                };
            return state;

        case APP_STATE_TYPES.CLOSE_MODAL:
            return {
                ...state,
                kind: '',
                opened: false,
                props: null,
            };

        default:
            return state;
    }
}
