// @ts-nocheck

import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { get_, dataTransformers } from 'sf-modules';
import { CartProblemIcon } from 'icons/solid';
import { APP_STATE_TYPES, CommerceContext, MODAL_KIND } from '../../CommerceProvider';
import { trackError } from '../../utils/error';
import { fnDoMutation } from '../../utils/fnDoMutation';
import useStateEngine from '../../useStateEngine';
import useStore from '../store/useStore';
import useCheckout from './useCheckout';
import useCheckoutUpdate from './useCheckoutUpdate';
import useSetCheckoutEmail from './useSetCheckoutEmail';
import useRemoveShippingAddress from './useRemoveShippingAddress';
import CHECKOUT_SHIPPING_ADDRESS_UPDATE_MUTATION from '../../graphql/mutations/checkout/checkoutShippingAddressUpdate.gql';
import STORES_BY_ZIPCODE_QUERY from '../../graphql/queries/store/stores-by-zipcode.gql';

export default function useSetShippingAddress() {
    const { apolloClient, commerceConfig, checkout: checkoutData } = useContext(CommerceContext);
    const [{ catalog }, dispatchStateEngine] = useStateEngine();

    const { setZipCodeAndUpdateStore } = useStore();
    const { checkoutBase64Id } = useCheckout();
    const { setCheckoutEmail } = useSetCheckoutEmail();
    const checkoutUpdate = useCheckoutUpdate();
    const removeShippingAddress = useRemoveShippingAddress();

    const [loading, setLoading] = useState(false);

    const [mutationUpdateShippingAddress] = useMutation(CHECKOUT_SHIPPING_ADDRESS_UPDATE_MUTATION);

    async function doSetShippingAddress(
        address?: any,
        addressId?: string,
        addressFormState?: any,
        forceUpdateCheckout?: boolean
    ) {
        let storeAlreadySet = false;
        // On POS check if store is not already assigned to checkout
        if (commerceConfig.context === 'pos') {
            const storesForThisZipCode = await apolloClient.query({
                query: STORES_BY_ZIPCODE_QUERY,
                variables: { zipCode: get_(address, ['postalCode']) },
            });
            let checkoutStoreId = get_(checkoutData, ['store', 'id']);
            storeAlreadySet = get_(storesForThisZipCode, ['data', 'storesByZipCode'], []).find(
                (i) => i.id === checkoutStoreId
            );

            if (!storeAlreadySet) {
                let zipcode = get_(address, ['postalCode']);
                let store = get_(checkoutData, ['store', 'name']);
                dispatchStateEngine({
                    type: APP_STATE_TYPES.TOGGLE_MODAL,
                    kind: MODAL_KIND.ALERT,
                    open: true,
                    props: {
                        modalTitleIcon: (
                            <CartProblemIcon className='text-brand-tertiary-700 mx-auto block w-20' />
                        ),
                        modalTitle:
                            'Este código postal no está disponible para la tienda seleccionada',
                        modalTextNode: (
                            <>
                                <p className='mb-0 text-center'>
                                    El código postal indicado ({zipcode}) no está disponible para la
                                    tienda seleccionada:
                                    <br />
                                    Punto de venta seleccionado: {catalog?.pos?.name}
                                    <br />
                                    Tienda seleccionada: {store}
                                </p>
                                <p
                                    className='text-small text-ui-error hover:text-ui-alert mt-6 cursor-pointer text-center underline'
                                    onClick={() => {
                                        dispatchStateEngine({
                                            type: APP_STATE_TYPES.TOGGLE_MODAL,
                                            kind: MODAL_KIND.REQUIRE_SUPPORT,
                                            open: true,
                                            props: {
                                                message: `Quiero poder enviar un pedido al código postal ${zipcode} desde la tienda ${store}`,
                                            },
                                        });
                                    }}
                                >
                                    Reportar un error via Slack
                                </p>
                            </>
                        ),
                        confirmText: 'Entendido',
                    },
                });
                return false;
            }
        }

        // Assign store to our checkout, given the zipCode
        if (!storeAlreadySet) {
            await setZipCodeAndUpdateStore({
                catalog,
                dispatch: dispatchStateEngine,
                zipCode: get_(address, ['postalCode']),
                addressId: get_(address, ['addressCopy']),
                checkoutBase64Id,
            });
        }

        // ...then set new shipping address
        const addressInputs = addressId ? { shippingAddressId: addressId } : { address: address };
        const addressUpdateResponse = await fnDoMutation(mutationUpdateShippingAddress, {
            checkout: checkoutBase64Id,
            ...addressInputs,
        });

        const errors = get_(
            addressUpdateResponse,
            ['data', 'checkoutShippingAddressUpdate', 'errors'],
            []
        );
        const availableShippingMethods = get_(
            addressUpdateResponse,
            ['data', 'checkoutShippingAddressUpdate', 'checkout', 'availableShippingMethods'],
            []
        );

        // Error or no shipping method returned
        if (errors.length > 0 || !availableShippingMethods.length) {
            // Set errors in address form state
            if (addressFormState) {
                errors.map((err) => {
                    if (!err || !err.field) return;
                    let field = addressFormState.values[err.field] ? err.field : 'formError';
                    // postalCode errors are shown as main error
                    if (field === 'postalCode') field = 'postalCodeWithoutDelivery';
                    addressFormState.setFieldError(field, err.message);
                });
            }

            // No shipping method returned
            if (!errors.length && !availableShippingMethods.length) {
                trackError({
                    label: 'No shipping method returned',
                    extras: {
                        addressInput: address,
                        addressUpdateResponse: addressUpdateResponse,
                    },
                });
                addressFormState &&
                    addressFormState.setFieldError(
                        'postalCodeWithoutDelivery',
                        'No entregamos a esa dirección.'
                    );
            }

            await removeShippingAddress();
            setLoading(false);
            return false;
        } else {
            if (forceUpdateCheckout)
                checkoutUpdate({
                    newCheckoutData: get_(addressUpdateResponse, [
                        'data',
                        'checkoutShippingAddressUpdate',
                        'checkout',
                    ]),
                    updateLines: false,
                });

            return true;
        }
    }

    async function setShippingAddress({
        address,
        addressFormState = null,
        forceUpdateCheckout = false,
    }: {
        address: any;
        addressFormState?: any;
        forceUpdateCheckout?: boolean;
    }) {
        setLoading(true);

        const addressMapped = {
            ...dataTransformers.transformAddress(address),
            addressCopy: address.id,
        };

        delete addressMapped.id;
        delete addressMapped.__typename;
        delete addressMapped.isDefaultShippingAddress;
        delete addressMapped.isDefaultBillingAddress;

        if (get_(address, ['email']))
            await setCheckoutEmail({
                email: address.email,
                forceUpdateCheckout: false,
            });

        return await doSetShippingAddress(
            addressMapped,
            null,
            addressFormState,
            forceUpdateCheckout
        );
    }

    async function setShippingAddressById(
        address: any,
        addressId: string,
        forceUpdateCheckout?: boolean
    ) {
        setLoading(true);
        return await doSetShippingAddress(
            {
                id: addressId,
                addressCopy: addressId,
                postalCode: address?.postalCode, // required to set store if not already set
            },
            addressId,
            null,
            forceUpdateCheckout
        );
    }

    return {
        loading,
        setShippingAddress,
        setShippingAddressById,
    };
}
