import { get_ } from '../tools';

/**
 * Evaluate rule built with durable_rule on PIM side
 */
export default class RuleSet {
    constructor(rule) {
        this.rule = rule
            ? {
                  applied: rule.applied,
                  ...get_(rule, ['rule']),
              }
            : null;
    }

    get() {
        return this.rule;
    }

    getItem(slug, type) {
        return get_(this, ['rule', slug], []).find((x) => x.type === type);
    }

    getBenefit(type) {
        return this.getItem('benefits', type);
    }

    getCondition(type) {
        return this.getItem('conditions', type);
    }

    getConditionsLabels() {
        const getTypeLabel = (type) => {
            switch (type) {
                case 'SHIPPING_METHOD':
                    return 'método de envío';
                case 'PAYMENT_METHOD':
                    return 'método de pago';
                default:
                    return;
            }
        };

        return get_(this, ['rule', 'conditions'], [])
            .map((x) => getTypeLabel(x.type))
            .join(', ');
    }

    evaluateAchievementOnCondition(achievement, condition) {
        if (!achievement || !condition || !condition.type) return null;

        switch (condition.type) {
            case 'AMOUNT_SPENT': {
                let parsedGoalAmount = parseFloat(get_(condition, ['objects', 0], 0));
                let missingAmount =
                    achievement >= parsedGoalAmount ? 0 : parsedGoalAmount - achievement;
                // let parseGoalOperator = condition.operator; // operator information from PIM is buggy for the moment (got __4 instead of >=);
                return {
                    achieved: achievement >= parsedGoalAmount,
                    goalAmount: parsedGoalAmount,
                    missingAmount: missingAmount,
                    missingPercent: ((parsedGoalAmount - missingAmount) * 100) / parsedGoalAmount,
                };
            }

            default:
                return null;
        }
    }
}
