import { get_ } from '../tools';
import time from '../time';
import { formatMoney, parseUndiscountedPrice } from '../money';
import transformCheckoutLineFromPIM from './checkoutLine';
import { buildFormattedAddress } from './address';

const transformOrder = (data) => {
    if (!data || !data.orderPublicId || !data.orderPublicId.publicId) return null;

    const order = data.orderPublicId;

    /** LINES */
    let lines = [];
    let giftsLines = [];
    get_(order, ['lines'], []).map((l) => {
        const transformedLine = transformCheckoutLineFromPIM(l, order);
        const quantity = get_(transformedLine, ['quantity'], 0);
        const giftQuantity = get_(transformedLine, ['productsGifted'], 0);
        const billedQuantity = get_(
            l,
            ['billedQuantity'],
            quantity - giftQuantity - get_(transformedLine, ['productsDiscounted'], 0) // If billedQuantity does not exists
        );
        const totalPriceUndiscounted = billedQuantity * l.grossUnitPaidPrice;

        const paidPricing = l.isGift
            ? null
            : {
                  formattedPrice: formatMoney(l.grossUnitPaidPrice),
                  price: l.grossUnitPaidPrice,
                  formattedPriceUndiscounted: parseUndiscountedPrice(
                      l.grossUnitPaidPrice,
                      l.grossUnitPrice.amount
                  ),
                  priceUndiscounted: l.grossUnitPrice.amount,

                  discount: l.hasDiscount ? `-${l.percentageDiscount}%` : null,

                  totalPrice: l.totalPaidPrice,
                  formattedTotalPrice: formatMoney(l.totalPaidPrice),
                  formattedTotalPriceUndiscounted: formatMoney(totalPriceUndiscounted),
              };

        // Product
        if (quantity !== giftQuantity) {
            lines.push({
                ...transformedLine,
                hasDiscount: l.hasDiscount,
                paidPricing: paidPricing,
                billedQuantity: billedQuantity,
            });
        }

        // Gift
        if (giftQuantity > 0) {
            giftsLines.push({
                ...transformedLine,
                appliedBenefits: [],
                isGift: true,
            });
        }
    });

    /** SIPPING INFOS */
    let shippingDate;
    if (
        get_(order, ['shippingMethod', 'supportsScheduledDelivery'], false) === true &&
        order.deliveryEnd
    )
        shippingDate = time.getDateMoment(order.deliveryEnd);
    else shippingDate = get_(order, ['shippingMethod', 'name'], '');

    let shippingDateTime = '';
    if (
        get_(order, ['shippingMethod', 'supportsScheduledDelivery'], false) === true &&
        order.deliveryEnd
    ) {
        const start = time.getScheduleFormattedTime(order.deliveryStart);
        const end = time.getScheduleFormattedTime(order.deliveryEnd);
        shippingDateTime = `${start} - ${end}`;
    }

    /** ORDER OBJECT */
    return {
        ...order,
        publicOrderId: order.publicId,
        createdFormatted: time.getFormattedDate(order.created),
        tax: 'Incluídos',
        merchandiseAmount: order.subtotal,
        subTotalFormatted: formatMoney(get_(order, ['subtotal'])),
        totalFormatted: formatMoney(get_(order, ['total'])),
        totalPrice: get_(order, ['total']),
        lines: [...lines, ...giftsLines],
        discountValue: {
            voucher: {
                voucherCode: order.voucherCode ? order.voucherCode : '',
                voucherName: order.voucherName ? order.voucherName : '',
                voucherAmount: order.voucherDiscount,
            },
            errors: [],
        },
        shippingFormattedAddress: buildFormattedAddress(order.shippingAddress),
        shippingDate,
        shippingDateTime,
        shippingDiscount: parseFloat(order.shippingDiscount),
        fullName:
            get_(order, ['shippingAddress', 'firstName'], '') +
            ' ' +
            get_(order, ['shippingAddress', 'lastName'], ''),
        payments: get_(order, ['payments'], []),
        pos: get_(data, ['pos']),
        ruleDiscounts: order.ruleDiscounts?.map((x) => ({
            ...x,
            amount: parseFloat(x.amount),
        })),
    };
};

export default transformOrder;
