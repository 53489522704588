/**
 * This striginfy function allows us to avoid the cyclic object value error
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
 */

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        // eslint-disable-next-line consistent-return
        return value;
    };
};

export default function stringify(obj) {
    try {
        return JSON.stringify(obj);
    } catch (e) {
        return JSON.stringify(obj, getCircularReplacer());
    }
}
