export const PRODUCT_GIFT = {
    availability: {
        availableOnZipCode: true,
        checkoutMaxQuantity: 3,
        itemsAvailable: 1663,
        status: 'available',
    },
    badgePromotion: null,
    billedQuantity: 0,
    brand: 'YEMA & CO',
    gtin: '7503027879756',
    id: 'UHJvZHVjdDo0NjE=',
    isAvailable: true,
    isBundle: false,
    isGift: true,
    images: {
        hoverUrl: '',
        mainAlt: '',
        mainUrl:
            'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/bocadillo-datil-frambuesa-1-1_c0c819fd-1cd5-4abe-9086-9defe9d6fb10',
        items: [
            {
                alt: '',
                url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/bocadillo-datil-frambuesa-1-1_c0c819fd-1cd5-4abe-9086-9defe9d6fb10',
            },
            {
                alt: '',
                url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/bocadillo-datil-frambuesa-2-1_f23fad58-33c2-46e3-a90a-e17c22abf2b1',
            },
            {
                alt: '',
                url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/bocadillo-frambuesa_7a685d5e-e092-4372-96c1-050359100bfd',
            },
        ],
    },
    name: 'Agua Micelar con Caléndula',
    parsedUnitPricingBaseMeasure: null,
    pricing: {
        discount: null,
        formattedPrice: 'MXN 0.00',
        formattedPriceUndiscounted: null,
        price: 0,
        priceUndiscounted: 0,
    },
    productsDiscounted: 0,
    productsGifted: 1,
    quantity: 1,
    quantityDisplay: '1',
    seoDescription: '',
    seoTitle: '',
    sku: '7503027879756',
    slug: 'agua-micelar-con-calendula',
    stockUnit: null,
};

export default PRODUCT_GIFT;
