import { gql } from '@apollo/client';

const LINK_USER_TRACKING_MUTATION = gql`
    mutation LinkCustomerTracking($integrationsData: GenericScalar, $trackingId: String!) {
        linkCustomerTracking(integrationsData: $integrationsData, trackingId: $trackingId) {
            trackingId
            errors {
                field
                message
            }
        }
    }
`;

export default LINK_USER_TRACKING_MUTATION;
