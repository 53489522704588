import { gql } from '@apollo/client';
import { CheckoutFieldsFragment } from '../../fragments/checkout/CheckoutFields.gql';

const CHECKOUT_CUSTOMER_DETACH_MUTATION = gql`
    mutation CheckoutCustomerDetach($checkoutId: ID!) {
        checkoutCustomerDetach(checkoutId: $checkoutId) {
            checkout {
                ...CheckoutFields
            }
            errors {
                message
                field
            }
        }
    }
    ${CheckoutFieldsFragment}
`;

const CHECKOUT_CUSTOMER_DETACH_AND_RESET_EMAIL_MUTATION = gql`
    mutation CheckoutCustomerDetach($checkoutId: ID!, $email: String!) {
        checkoutCustomerDetach(checkoutId: $checkoutId) {
            checkout {
                ...CheckoutFields
            }
            errors {
                message
                field
            }
        }
        checkoutEmailUpdate(checkoutId: $checkoutId, email: $email) {
            checkout {
                ...CheckoutFields
            }
            errors {
                message
                field
            }
        }
    }
    ${CheckoutFieldsFragment}
`;

export { CHECKOUT_CUSTOMER_DETACH_MUTATION, CHECKOUT_CUSTOMER_DETACH_AND_RESET_EMAIL_MUTATION };
