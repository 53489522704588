import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { CheckoutUserFieldsFragment } from '../../fragments/checkout/CheckoutUserFields.gql';
import { CheckoutPricingFieldsFragment } from '../../fragments/checkout/CheckoutPricingFields.gql';
import { CheckoutExtendedFieldsFragment } from '../../fragments/checkout/CheckoutExtendedFields.gql';
import { LinesForAnalyticsFieldsFragment } from '../../fragments/checkout/LinesForAnalyticsFields.gql';
import { LinesBasicFieldsFragment } from '../../fragments/checkout/LinesBasicFields.gql';
import { LinesExtendedFieldsFragment } from '../../fragments/checkout/LinesExtendedFields.gql';

const CHECKOUT_ERASE_OUT_OF_STOCK_MUTATION = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutUserFieldsFragment}
    ${CheckoutPricingFieldsFragment}
    ${CheckoutExtendedFieldsFragment}
    ${LinesForAnalyticsFieldsFragment}
    ${LinesBasicFieldsFragment}
    ${LinesExtendedFieldsFragment}
    mutation CheckoutEraseOutOfStock($checkout: ID!) {
        checkoutEraseOutOfStock(checkoutId: $checkout) {
            checkout {
                ...CheckoutBasicFields
                ...CheckoutUserFields
                ...CheckoutPricingFields
                ...CheckoutExtendedFields
                lines {
                    ...LinesBasicFields
                    ...LinesExtendedFields
                    ...LinesForAnalyticsFields
                }
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_ERASE_OUT_OF_STOCK_MUTATION;
