export const PATH_HOME = '/';

export const PATH_ACCOUNT = '/cuenta';
export const PATH_LOGIN = '/cuenta/login';
export const PATH_RESET_PASSWORD = '/cuenta/password/restaurar';

export const PATH_CART = '/carrito';
export const PATH_PAYMENT = '/carrito/pago';

export const PATH_PRODUCT_PAGE = '/p';
export const PATH_KIT_PAGE = '/k';
export const PATH_CATEGORY = '/c';
export const PATH_SEARCH = '/s';
export const PATH_PRIVACY = '/sc/privacidad';
export const PATH_TERMS = '/sc/terminos-y-condiciones';
export const PATH_REFERRALS = '/cl/programa-de-referidos';

export const PATH_ACCOUNT_ADDRESSES = '/cuenta/mis-direcciones';
export const PATH_ACCOUNT_INVOICES = '/cuenta/mis-datos-de-facturacion';

export const YEMA_FACEBOOK = 'https://www.facebook.com/yema.mx/';
export const YEMA_INSTAGRAM = 'https://www.instagram.com/yema.mx/';
export const YEMA_TWITTER = 'https://twitter.com/yemamx';
export const YEMA_WHATSAPP = 'https://wa.me/525584218422';
