import * as PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import styles from './LoaderLogo.module.scss';

export default function LoaderLogo({
    className,
    active,
    position,
    withOverlay,
    overlayColor,
    description,
    descriptionLoopTimer,
    small,
}) {
    const [descriptionId, setDescriptionId] = useState(0);

    useEffect(() => {
        let timer =
            Array.isArray(description) &&
            setInterval(() => {
                setDescriptionId((val) => (val + 1) % description.length);
            }, descriptionLoopTimer);
        return () => clearInterval(timer);
    }, [description, descriptionLoopTimer]);

    if (!active) return <></>;

    const overlayClasses = cx(className, 'flex flex-col justify-center', {
        // Fixed and absolute
        [styles.overlay]: withOverlay && position !== 'relative', // Overlay when is not relative
        [`${position} items-center top-0 bottom-0 right-0 left-0 h-full w-full`]:
            position !== 'relative',

        // Relative
        'relative flex items-center justify-center': position === 'relative',
    });

    const blobStyles = cx(
        'absolute bg-white/25 border border-ui-gray-900/75',
        'flex items-center justify-center font-heading font-thin text-ui-gray-900/75',
        small ? 'w-[25px] h-[25px] text-caption' : 'w-[50px] h-[50px] text-h2'
    );

    return (
        <div
            className={overlayClasses}
            style={{ backgroundColor: withOverlay ? overlayColor : '' }}
        >
            <div className={cx('relative', small ? 'h-[52px] w-[52px]' : 'h-[105px] w-[105px]')}>
                <span className={cx(styles.animateBlob1, blobStyles, 'top-0 left-0 rounded-full')}>
                    Y
                </span>
                <span className={cx(styles.animateBlob2, blobStyles, 'top-0 left-1/2 rounded-md')}>
                    E
                </span>
                <span className={cx(styles.animateBlob3, blobStyles, 'rounded-md')}>A</span>
                <span className={cx(styles.animateBlob4, blobStyles, 'rounded-md')}>M</span>
            </div>
            {description && (
                <>
                    <div className='mt-2 font-body text-small font-body-regular text-ui-black'>
                        {Array.isArray(description) ? description[descriptionId] : description}
                    </div>
                </>
            )}
        </div>
    );
}

LoaderLogo.defaultProps = {
    active: false,
    withOverlay: false,
    descriptionLoopTimer: 3000,
    small: false,
};

LoaderLogo.propTypes = {
    className: PropTypes.string,
    active: PropTypes.bool,
    position: PropTypes.oneOf(['fixed', 'absolute', 'relative']),
    withOverlay: PropTypes.bool,
    overlayColor: PropTypes.string,
    /** Provide a description for the loading state */
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    /** Interval timer for descriptions */
    descriptionLoopTimer: PropTypes.number,
    /** Size of loader */
    small: PropTypes.bool,
};
