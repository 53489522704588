import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es-mx';

dayjs.extend(relativeTime);
dayjs.extend(isToday);

function getFormattedDate(date) {
    return new Intl.DateTimeFormat('es-MX', {
        month: 'short',
        weekday: 'short',
        day: '2-digit',
        year: 'numeric',
    }).format(new Date(date));
}

/**
 * Round date to next given minutes
 * @param minutes
 * @param d
 * @returns {Date}
 */
function getRoundedDate(minutes, d = new Date()) {
    const ms = 1000 * 60 * minutes;
    return new Date(Math.ceil(d.getTime() / ms) * ms);
}

function getScheduleFormattedTime(date) {
    let d = date;
    if (typeof date === 'string') d = dayjs(date);

    if (!d || !d.isValid()) return '';

    return dayjs(date).locale('es-mx').format('h:mm A');
}

function getDateMoment(date) {
    const d = typeof date === 'string' ? dayjs(date) : dayjs();
    return d.isToday()
        ? `Hoy ${d.locale('es-mx').format('D MMM')}`
        : d.locale('es-mx').format('dddd D MMM');
}

function getPublishedDate(date) {
    if (!date) return '';

    const d = dayjs(date);
    if (d.isToday()) return `hoy, ${d.format('D MMM')}`;
    else return `el ${d.locale('es-mx').format('dddd D MMM YYYY')}`;
}

function getCommentDate(date) {
    if (!date) return '';
    return dayjs(date).locale('es-mx').fromNow();
}

function setTimeHour(date, hours, minutes, seconds) {
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}

function getDifferenceInMinutes(dateUTC1, dateUTC2 = null) {
    if (!dateUTC1) return null;
    let d1 = dayjs(dateUTC1);
    let d2 = dateUTC2 ? dayjs(dateUTC2) : dayjs();
    return d2.diff(d1, 'minute');
}

function getIsToday(date) {
    date = typeof date === 'string' ? dayjs(date) : dayjs();
    return date.isToday();
}

export default {
    getFormattedDate,
    getRoundedDate,
    getScheduleFormattedTime,
    getDateMoment,
    getPublishedDate,
    getCommentDate,
    setTimeHour,
    getDifferenceInMinutes,
    getIsToday,
};
