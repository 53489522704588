import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';

const ModalBody = ({ className, children, style, ...otherProps }) => {
    const baseClass = cx(className, 'block px-4 py-2 sm:py-6 sm:px-6');

    return (
        <div className={baseClass} {...otherProps} style={style}>
            {children}
        </div>
    );
};

ModalBody.displayName = 'ModalBody';

ModalBody.defaultProps = {};

ModalBody.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default ModalBody;
