import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { CheckoutUserFieldsFragment } from '../../fragments/checkout/CheckoutUserFields.gql';
import { CheckoutPricingFieldsFragment } from '../../fragments/checkout/CheckoutPricingFields.gql';
import { CheckoutExtendedFieldsFragment } from '../../fragments/checkout/CheckoutExtendedFields.gql';
import { LinesForAnalyticsFieldsFragment } from '../../fragments/checkout/LinesForAnalyticsFields.gql';
import { LinesBasicFieldsFragment } from '../../fragments/checkout/LinesBasicFields.gql';

const CHECKOUT_SHIPPING_METHOD_UPDATE_MUTATION = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutUserFieldsFragment}
    ${CheckoutPricingFieldsFragment}
    ${CheckoutExtendedFieldsFragment}
    ${LinesForAnalyticsFieldsFragment}
    ${LinesBasicFieldsFragment}
    mutation Checkout($checkout: ID!, $shippingMethod: ID!) {
        checkoutShippingMethodUpdate(checkoutId: $checkout, shippingMethodId: $shippingMethod) {
            checkout {
                ...CheckoutBasicFields
                ...CheckoutUserFields
                ...CheckoutPricingFields
                ...CheckoutExtendedFields
                lines {
                    ...LinesBasicFields
                    ...LinesForAnalyticsFields
                }
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_SHIPPING_METHOD_UPDATE_MUTATION;
