import { gql } from '@apollo/client';

export const LinesBasicFieldsFragment = gql`
    fragment LinesBasicFields on CheckoutLine {
        id
        sku
        rawId
        plmId
        quantity
        quantityDisplay
        customerUnit
        customerQuantity
        customerQuantityDisplay
        checkoutMaxQuantity
        isBundle
        isGift
        productAvailability
        itemsAvailable
        billedQuantity
        productsGifted
        productsDiscounted
    }
`;
