import { gql } from '@apollo/client';

import { AddressFieldsFragment } from '../../fragments/generic/AddressFields.gql';

const ADDRESS_LIST_QUERY = gql`
    query ShippingAddressesList {
        me {
            id
            addresses {
                ...AddressFields
            }
            defaultShippingAddress {
                id
                isDefaultShippingAddress
            }
        }
    }

    ${AddressFieldsFragment}
`;

export default ADDRESS_LIST_QUERY;
