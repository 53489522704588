import { ReactChild, ReactChildren, createContext, useState, useEffect, useContext } from 'react';
import { useDetectAdBlock } from 'adblock-detect-react';
import { get_, sessionstorage, localstorage, cookie } from 'sf-modules';
import { CommerceContext } from '../../CommerceProvider';
import { fnDoMutation } from '../../utils/fnDoMutation';
import { checkout_update } from '../../store/actions/cartActions';
import { useMutation } from '../../APIClient';
import { useDispatch, useSelector } from '../../StoreClient';
import useStateEngine from '../../useStateEngine';
import useTracker from './useTracker';
// import useAffiliation from "./useAffiliation";
import WithIntercom from './WithIntercom';
import CHECKOUT_UPDATE_OR_CREATE_MUTATION from '../../graphql/mutations/checkout/checkoutUpdateOrCreate.gql';
import LINK_USER_TRACKING_MUTATION from '../../graphql/mutations/user/linkUserTracking.gql';

/**
 * KEY_USR_CTX:
 *  - Session storage values: ongoing / done
 *  - Local storage values: { context: obj }
 * */
const KEY_USR_CTX = 'y_usr_ctx';

interface EventFunction extends Function {
    eventType?: string | string[];
}

const waitForAnalytics = (key, callback) => {
    if (window[key]) {
        callback();
    } else {
        setTimeout(function () {
            waitForAnalytics(key, callback);
        }, 100);
    }
};

// Dynamically set event listeners declared in eventListeners folder
async function getListeners(): Promise<Function[]> {
    const listeners = await import('./eventListeners');
    return listeners?.default?.filter((listener) => typeof listener === 'function') || [];
}

interface TrackerContextInterface {
    listeners: EventFunction[];
    trackingHistory: any[];
    setTrackingHistory: Function;
    isInitializing: boolean;
    initializeCheckout: Function;
}

export const TrackerContext = createContext<TrackerContextInterface>({
    listeners: [],
    trackingHistory: [],
    setTrackingHistory: undefined,
    isInitializing: false,
    initializeCheckout: undefined,
});

export default function TrackerProvider({ children }: { children: ReactChildren | ReactChild }) {
    const dispatch = useDispatch();
    const auth = useSelector(({ authentication }) => authentication);
    const storeIsInitialized = useSelector(({ storeState }) => storeState.isInitialized);

    const { commerceConfig } = useContext(CommerceContext);
    const [{ catalog }, _] = useStateEngine();
    const { getUserTrackingIds } = useTracker();

    const adBlockDetected = useDetectAdBlock();

    const [mutationInitializeEmptyCheckout] = useMutation(CHECKOUT_UPDATE_OR_CREATE_MUTATION);
    const [mutationLinkUserTracking] = useMutation(LINK_USER_TRACKING_MUTATION);

    const [isInitializing, setIsInitializing] = useState(true);
    const [trackingIsReady, setTrackingIsReady] = useState(false);

    const [listeners, setListeners] = useState([]);
    const [trackingHistory, setTrackingHistory] = useState([]);

    // Disabled until SF-1669 is resolved.
    // useAffiliation({ isReady: trackingIsReady });

    async function initEmptyCart() {
        return fnDoMutation(mutationInitializeEmptyCheckout, {
            token: '',
            lines: [],
            email: auth && auth.email !== '' ? auth.email : commerceConfig.values.DEFAULT_EMAIL,
            zipCode: catalog && catalog.zipCode ? catalog.zipCode : null,
        }).then((res) => {
            let checkoutId = get_(res, ['data', 'checkoutUpdateOrCreate', 'checkout', 'id']);
            if (checkoutId) dispatch(checkout_update(checkoutId));
            return checkoutId;
        });
    }

    /**
     * Initialize checkout & forward user context information to backend
     * @param force
     */
    async function init(force: boolean = false) {
        // Get localstorage & sessionstorage values
        let userCtxLS = localstorage.get(KEY_USR_CTX);
        let userCtxStatusSS = sessionstorage.get(KEY_USR_CTX);

        // User context has already been forwarded to backend
        if (
            !force &&
            ((userCtxStatusSS && userCtxStatusSS === 'done') || userCtxStatusSS === 'ongoing')
        ) {
            return;
        }

        // Set user context
        setIsInitializing(true);

        // Init checkout
        cookie.get(process.env.NEXT_PUBLIC_COOKIE_CHECKOUT_ID) || (await initEmptyCart());

        // Fetch & forward user context & tracking ids
        // @TODO Ludo: use timestamp to invalidate if more than 1 week
        sessionstorage.set(KEY_USR_CTX, 'ongoing');

        let userTrackingIds = await getUserTrackingIds({
            adBlockDetected,
            savedContext: userCtxLS?.ctx || null,
        });

        await fnDoMutation(mutationLinkUserTracking, { ...userTrackingIds });

        sessionstorage.set(KEY_USR_CTX, 'done');
        localstorage.set(KEY_USR_CTX, {
            ctx: get_(userTrackingIds, ['integrationsData', 'Context']),
        });

        setIsInitializing(false);
    }

    /** Detect adblocker */
    useEffect(() => {
        if (commerceConfig.context === 'pos') return;

        // Ad blocker is detected, we consider tracking tools are ready
        if (storeIsInitialized && adBlockDetected) setTrackingIsReady(true);
    }, [storeIsInitialized, adBlockDetected]);

    /** Wait for analytics to be ready */
    useEffect(() => {
        if (commerceConfig.context === 'pos') return;

        if (storeIsInitialized) {
            waitForAnalytics('analytics', function () {
                global.analytics.ready(() => setTrackingIsReady(true));
            });
        }
    }, [storeIsInitialized]);

    /** Initialize checkout & forward user context to backend */
    useEffect(() => {
        if (commerceConfig.context === 'pos') return;

        if (trackingIsReady) {
            init();
        }
    }, [trackingIsReady]);

    /** Initialize event listeners */
    useEffect(() => {
        // Fetch & set event listeners
        getListeners().then((l) => setListeners(l));
    }, []);

    return (
        <WithIntercom>
            <TrackerContext.Provider
                value={{
                    listeners,
                    trackingHistory,
                    setTrackingHistory,
                    isInitializing,
                    initializeCheckout: init,
                }}
            >
                {children}
            </TrackerContext.Provider>
        </WithIntercom>
    );
}
