import { gql } from '@apollo/client';

export const LinesAppliedBenefitsFieldsFragment = gql`
    fragment LinesAppliedBenefitsFields on CheckoutLine {
        appliedBenefits {
            id
            title
            evaluationType
            discountString
            objects
            includeAll
            itemQuantity
            type
        }
    }
`;
