import sessionstorage from '../sessionstorage/';
import { get_, sortAlphabeticallyObjectByKey } from '../tools';

const DEFAULT_QUALITY = 80;

/** Function to replace CDNPublickLink with CDNFunctionLink */
export function withCDNFunctionLink(
    baseUrl,
    cdnPublicLink = process.env.NEXT_PUBLIC_CDN_PUBLIC_LINK,
    cdnFunctionLink = process.env.NEXT_PUBLIC_CDN_RESIZE_LINK
) {
    if (!baseUrl) {
        return baseUrl;
    }

    let newLink = baseUrl;
    if (newLink.includes(cdnPublicLink)) {
        const withoutPublicLink = baseUrl.replace(cdnPublicLink, '');
        const bucket = get_(withoutPublicLink.split('/'), [0]);
        newLink = cdnFunctionLink + bucket + '/images/view' + withoutPublicLink.replace(bucket, '');
    }
    return newLink;
}

const buildSuffixParameters = (suffixParams) => {
    let suffixes = '';
    Object.entries(suffixParams).forEach(
        ([key, value], idx) => (suffixes = suffixes + (idx === 0 ? '=' : '-') + key + value)
    );
    return suffixes;
};

/** Function to build URL with suffix params */
const buildURLWithSuffixParameters = (baseUrl, suffixParams) => {
    return baseUrl + buildSuffixParameters(suffixParams);
};

/** BUILD NEW IMAGE URL WITH SUFFIX PARAMETERS */
export function getOptimizedImageURL(image, params = {}) {
    if (!image) return '';

    let copyParams = { ...params };

    // Get base url (without query params & suffix)
    let baseUrl = get_(image.split('?'), [0]);
    baseUrl = get_(baseUrl.split('='), [0]);
    if (!baseUrl || baseUrl === '') return '';

    // Replace CDNPublicLink with CDNFunctionLink (so CDN functions can transform our image)
    baseUrl = withCDNFunctionLink(baseUrl);

    if (!copyParams.format) {
        // Check WebP compatibility
        let withWebP = sessionstorage.get('webpSupport', false) === 'true';
        if (!withWebP) {
            const canUseDOM = !!(
                typeof window !== 'undefined' &&
                window.document &&
                window.document.createElement
            );

            // Force webp server-side to avoid HTML mismatch between server and client
            // ...not ideal fix, but does the job
            if (!canUseDOM) withWebP = true;
        }
        if (!copyParams.f && withWebP) copyParams['f'] = 'webp';
    } else {
        delete copyParams.format;
    }

    // Set default quality
    if (!copyParams.q) copyParams['q'] = DEFAULT_QUALITY;

    const sortedParams = sortAlphabeticallyObjectByKey(copyParams);

    // Build new URL with suffix params
    return buildURLWithSuffixParameters(baseUrl, sortedParams);
}

export function sortProductImages(images) {
    if (!images || !images.length) return images;

    let imagesCopy = [...images];
    return imagesCopy.sort((a, b) => parseInt(a.order) - parseInt(b.order));
}

/**
 * Parse array of images, return the isMain one with the given size query string
 * @param images
 * @param size
 * @returns object
 */
export function parseMainProductImage(images, size = 800) {
    if (!images)
        return {
            url: '',
        };

    // First, try to retrive isMain image
    let image =
        images && images.length > 0
            ? images.filter((image) => {
                  return image.isMain === true;
              })
            : null;

    // If no isMain image, we take the first in order
    if (!image || !image[0]) image = sortProductImages(images);

    if (!image || !image[0])
        return {
            url: '',
        };

    image = image[0];

    let imgParams = {
        f: sessionstorage.get('webpSupport', false) === 'true' ? 'webp' : 'jpg',
    };

    if (size) imgParams['w'] = size;

    return {
        ...image,
        url: getOptimizedImageURL(image.url, imgParams),
    };
}

export default {
    withCDNFunctionLink,
    getOptimizedImageURL,
    sortProductImages,
    parseMainProductImage,
};
