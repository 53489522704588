import { initializeApollo } from '../../lib/apollo';
import { get_, cookie } from 'sf-modules';
import { CHECKOUT_UPDATE, CHECKOUT_RESET } from '../types';

export function removeUnavailableCheckoutLines() {
    // Remove apollo cache for unavailable products
    const apolloClient = initializeApollo();
    const cachedData = get_(apolloClient, ['cache', 'data', 'data'], []);
    Object.entries(cachedData).map(([key, value]) => {
        if (
            key.startsWith('CheckoutLine:{"rawId":') &&
            get_(value, ['productAvailability']) !== 'available'
        )
            apolloClient.cache.evict({ id: key });
    });
}

export function removeCheckout() {
    // Remove apollo cache for given products & checkout
    const apolloClient = initializeApollo();
    const cachedData = get_(apolloClient, ['cache', 'data', 'data'], []);
    Object.keys(cachedData).forEach((key) => {
        if (key.startsWith('CheckoutLine:{"rawId":')) apolloClient.cache.evict({ id: key });
        else if (key.startsWith('Checkout:{')) apolloClient.cache.evict({ id: key });
    });
}

export function checkoutEmptied() {
    // Remove apollo cache for all checkout products
    const apolloClient = initializeApollo();
    const cachedData = get_(apolloClient, ['cache', 'data', 'data'], []);
    Object.keys(cachedData).forEach((key) => {
        if (key.startsWith('CheckoutLine:{"rawId":')) apolloClient.cache.evict({ id: key });
    });
}

export function resetCheckout() {
    cookie.remove(process.env.NEXT_PUBLIC_COOKIE_CHECKOUT_ID);
    removeCheckout();
}

export function checkout_reset() {
    return (dispatch) => {
        dispatch({ type: CHECKOUT_RESET });
        resetCheckout();
    };
}

export function checkout_complete() {
    return (dispatch) => {
        dispatch({ type: CHECKOUT_RESET });
        resetCheckout();
    };
}

export function checkout_update(checkoutID) {
    return (dispatch) => {
        dispatch({
            type: CHECKOUT_UPDATE,
            payload: {
                checkoutID: checkoutID,
            },
        });
    };
}
