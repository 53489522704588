import React from 'react';
import * as PropTypes from 'prop-types';
import useMedia from 'use-media';
import ReactModal from 'react-modal';
import cx from 'classnames';
import { slugify } from 'sf-modules';
import ModalHeader from '../../atoms/ModalHeader';

const Modal = ({
    size,
    customHeight,
    onClose,
    className,
    style,
    title,
    subtitle,
    subtitleClassName,
    icon,
    headerClassName,
    headerWrapperClassName,
    headerAlignment,
    headerType,
    headerBackground,
    hiddenCloseButton,
    shouldCloseOnOverlayClick,
    isOpen,
    overlayClassName,
    children,
    ...otherProps
}) => {
    const isMobile = useMedia({ maxWidth: 640 });

    const baseOverlayClass = cx(
        overlayClassName,
        'fixed top-0 right-0 bottom-0 left-0 flex items-center justify-center pointer-events-auto w-full',
        'opacity-100 z-modal transition duration-200 ease-modal'
    );
    const baseClass = cx(
        className,
        'bg-ui-white outline-none z-modal fixed flex flex-col mt-0 mx-auto w-full float-none p-0',
        'overflow-auto', // To allow scroll inside the modal
        // Desktop
        'sm:rounded-lg sm:mb-12 sm:bottom-auto sm:shadow-down',
        // Mobile sticky bottom
        'rounded-t-md mb-0 bottom-0 max-w-none shadow-up',
        customHeight ? customHeight : 'max-h-modal-sm',
        {
            'sm:max-w-md': size === 'sm',
            'sm:max-w-xl': size === 'md',
            'sm:max-w-2xl': size === 'lg',
            'sm:max-w-[780px]': size === 'xl',
        }
    );

    return (
        <ReactModal
            id={typeof title === 'string' ? slugify(title) : null}
            overlayClassName={baseOverlayClass}
            className={baseClass}
            style={style}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
            onRequestClose={onClose}
            isOpen={isOpen}
            ariaHideApp={false}
            contentLabel='default'
            {...otherProps}
        >
            <ModalHeader
                containerClassName={headerClassName}
                wrapperClassName={headerWrapperClassName}
                subtitleClassName={subtitleClassName}
                onClose={onClose}
                title={title}
                subtitle={subtitle}
                icon={!isMobile && icon}
                headerBackground={headerBackground}
                textAlignment={icon && !headerAlignment ? 'center' : headerAlignment}
                hiddenCloseButton={hiddenCloseButton}
                type={headerType}
            />

            {children}
        </ReactModal>
    );
};

Modal.displayName = 'Modal';

Modal.defaultProps = {
    isOpen: false,
    shouldCloseOnOverlayClick: true,
    overlayClassName: 'bg-overlay-primary-300',
    size: 'md',
};

Modal.propTypes = {
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    customHeight: PropTypes.string,
    onClose: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.object,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subtitleClassName: PropTypes.string,
    icon: PropTypes.node,
    headerClassName: PropTypes.string,
    headerWrapperClassName: PropTypes.string,
    headerAlignment: PropTypes.string,
    headerType: PropTypes.string,
    headerBackground: PropTypes.string,
    hiddenCloseButton: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    overlayClassName: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Modal;
