import * as PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Section from '../Section';
import ClickToChat from '../../atoms/ClickToChat';

const Footer = dynamic(() => import('../../organisms/Footer'), {
    ssr: false,
});

const Header = dynamic(() => import('../../organisms/Header'), {
    ssr: false,
});

const ErrorComponent = ({ title, customIcon, children, context, cardFooterComponent }) => (
    <Section>
        <div className='flex flex-col items-center justify-center gap-y-5'>
            <img
                src={
                    'https://storage.googleapis.com/yema-assets/icons/checkout-error.svg' ||
                    customIcon
                }
                alt='Error'
            />
            <h4 className='text-center'>{title}</h4>

            {children && children}

            {cardFooterComponent && cardFooterComponent}

            {context === 'checkout' ? (
                <p className='text-center'>
                    Si necesitas ayuda con tu pedido no dudes en contactarnos <br />
                    <ClickToChat>
                        <span className='underline'>Inicia una sesión de chat ahora</span> 👇
                    </ClickToChat>
                    {` `} o al teléfono{' '}
                    <a href='tel:+525618394661' className='underline md:no-underline'>
                        56 1839 4661
                    </a>
                </p>
            ) : (
                <p className='text-center'>
                    Si necesitas asistencia inmediata escríbenos a{' '}
                    <a href='mailto:hola@yema.mx'>hola@yema.mx</a>
                </p>
            )}
        </div>
    </Section>
);

const PageError = ({ customIcon, title, children, context, fullPage, cardFooterComponent }) => {
    if (fullPage)
        return (
            <>
                <Header />
                <ErrorComponent
                    title={title}
                    context={context}
                    customIcon={customIcon}
                    children={children}
                    cardFooterComponent={cardFooterComponent}
                />
                <Footer type='minimal' />
            </>
        );

    return (
        <ErrorComponent
            title={title}
            context={context}
            customIcon={customIcon}
            children={children}
            cardFooterComponent={cardFooterComponent}
        />
    );
};
export default PageError;

PageError.defaultProps = {
    context: '',
    fullPage: false,
};

PageError.propTypes = {
    title: PropTypes.string,
    context: PropTypes.string,
    children: PropTypes.node,
    customIcon: PropTypes.string,
    cardFooterComponent: PropTypes.node,
};
