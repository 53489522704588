// @ts-nocheck

import {
    gql as gqlApollo,
    useQuery as useApolloQuery,
    useLazyQuery as useApolloLazyQuery,
    useMutation as useApolloMutation,
    useApolloClient,
} from '@apollo/client';

export function useQuery(query, options) {
    return useApolloQuery(query, options);
}

export function useLazyQuery(query, options) {
    return useApolloLazyQuery(query, options);
}

export function useMutation(mutation) {
    return useApolloMutation(mutation);
}

export function useClient() {
    return useApolloClient();
}

export function gql(literals, ...placeholders) {
    return gqlApollo(literals, ...placeholders);
}
