import { gql } from '@apollo/client';

const STORES_BY_ZIPCODE_QUERY = gql`
    query StoresByZipCode($zipCode: String!) {
        storesByZipCode(zipCode: $zipCode) {
            id
            name
            algoliaIndex
            defaultOnPos
        }
    }
`;

export default STORES_BY_ZIPCODE_QUERY;
