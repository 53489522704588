import { get_ } from '../tools';
import money from '../money';
import imageTools from '../imageTools';
import { parseAvailability } from './productUtils';

const transformProductFromAlgolia = (props) => {
    const {
        objectID,
        gql_id,
        slug,
        brand,
        name,
        description,
        is_bundle,
        selling_type: raw_selling_type,
        selling_value,
        selling_unit,
        increment_value,
        // increment_unit,
        weight_per_piece_value,
        // weight_per_piece_unit,
        aisles,
        base_measure_price,
        base_measure_discounted_price,
        price,
        discounted_price,
        stock_available,
        stock_available_unit,
        available_quantity,
        checkout_max_quantity,
        images,
        main_image,
        hover_image,
        review_average,
        review_count,
        first_applicable_rule,
        campaign_icon,
        raw_id,
        plm_id,
        analytics_id,
        barcodes,
    } = props;

    const href = is_bundle && slug ? '/k/' + slug : '/p/' + slug;

    const selling_type =
        raw_selling_type || (stock_available_unit === 'kg' ? 'por-peso' : 'por-pieza');
    const sale_by_weight = selling_type !== 'por-pieza';

    const productCurrentPrice = discounted_price ? discounted_price : price;
    const productCurrentBasePrice = base_measure_discounted_price
        ? base_measure_discounted_price
        : base_measure_price;

    const parsedPricing = {
        formattedPrice: money.formatMoney(productCurrentPrice),
        price: productCurrentPrice,

        formattedPriceUndiscounted: money.parseUndiscountedPrice(productCurrentPrice, price),
        priceUndiscounted: price,

        // basePrice: productCurrentBasePrice,
        formattedBasePrice: money.formatMoney(productCurrentBasePrice),
        baseUndiscountedPrice: base_measure_price,
        formattedBaseUndiscountedPrice: money.formatMoney(base_measure_price),

        discount: money.getDiscount(productCurrentPrice, price),
    };

    const catalogPrice = {
        sku: objectID ?? '',
        isBundle: is_bundle ?? false,
        baseMeasureDiscountedPrice: productCurrentBasePrice,
        baseMeasurePrice: base_measure_price,
        discountedPrice: productCurrentPrice,
        price: price,
    };

    const stock = stock_available ? stock_available : 0;

    const stockUnit = is_bundle ?? false ? 'pack' : sale_by_weight ? selling_unit : 'pz';

    const availableStockUnit =
        is_bundle ?? false ? 'pack' : stock_available_unit || available_quantity?.unit || 'pz';

    const mainCategory = [
        get_(aisles, ['main', 'first_level']),
        get_(aisles, ['main', 'second_level']),
        get_(aisles, ['main', 'third_level']),
    ]
        .filter(Boolean)
        .join(' > ');

    const category = mainCategory
        ? mainCategory
        : get_(aisles, ['lvl2', 0], get_(aisles, ['lvl1', 0], get_(aisles, ['lvl0', 0])));

    const mainImage = get_(main_image, ['url']) || get_(images, ['image_url']);
    const mainImageAlt = get_(main_image, ['alt']) || get_(images, ['image_alt'], '');

    const product = {
        rawId: raw_id,
        plmId: plm_id,
        analyticsId: analytics_id,
        barcodes,
        gqlId: gql_id,
        href,
        reviewAverage: review_average,
        reviewCount: review_count,
        mainImage: mainImage,
        mainImageAlt: mainImageAlt,
        hoverImage: get_(hover_image, ['url']),
        thumbnail: imageTools.getOptimizedImageURL(mainImage, { w: 100 }),

        sellingType: selling_type,
        sellingValue: selling_value,
        sellingUnit: selling_unit,
        stepperIncrement: sale_by_weight
            ? {
                  value: increment_value || weight_per_piece_value,
                  unit: 'kg', // should be 'increment_unit || weight_per_piece_unit' when these fields will be used in PLM2
              }
            : null,

        firstApplicableRule: {
            title: first_applicable_rule,
            icon: campaign_icon,
        },
        availability: undefined,
        pricing: parsedPricing,
        catalogPrice: catalogPrice,
        backgroundColor: '',
        brand: brand ?? '',
        description: description ?? '',
        isAvailable: true,
        isBundle: is_bundle ?? false,
        longDescription: '',
        name: name ?? '',
        quantity: 0,
        quantityDisplay: '',
        seoDescription: description ?? '',
        seoTitle: name ?? '',
        sku: objectID ?? '',
        gtin: objectID ?? '',
        slug: slug ?? '',
        stock: stock, // @TODO Ludo: should deprecate this field (now use availability object)
        category: category,
        stockUnit: stockUnit,
        availableStockUnit: availableStockUnit,
    };

    product.availability = parseAvailability(
        null,
        {
            ...product,
            checkoutMaxQuantity: checkout_max_quantity,
        },
        {
            availableOnZipCode: true,
            itemsAvailable: stock_available,
            status: stock_available && stock_available > 0 ? 'available' : 'stock-out',
        }
    );

    return product;
};

export default transformProductFromAlgolia;
