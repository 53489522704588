export const PRODUCT_BUNDLE = {
    availability: {
        availableOnZipCode: true,
        checkoutMaxQuantity: 791,
        itemsAvailable: 791,
        status: 'available',
    },
    quantity: 1,
    customerQuantity: 1,
    quantityDisplay: '1',
    id: 'QnVuZGxlOjE1',
    name: 'Pack galletas',
    slug: 'pack-galletas',
    description: '<p>Ricas galletas de diferentes sabores y colores.</p>',
    totalPrice: 228.0,
    stockQuantity: 791,
    isBundle: true,
    billedQuantity: 1,
    aisle: {
        firstLevel: {
            name: 'Comida',
            slug: 'comida',
        },
        secondLevel: {
            name: 'Botanas',
            slug: 'botanas',
        },
        thirdLevel: {
            name: 'Galletas',
            slug: 'galletas',
        },
    },
    blobs: [
        {
            word: 'Pack',
            color: '#fff',
            fontColor: '#000',
            borderColor: '#000',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '0px',
        },
        {
            word: 'galletas',
            color: '#fff',
            fontColor: '#000',
            borderColor: '#000',
            borderStyle: 'solid',
            borderWidth: '1px',
            borderRadius: '0px',
        },
    ],
    images: {
        mainAlt: 'Galletas',
        mainUrl:
            'https://us-central1-yema-cdn-dev.cloudfunctions.net/cdn/api/v1/cdnplm/images/view/bundleImage/galletas_11cdca87-3027-4d04-9199-4c731c4df599',
    },
    products: [
        {
            name: 'Galletas Veganas con Arándanos',
            description:
                '¡Naturalmente deliciosas!\n\nNuestras Galletas Veganas con Arándanos están hechas a base de semillas, sin ingredientes de origen animal.',
            gtin: '7503027879855',
            slug: 'galletas-veganas-con-arandanos',
            stock: 0.0,
            images: [
                {
                    altText: 'Galletas Veganas con Arándano',
                    url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/_GALLETAS_ARANDANOS_F_8ce6f4b2-1fea-4d0a-9898-89ce7e07c8b7',
                },
                {
                    altText: 'Galletas Veganas con Arándano',
                    url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/_GALLETAS_ARANDANOS_B_bef5e9ff-4cc4-4964-af17-ad2165d7cd72',
                },
                {
                    altText: 'Galletas Veganas con Arándano',
                    url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/20-BATCH6_ALTA_c0c2697a-91cf-4186-a37a-9dd7830fd42c',
                },
            ],
        },
        {
            name: 'Galletas Veganas con Chispas de Chocolate',
            description:
                '¡Naturalmente deliciosas!\n\nNuestras Galletas Veganas con Chispas de Chocolate están hechas a base de semillas, sin ingredientes de origen animal.',
            gtin: '7503027879848',
            slug: 'galletas-veganas-con-chispas-de-chocolate',
            stock: 0.0,
            images: [
                {
                    altText: 'Galleta vegana ',
                    url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/_GALLETAS_CHOCO_F_b406bb2c-dfdd-4d0d-8f9d-f66587d5e660',
                },
                {
                    altText: 'Galleta vegana',
                    url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/_GALLETAS_CHOCO_B_b0d11b32-414d-4458-8a37-0d3f14d1a37d',
                },
                {
                    altText: 'Galleta vegana',
                    url: 'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/productImage/19-BATCH6_ALTA_94c4331a-31ad-489d-89fc-99c293bd3cc6',
                },
            ],
        },
    ],
    certifications: [
        {
            name: 'Reciclable',
            logoUrl:
                'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/certificate_48a53943-26ef-4015-b3aa-8f4c5fdc4728',
        },
        {
            name: 'Vegano',
            logoUrl:
                'https://us-central1-yema-cdn.cloudfunctions.net/cdn/api/v1/yema-plm/images/view/certificate_86a4b5dc-104e-40e4-a7f8-89b43527f01f',
        },
    ],
    facets: [],
    isPublished: true,
    pricing: {
        discount: null,
        formattedPrice: 'MXN 228.00',
        formattedPriceUndiscounted: null,
        price: 228,
        priceUndiscounted: null,
    },
    route: {
        as: '/k/pack-galletas',
    },
};

export default PRODUCT_BUNDLE;
