import { InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { imageTools } from 'sf-modules';

function relayCursorPagination(keyArgs) {
    return {
        keyArgs: keyArgs,
        merge(exsting, incoming) {
            return incoming;
        },
        read(existing) {
            if (existing) {
                return existing;
            }
        },
    };
}

const cacheConfiguration = {
    typePolicies: {
        Query: {
            fields: {
                productReviews: relayStylePagination(['productId']),
                cashBoxTransactions: relayCursorPagination([
                    'first',
                    'last',
                    'before',
                    'after',
                    'posId',
                    'userId',
                    'cashBoxId',
                    'cashBoxIds',
                ]),
            },
        },
        Product: {
            fields: {
                sku: {
                    read(_, { readField }) {
                        return readField('gtin');
                    },
                },
                href: {
                    read(_, { readField }) {
                        return `/p/${readField('slug')}`;
                    },
                },
                mainImageAlt: {
                    read(_, { readField }) {
                        return readField('name');
                    },
                },
                stepperIncrement: {
                    read(_, { readField }) {
                        let sellingType = readField('sellingType');
                        let weightPerPieceValue = parseFloat(readField('weightPerPieceValue'));
                        let weightPerPieceUnit = readField('weightPerPieceUnit');
                        let incrementValue = parseFloat(readField('incrementValue'));
                        let incrementUnit = readField('incrementUnit');

                        return sellingType !== 'por-pieza'
                            ? {
                                  value: incrementValue || weightPerPieceValue,
                                  unit: incrementUnit || weightPerPieceUnit,
                              }
                            : null;
                    },
                },
            },
        },
        Bundle: {
            fields: {
                isBundle: {
                    read() {
                        return true;
                    },
                },
                gtin: {
                    read(_, { readField }) {
                        return readField('sku');
                    },
                },
                href: {
                    read(_, { readField }) {
                        return `/k/${readField('slug')}`;
                    },
                },
                mainImage: {
                    read(_, { readField }) {
                        let images = (readField('images') || []).map((x) => ({
                            order: readField('order', x),
                            url: readField('url', x),
                        }));
                        let sortedImages = imageTools.sortProductImages(images);
                        return sortedImages && sortedImages.length ? sortedImages[0].url : null;
                    },
                },
                mainImageAlt: {
                    read(_, { readField }) {
                        return readField('name');
                    },
                },
                sellingValue: {
                    read(_, { readField }) {
                        let bundle = readField('bundle');
                        let products = bundle
                            ? readField('products', bundle)
                            : readField('products');
                        return products
                            ? products.reduce(
                                  (total = 0, product) => total + readField('quantity', product),
                                  0
                              )
                            : null;
                    },
                },
                sellingUnit: {
                    read() {
                        return 'U';
                    },
                },
            },
        },
        Checkout: {
            fields: {
                appliedBenefits: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                availableShippingMethods: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                cartRules: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                lines: {
                    keyArgs: [],
                    merge(existing, incoming, { args }) {
                        return incoming;
                    },
                },
            },
        },
        CheckoutLine: {
            keyFields: ['rawId'],
            fields: {
                appliedBenefits: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
            },
        },
    },
};

export const cache = new InMemoryCache(cacheConfiguration);
export const createNewCache = () => new InMemoryCache(cacheConfiguration);
