/** URLs FOR APOLLO CONFIG */
export const APOLLO_GRAPHQL_TIMEOUT = 30000;

/** COOKIES & LOCAL STORAGE */
export const CATALOG_LS = 'y_catalog';
export const PROMO_HEADER = 'y_promo_header';

/** SOCIAL LOGIN */
export const LINKEDIN_PAGE = 'https://www.linkedin.com/company/yemamx/';
export const INSTAGRAM_PAGE = 'https://www.instagram.com/yema.mx/';
export const TWITTER_PAGE = 'https://twitter.com/yemamx';
export const FB_PAGE = 'https://www.facebook.com/yema.mx/';
export const FB_CALLBACK = '/cuenta/auth/fblogin';

/** CMS SLUG FOR MENUS */
export const CMS_SLUG_CATALOG_CATEGORIES_MENU = 'menu-de-categorias';
export const CMS_SLUG_FOOTER_MENU = 'footer-menu';

/** REGEX */
export const EMAIL_REGEX =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/** VALUES */
export const BREAKPOINT_MD = 768;
export const BREAKPOINT = 991;
export const BREAKPOINT_XS = 576;
export const DEFAULT_CHECKOUT_EMAIL = 'pedidos@yema.mx';

export const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
];

/** GOOGLE MAPS */
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCaipMzgmvs4qPM-cVhqDSJpVKzxYlUcnU';
