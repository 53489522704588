import { get_ } from '../tools';
import transformProductFromPIM from './productFromPIM';
import transformBundleFromPIM from './bundleFromPIM';

const transformCheckoutLineFromPIM = (rawCheckoutLine, checkout) => {
    const checkoutLine = {
        ...rawCheckoutLine,
        appliedBenefits: (rawCheckoutLine?.appliedBenefits || []).filter(
            (x) => x.title !== checkout.voucherCode
        ),
    };

    const availability = {
        availableOnZipCode: checkoutLine.productAvailability === 'available',
        itemsAvailable: checkoutLine.itemsAvailable,
        status: checkoutLine.productAvailability,
    };

    if (checkoutLine.isBundle) {
        return transformBundleFromPIM({
            bundle: checkoutLine.bundle,
            quantity: checkoutLine.quantity,
            checkoutLine: checkoutLine,
            availability: availability,
        });
    }

    return transformProductFromPIM({
        product: get_(checkoutLine, ['variant', 'product']),
        quantity: checkoutLine.quantity,
        checkoutLine: checkoutLine,
        availability: availability,
    });
};

export default transformCheckoutLineFromPIM;
