import React from 'react';
import * as PropTypes from 'prop-types';
import cx from 'classnames';

const Section = ({ className, children, ...otherProps }) => (
    <section className={cx(className, 'w-full py-8')} {...otherProps}>
        <div className='mx-auto' style={{ maxWidth: '1376px' }}>
            {children}
        </div>
    </section>
);

Section.displayName = 'Section';

Section.defaultProps = {};

Section.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
};

export default Section;
