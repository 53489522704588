import { gql } from '@apollo/client';

export const CheckoutBasicFieldsFragment = gql`
    fragment CheckoutBasicFields on Checkout {
        id
        token
        quantity
        email
        totalPrice
        zipCode
    }
`;
