import { useMutation } from '@apollo/client';
import { get_, stringify } from 'sf-modules';
import { fnDoMutation } from '../../utils/fnDoMutation';
import { trackError } from '../../utils/error';
import useCommerce from '../../useCommerce';
import useCheckout from './useCheckout';
import useCheckoutUpdate from './useCheckoutUpdate';
import useSetShippingAddress from './useSetShippingAddress';
import CHECKOUT_SHIPPING_METHOD_UPDATE_MUTATION from '../../graphql/mutations/checkout/checkoutShippingMethodUpdate.gql';

export default function useSetDeliveryMethod() {
    const { checkoutBase64Id } = useCheckout();
    const { checkout: checkoutData } = useCommerce();
    const { setShippingAddressById } = useSetShippingAddress();
    const checkoutUpdate = useCheckoutUpdate();

    const [mutationUpdateShippingMethod] = useMutation(CHECKOUT_SHIPPING_METHOD_UPDATE_MUTATION);

    const setDeliveryMethod = async ({
        id,
        shippingType,
        forceUpdateCheckout = false,
    }: {
        id: string;
        shippingType: string;
        forceUpdateCheckout?: boolean;
    }) => {
        if (!id)
            return {
                success: false,
                errors: [
                    {
                        message: 'Ningún método de entrega ha sido seleccionado',
                    },
                ],
            };

        // Automatically set stock location address for immediate delivery or store pickup
        if (shippingType === 'immediate' || shippingType === 'store_pickup') {
            await setShippingAddressById(
                get_(checkoutData, ['store', 'stockLocation', 'address']),
                get_(checkoutData, ['store', 'stockLocation', 'address', 'id']),
                true
            );
        }

        const { error, data } = await fnDoMutation(mutationUpdateShippingMethod, {
            checkout: checkoutBase64Id,
            shippingMethod: id,
        });

        if (error || get_(data, ['checkoutShippingMethodUpdate', 'errors'], []).length) {
            trackError({
                label: 'Impossible to set delivery method',
                extras: {
                    location: 'useSetDeliveryMethod > setDeliveryMethod',
                    deliveryMethodId: id,
                    checkoutBase64Id: checkoutBase64Id,
                    error: error,
                    errors: stringify(get_(data, ['checkoutShippingMethodUpdate', 'errors'])),
                },
            });
            return {
                success: false,
                errors: error || get_(data, ['checkoutShippingMethodUpdate', 'errors'], []),
            };
        }

        if (forceUpdateCheckout)
            checkoutUpdate({
                newCheckoutData: get_(data, ['checkoutShippingMethodUpdate', 'checkout']),
                updateLines: false,
            });

        return {
            success: true,
            ...get_(data, ['checkoutShippingMethodUpdate', 'checkout']),
        };
    };

    return {
        setDeliveryMethod,
    };
}
