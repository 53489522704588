import * as Sentry from '@sentry/nextjs';

const name = process.env.APP_NAME || 'unnamed-app';
const version = process.env.APP_VERSION || 'no-version';

const getErrorTitle = (label) => {
    const application = process.env.NEXT_PUBLIC_APP_TYPE || 'SF';
    return `${application} (${process.env.NEXT_PUBLIC_DEPLOY_ENV}) - v${version}: ${label}`;
};

/**
 * Send error to Sentry via Segment
 * @param label
 * @param data
 * @param tags
 */
export function trackError({
    label = null,
    error = null,
    extras = null,
    tags = null,
    path = null,
}) {
    const err = error || new Error(getErrorTitle(label));
    if (error) err.message = getErrorTitle(err.message);

    if (process.env.NEXT_PUBLIC_DEPLOY_ENV === 'local') {
        console.error('trackError: ', err.message, extras, tags);
    }

    Sentry.withScope((scope) => {
        scope.setExtras({
            app_name: name,
            app_version: version,
            app_environment: process.env.NEXT_PUBLIC_DEPLOY_ENV,
            ...extras,
        });

        if (tags) scope.setTags(tags);

        if (path)
            scope.addBreadcrumb({
                category: 'path',
                message: path.join(' > '),
                level: 'debug',
            });

        Sentry.captureException(err);
    });
}

/**
 * Callback to trackError
 * @param message
 * @param object
 */
export function errorCallback(message, object) {
    trackError({
        label: message,
        extras: object,
    });
}
