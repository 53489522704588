import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { CheckoutStoreFieldsFragment } from '../../fragments/checkout/CheckoutStoreFields.gql';
import { CheckoutPricingFieldsFragment } from '../../fragments/checkout/CheckoutPricingFields.gql';
import { CheckoutExtendedFieldsFragment } from '../../fragments/checkout/CheckoutExtendedFields.gql';
import { LinesBasicFieldsFragment } from '../../fragments/checkout/LinesBasicFields.gql';
import { LinesForAnalyticsFieldsFragment } from '../../fragments/checkout/LinesForAnalyticsFields.gql';
import { LinesExtendedFieldsFragment } from '../../fragments/checkout/LinesExtendedFields.gql';
import { LinesAppliedBenefitsFieldsFragment } from '../../fragments/checkout/LinesAppliedBenefitsFields.gql';

const CHECKOUT_UPDATE_OR_CREATE_MUTATION = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutStoreFieldsFragment}
    ${CheckoutPricingFieldsFragment}
    ${CheckoutExtendedFieldsFragment}
    ${LinesBasicFieldsFragment}
    ${LinesForAnalyticsFieldsFragment}
    ${LinesExtendedFieldsFragment}
    ${LinesAppliedBenefitsFieldsFragment}
    mutation CheckoutUpdateOrCreate(
        $token: String
        $lines: [CheckoutLineInput]!
        $email: String
        $zipCode: String
        $withStoreFields: Boolean = false
        $withPricingFields: Boolean = false
        $withLinesExtendedFields: Boolean = false
        $applyCartRules: Boolean = false
    ) {
        checkoutUpdateOrCreate(
            token: $token
            lines: $lines
            email: $email
            zipCode: $zipCode
            applyCartRules: $applyCartRules
        ) {
            checkout {
                ...CheckoutBasicFields
                ...CheckoutStoreFields @include(if: $withStoreFields)
                ...CheckoutPricingFields @include(if: $withPricingFields)
                ...CheckoutExtendedFields @include(if: $applyCartRules)
                lines {
                    ...LinesBasicFields
                    ...LinesForAnalyticsFields @skip(if: $withLinesExtendedFields)
                    ...LinesExtendedFields @include(if: $withLinesExtendedFields)
                    ...LinesAppliedBenefitsFields @include(if: $withPricingFields)
                }
            }
            errors {
                message
                field
            }
        }
    }
`;

export default CHECKOUT_UPDATE_OR_CREATE_MUTATION;
