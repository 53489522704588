// @ts-nocheck

import React, { useContext } from 'react';
import { get_, stringify, dataTransformers } from 'sf-modules';
import { CartProblemIcon } from 'icons/solid';
import { trackError } from '../../utils/error';
import { APP_STATE_TYPES, MODAL_KIND, CommerceContext } from '../../CommerceProvider';
import useTracker from '../tracking/useTracker';
import useCheckoutUpdate from '../checkout/useCheckoutUpdate';
import useRemoveShippingAddress from '../checkout/useRemoveShippingAddress';
import STORE_QUERY from '../../graphql/queries/store/store.gql';
import CHECKOUT_CHANGE_STORE_MUTATION from '../../graphql/mutations/checkout/checkoutChangeStore.gql';
import CHECKOUT_POS_UPDATE_MUTATION from '../../graphql/mutations/checkout/checkoutPosUpdate.gql';

export default function useStore() {
    const { apolloClient } = useContext(CommerceContext);
    const { trackEvent } = useTracker();
    const checkoutUpdate = useCheckoutUpdate();
    const removeShippingAddress = useRemoveShippingAddress();

    const fetchStore = async (zipCode = null) => {
        const { data } = await apolloClient.query({
            query: STORE_QUERY,
            variables: {
                zipCode: zipCode,
            },
        });
        return get_(data, ['store']);
    };

    const fetchNewStore = async (zipCode, addressId, catalog, dispatch) => {
        const newStore = await fetchStore(zipCode);

        if (!newStore || !newStore.algoliaIndex) {
            trackError({
                label: 'No store returned for given zipCode',
                extras: {
                    zipCode: zipCode,
                    response: newStore,
                },
            });
            return null;
        }

        dispatch({
            type: APP_STATE_TYPES.CATALOG_ADD_SETTINGS,
            zipCode: zipCode,
            addressId: addressId,
            store: newStore,
            index: newStore.algoliaIndex,
            createdAt: new Date(),
        });

        return newStore;
    };

    const updateCheckoutStore = async (zipCode, checkoutBase64Id, erase = false) => {
        try {
            return await apolloClient.mutate({
                mutation: CHECKOUT_CHANGE_STORE_MUTATION,
                variables: {
                    checkoutId: checkoutBase64Id,
                    zipCode: zipCode,
                    erase: erase ? erase : false,
                    withPricingFields: true, // Required to update apollo cache when setting last user checkout
                },
            });
        } catch (e) {
            return null;
        }
    };

    const setZipCodeAndUpdateStore = async ({
        catalog,
        dispatch,
        zipCode = null,
        addressId = null,
        checkoutBase64Id = null,
        resetCheckoutShippingAddress = null,
        pipeCart = [],
    }) => {
        if (zipCode) {
            trackEvent({
                type: 'Delivery Zipcode Set',
                data: {
                    category: 'Catalog',
                    zipCode: zipCode,
                },
            });
        }

        const checkoutUpdated =
            zipCode && checkoutBase64Id
                ? await updateCheckoutStore(zipCode, checkoutBase64Id)
                : null;

        const checkoutUpdatedId = get_(checkoutUpdated, [
            'data',
            'checkoutChangeStore',
            'checkout',
            'id',
        ]);

        // Error at checkoutChangeStore
        if (zipCode && checkoutBase64Id && (!checkoutUpdated || !checkoutUpdatedId)) {
            trackError({
                label: 'Could not modify checkout store',
                extras: {
                    checkoutBase64Id: checkoutBase64Id,
                    oldSettings: catalog,
                    newZipCode: zipCode,
                    error: stringify(
                        get_(checkoutUpdated, ['data', 'checkoutChangeStore', 'errors'], [])
                    ),
                },
            });
            dispatch({
                type: APP_STATE_TYPES.TOGGLE_MODAL,
                kind: MODAL_KIND.ALERT,
                open: true,
                props: {
                    modalTitleIcon: (
                        <CartProblemIcon className='text-brand-tertiary-700 mx-auto block w-20' />
                    ),
                    modalTitle: 'Ocurrió un error al modificar la zona de entrega de tu carrito',
                    modalTextNode: (
                        <p className='mb-0 text-center'>
                            No pudimos modificar la zona de entrega de tu carrito a este código
                            postal: {zipCode} :( Intenta nuevamente o contáctanos via el chat.
                        </p>
                    ),
                    confirmText: 'Cerrar',
                },
            });

            return null;
        } else {
            if (resetCheckoutShippingAddress) resetCheckoutShippingAddress();

            const store = fetchNewStore(zipCode, addressId, catalog, dispatch);
            if (!store) return null;

            // Check if some products are out-of-stock or unavailable in this new store
            const unavailableProducts = get_(
                checkoutUpdated,
                ['data', 'checkoutChangeStore', 'checkout', 'lines'],
                []
            )
                .filter(
                    (line) =>
                        !line.itemsAvailable ||
                        (!line.isGift && line.productAvailability !== 'available')
                )
                .map((line) =>
                    dataTransformers.transformCheckoutLineFromPIM(
                        line,
                        checkoutUpdated.data.checkoutChangeStore.checkout
                    )
                );

            if (unavailableProducts.length)
                // Show modal "Unavailable products"
                dispatch({
                    type: APP_STATE_TYPES.CART_SET_UNAVAILABLE_PRODUCTS,
                    products: unavailableProducts,
                });

            dispatch({ type: APP_STATE_TYPES.CART_EMPTY_QUEUE });

            if (pipeCart && pipeCart.length) {
                if (!zipCode) {
                    // restore counter to initial quantity
                    pipeCart.map((p) => p.setCountersCallback(p.product.quantity));
                } else {
                    // add products
                    // @TODO Ludo: Fix: we should make only one call to checkoutUpdateOrCreate (especially when checkoutId is null)
                    pipeCart.map((p) =>
                        p.addToCartCallback(
                            p.quantity,
                            p.unit,
                            {
                                ...catalog,
                                zipCode,
                                index: get_(store, ['algoliaIndex'], catalog.index),
                            },
                            checkoutBase64Id
                        )
                    );
                }
            }

            return store;
        }
    };

    const updatePos = async (checkoutBase64Id, posId, storeId, erase = false) => {
        try {
            return await apolloClient
                .mutate({
                    mutation: CHECKOUT_POS_UPDATE_MUTATION,
                    variables: {
                        checkoutId: checkoutBase64Id,
                        posId: posId,
                        storeId: storeId,
                        erase: erase,
                    },
                })
                .then((res) => {
                    // Update local copy of checkout only if CheckoutProvider has been declared
                    if (typeof checkoutUpdate === 'function') {
                        checkoutUpdate({
                            newCheckoutData: get_(res, ['data', 'checkoutPosUpdate', 'checkout']),
                        });
                    }
                    return res;
                });
        } catch (e) {
            return null;
        }
    };

    const setUpdatePos = async ({
        catalog,
        dispatch,
        checkoutBase64Id,
        zipCode,
        addressId,
        posName,
        posId,
        storeId,
    }) => {
        if (!checkoutBase64Id || !posId) return;

        const checkoutPosUpdated = await updatePos(checkoutBase64Id, posId, storeId);
        const checkoutPos = get_(checkoutPosUpdated, [
            'data',
            'checkoutPosUpdate',
            'checkout',
            'pointOfSale',
        ]);
        const checkoutStore = get_(checkoutPosUpdated, [
            'data',
            'checkoutPosUpdate',
            'checkout',
            'store',
        ]);

        // Reset checkout address to avoid having shipping.address.zipcode != checkout.zipcode (SF-1684)
        await removeShippingAddress(checkoutBase64Id);

        // Error at checkoutPosUpdated
        if (!checkoutPosUpdated || !checkoutStore) {
            trackError({
                label: 'Could not modify pos',
                extras: {
                    checkoutBase64Id: checkoutBase64Id,
                    oldSettings: catalog,
                    newZipCode: zipCode,
                    error: stringify(
                        get_(checkoutPosUpdated, ['data', 'checkoutPosUpdate', 'errors'], [])
                    ),
                },
            });
            dispatch({
                type: APP_STATE_TYPES.TOGGLE_MODAL,
                kind: MODAL_KIND.ALERT,
                open: true,
                props: {
                    modalTitleIcon: (
                        <CartProblemIcon className='text-brand-tertiary-700 mx-auto block w-20' />
                    ),
                    modalTitle: 'Ocurrió un error al modificar el punto de venta o la tienda',
                    modalTextNode: (
                        <p className='mb-0 text-center'>
                            No pudimos modificar a el siguiente punto de venta: {posName} :( Intenta
                            nuevamente o contáctanos via el chat.
                            <br />
                            <br />
                            {get_(checkoutPosUpdated, [
                                'data',
                                'checkoutPosUpdate',
                                'errors',
                                0,
                                'message',
                            ])}
                        </p>
                    ),
                    confirmText: 'Cerrar',
                },
            });
        } else {
            // Check if some products are out-of-stock or unavailable in this new store
            const unavailableProducts = get_(
                checkoutPosUpdated,
                ['data', 'checkoutPosUpdate', 'checkout', 'lines'],
                []
            )
                .filter(
                    (line) =>
                        !line.itemsAvailable ||
                        (!line.isGift && line.productAvailability !== 'available')
                )
                .map((line) =>
                    dataTransformers.transformCheckoutLineFromPIM(
                        line,
                        checkoutPosUpdated.data.checkoutPosUpdate.checkout
                    )
                );

            if (unavailableProducts.length)
                dispatch({
                    type: APP_STATE_TYPES.CART_SET_UNAVAILABLE_PRODUCTS,
                    products: unavailableProducts,
                });

            dispatch({
                type: APP_STATE_TYPES.CATALOG_SET_SETTINGS,
                zipCode: zipCode,
                addressId: addressId,
                pos: checkoutPos,
                store: checkoutStore,
                index: checkoutStore.algoliaIndex,
                createdAt: new Date(),
            });
        }

        return checkoutPosUpdated;
    };

    return {
        updateCheckoutStore,
        setZipCodeAndUpdateStore,
        updatePos,
        setUpdatePos,
    };
}
