import { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { get_ } from 'sf-modules';
import { fnDoMutation } from '../../utils/fnDoMutation';
import { CheckoutContext } from './CheckoutProvider';
import useCheckoutUpdate from '../../hooks/checkout/useCheckoutUpdate';
import CHECKOUT_ERASE_OUT_OF_STOCK_MUTATION from '../../graphql/mutations/checkout/checkoutEraseOutOfStock.gql';

export default function useCheckoutEraseOutOfStock() {
    const { checkoutBase64Id } = useContext(CheckoutContext);
    const checkoutUpdate = useCheckoutUpdate();
    const [mutationCheckoutEraseOutOfStock] = useMutation(CHECKOUT_ERASE_OUT_OF_STOCK_MUTATION);
    const [loading, setIsLoading] = useState(false);

    const eraseOutOfStock = async () => {
        setIsLoading(true);

        return await fnDoMutation(mutationCheckoutEraseOutOfStock, {
            checkout: checkoutBase64Id,
        }).then((res) => {
            const newCheckoutData = get_(res, ['data', 'checkoutEraseOutOfStock', 'checkout']);

            if (typeof checkoutUpdate === 'function')
                checkoutUpdate({
                    newCheckoutData: newCheckoutData,
                    updateLines: true,
                });

            setIsLoading(false);
            return {
                res,
                newCheckoutData: newCheckoutData,
            };
        });
    };

    return {
        loading,
        eraseOutOfStock,
    };
}
