import { gql } from '@apollo/client';
import { CheckoutBasicFieldsFragment } from '../../fragments/checkout/CheckoutBasicFields.gql';
import { CheckoutPricingFieldsFragment } from '../../fragments/checkout/CheckoutPricingFields.gql';
import { LinesBasicFieldsFragment } from '../../fragments/checkout/LinesBasicFields.gql';
import { LinesExtendedFieldsFragment } from '../../fragments/checkout/LinesExtendedFields.gql';

const CHECKOUT_CHANGE_STORE_MUTATION = gql`
    ${CheckoutBasicFieldsFragment}
    ${CheckoutPricingFieldsFragment}
    ${LinesBasicFieldsFragment}
    ${LinesExtendedFieldsFragment}
    mutation ChangeStoreToCheckout(
        $checkoutId: ID!
        $zipCode: String!
        $erase: Boolean
        $withPricingFields: Boolean = false
    ) {
        checkoutChangeStore(checkoutId: $checkoutId, newZipCode: $zipCode, eraseLines: $erase) {
            checkout {
                ...CheckoutBasicFields
                ...CheckoutPricingFields @include(if: $withPricingFields)
                store {
                    id
                    name
                    algoliaIndex
                }
                pointOfSale {
                    id
                    name
                    defaultAlgoliaIndex
                    active
                    isDefault
                    isDefaultForUser
                    stockLocation {
                        id
                        name
                        address {
                            id
                            postalCode
                        }
                    }
                }
                lines {
                    ...LinesBasicFields
                    ...LinesExtendedFields
                }
            }
            errors {
                field
                message
            }
        }
    }
`;

export default CHECKOUT_CHANGE_STORE_MUTATION;
