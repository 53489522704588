import React, { useEffect } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import CommerceProvider from '@yema/commerce/CommerceProvider';
import useTracker from '@yema/commerce/hooks/tracking/useTracker';
import { useSelector } from '@yema/commerce/StoreClient';
import ModalEngineRender from 'components/ModalEngineRender';
import { PATH_CART, PATH_TERMS } from 'constants/paths';
import {
    DEFAULT_CHECKOUT_EMAIL,
    GOOGLE_MAPS_API_KEY,
    BREAKPOINT,
    BREAKPOINT_MD,
    CATALOG_LS,
} from 'constants/constants';
import 'public/css/loading_nprogress.css';
import 'designkit/src/styles/tailwind.scss';

// export function reportWebVitals(metric) {
//     switch (metric.name) {
//         case 'FCP':
//             // handle FCP results
//             break;
//         case 'LCP':
//             // handle LCP results
//             break;
//         case 'CLS':
//             // handle CLS results
//             break;
//         case 'FID':
//             // handle FID results
//             break;
//         case 'TTFB':
//             // handle TTFB results
//             break;
//         default:
//             // handle other results
//             break
//     }
// }

const Page = ({ children }) => {
    const router = useRouter();
    const { identify, pageView } = useTracker();
    const authInfos = useSelector(({ authentication }) => authentication);

    useEffect(() => {
        if (authInfos && authInfos.init) {
            identify(authInfos);
            pageView(router.asPath);
        }
    }, [authInfos, router.asPath]);

    useEffect(() => {
        const onStartLoading = () => NProgress.start();
        const onFinishLoading = () => NProgress.done();

        router.events.on('routeChangeStart', onStartLoading);
        router.events.on('routeChangeComplete', onFinishLoading);
        router.events.on('routeChangeError', onFinishLoading);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', onFinishLoading);
            router.events.off('routeChangeComplete', onFinishLoading);
            router.events.off('routeChangeError', onFinishLoading);
        };
    }, [router]);

    return (
        <>
            {children}
            <ModalEngineRender />
        </>
    );
};

const WithSegment = ({ children }) => {
    return (
        <>
            {children}
            {process.env.NEXT_PUBLIC_SEGMENT_API_KEY && (
                <Script id='withsegment' strategy='lazyOnload'>
                    {`!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.NEXT_PUBLIC_SEGMENT_API_KEY}";analytics.SNIPPET_VERSION="4.13.2";
                analytics.load("${process.env.NEXT_PUBLIC_SEGMENT_API_KEY}");
                analytics.page();
            }}();`}
                </Script>
            )}
        </>
    );
};

export default function App({ Component, pageProps }) {
    return (
        <WithSegment>
            <CommerceProvider
                pageProps={pageProps}
                commerceConfig={{
                    context: 'storefront',
                    storage: {
                        CATALOG_INDEX: null,
                        CATALOG_LS: CATALOG_LS,
                    },
                    values: {
                        GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY,
                        DEFAULT_EMAIL: DEFAULT_CHECKOUT_EMAIL,
                        BREAKPOINT: BREAKPOINT,
                        BREAKPOINT_MD: BREAKPOINT_MD,
                    },
                    paths: {
                        PATH_TERMS: PATH_TERMS,
                        PATH_CART: PATH_CART,
                        CHECKOUT_SUCCESS: '/carrito/compra-exitosa',
                    },
                }}
            >
                <Page>
                    <Component {...pageProps} />
                </Page>
            </CommerceProvider>
        </WithSegment>
    );
}
