import { useContext } from 'react';
import { CheckoutContext } from './CheckoutProvider';
import useCheckoutReset from './useCheckoutReset';

export default function useCheckoutError() {
    const { checkoutSetError } = useContext(CheckoutContext);
    const checkoutReset = useCheckoutReset();

    function setCheckoutError({
        withCheckoutReset = false,
        title,
        errorType,
        errorDescription,
        buttonText = null,
        buttonOnClick = null,
        errorReport = null,
    }) {
        if (withCheckoutReset) checkoutReset();

        checkoutSetError({
            title,
            errorType,
            errorDescription,
            buttonText,
            buttonOnClick,
            errorReport,
        });
    }

    return {
        setCheckoutError,
    };
}
