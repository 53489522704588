import atob from 'atob';

/**
 * Safe catalog an item in a nested object
 * @param keys
 * @param object
 * @param defaultValue
 * @returns {*}
 */
export function get_(object, keys, defaultValue = null) {
    if (!Array.isArray(keys))
        throw new Error('Second argument of _get function should be an array');

    if (!keys.length) return object || defaultValue;

    return keys.reduce(
        (xs, x) => (xs && Object.prototype.hasOwnProperty.call(xs, x) ? xs[x] : defaultValue),
        object
    );
}

/**
 * Decode checkout ID from base 64
 * @param id
 * @param defaultReturn
 * @returns {null|*|string}
 */
export function decodeIdBase64(id, defaultReturn = null) {
    try {
        let decodedString = atob(id);

        if (decodedString.split(':')[1] !== undefined) {
            return decodedString.split(':')[1];
        }
    } catch (e) {
        return defaultReturn;
    }

    return defaultReturn;
}

/**
 * Slugify a string
 * @param string
 * @returns {string}
 */
export function slugify(string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    /* eslint-disable */
    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, '-and-') // Replace & with 'and'
        .replace(/[^\w\-]+/g, '') // Remove all non-word characters
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
    /* eslint-enable */
}

export function removeAccentsLowerCase(s, allowEne = false) {
    let lowercaseString = s.toLowerCase();

    lowercaseString = lowercaseString.replace(new RegExp('[àáâãäå]', 'g'), 'a');
    lowercaseString = lowercaseString.replace(new RegExp('æ', 'g'), 'ae');
    lowercaseString = lowercaseString.replace(new RegExp('ç', 'g'), 'c');
    lowercaseString = lowercaseString.replace(new RegExp('[èéêë]', 'g'), 'e');
    lowercaseString = lowercaseString.replace(new RegExp('[ìíîï]', 'g'), 'i');
    lowercaseString = lowercaseString.replace(new RegExp('[òóôõö]', 'g'), 'o');
    lowercaseString = lowercaseString.replace(new RegExp('œ', 'g'), 'oe');
    lowercaseString = lowercaseString.replace(new RegExp('[ùúûü]', 'g'), 'u');
    lowercaseString = lowercaseString.replace(new RegExp('[ýÿ]', 'g'), 'y');
    if (!allowEne) {
        lowercaseString = lowercaseString.replace(new RegExp('ñ', 'g'), 'n');
    }

    return lowercaseString;
}

export const htmlToPlainText = (content) => {
    if (!content || content === '') return '';

    let plainText = content.replace(/<[^>]+>/g, '');
    plainText = plainText.replace(/&nbsp;/g, '');
    return plainText;
};

/**
 * Chunk array into same size arrays
 * @param array
 * @param size
 * @returns {[]}
 */
export function chunkArray(array, size) {
    const chunked_arr = [];
    let index = 0;
    while (index < array.length) {
        chunked_arr.push(array.slice(index, size + index));
        index += size;
    }
    return chunked_arr;
}

/**
 * Shuffle array with fisher-yates algorithm
 * @param array
 */
export function shuffle(array) {
    let arr = [...array];
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
    }
    return arr;
}

/**
 * Lets you select keys from an object
 *    Example:
 *     const data = { a: 1, b: 2, c: 3 };
 *     console.debug(pick(data, ['a', 'c']));
 * @param obj
 * @param keys
 * @returns {{}}
 */
export function pickKeysFromObject(obj, keys = []) {
    return Object.keys(obj)
        .filter((i) => keys.includes(i))
        .reduce((acc, key) => {
            acc[key] = obj[key];
            return acc;
        }, {});
}

/**
 * Function to alphabetically sort object by keys
 * @param obj
 * @returns {{}}
 */
export function sortAlphabeticallyObjectByKey(obj) {
    if (!obj) return {};
    let ordered = {};
    Object.keys(obj)
        .sort()
        .forEach((key) => (ordered[key] = obj[key]));
    return ordered;
}

/**
 * Function that check if a value is a number
 * isNumber(42)         --> true
 * isNumber(4.2)        --> true
 * isNumber(0)          --> true
 * isNumber(null)       --> false
 * isNumber(undefined)  --> false
 * @param value
 */
export function isNumber(value) {
    return typeof value === 'number';
}

export default {
    get_,
    decodeIdBase64,
    slugify,
    removeAccentsLowerCase,
    htmlToPlainText,
    chunkArray,
    shuffle,
    isNumber,
    pickKeysFromObject,
};
