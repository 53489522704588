import { gql } from '@apollo/client';
import { ProductFieldsForCheckoutFragment } from './ProductFieldsForCheckout.gql';

/**
 * GraphQL Fragment
 *
 */
export const BundleFieldsForCheckoutFragment = gql`
    ${ProductFieldsForCheckoutFragment}
    fragment BundleFieldsForCheckout on Bundle {
        id
        name
        brand
        slug
        sku
        stockQuantity
        totalPrice
        checkoutMaxQuantity
        images {
            alt
            url
            order
        }
        applicableRules {
            id
            title
            benefits {
                id
                discountString
                type
            }
        }
        products {
            ...ProductFieldsForCheckout
            quantity
        }
        rawId
        plmId
        gtin @client
        href @client
        mainImage @client
        mainImageAlt @client
        sellingValue @client
        sellingUnit @client
    }
`;
