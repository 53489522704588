import { useContext, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { get_ } from 'sf-modules';
import { fnDoMutation } from '../../utils/fnDoMutation';
import { trackError } from '../../utils/error';
import * as transform from '../../transformers';
import useCommerce from '../../useCommerce';
import useCheckout from './useCheckout';
import useCheckoutUpdate from './useCheckoutUpdate';
import { CheckoutContext } from './CheckoutProvider';
import CHECKOUT_VOUCHER_UPDATE_MUTATION from '../../graphql/mutations/checkout/checkoutVoucherUpdate.gql';

const VOUCHER_STATE_RESET = { voucher: null, errors: [] };

export default function useVoucher() {
    const { checkout: checkoutData } = useCommerce();
    const { checkoutBase64Id } = useCheckout();
    const checkoutUpdate = useCheckoutUpdate();
    const { voucher, voucherStateUpdate } = useContext(CheckoutContext);

    const [mutationUpdateDiscountVoucher] = useMutation(CHECKOUT_VOUCHER_UPDATE_MUTATION);

    const voucherSet = async (voucherCode) => {
        const voucherSanitized = voucherCode && voucherCode.trim();
        if (!voucherSanitized) return;

        return await fnDoMutation(mutationUpdateDiscountVoucher, {
            checkout: checkoutBase64Id,
            voucher: voucherSanitized,
        }).then((response) => {
            const newCheckoutData = get_(response, ['data', 'checkoutUpdateVoucher', 'checkout']);
            const errors = get_(response, ['data', 'checkoutUpdateVoucher', 'errors'], []);

            checkoutUpdate({
                newCheckoutData: newCheckoutData,
                updateLines: true,
            });
            let localState;

            if (!newCheckoutData && !errors.length) {
                trackError({
                    label: 'Voucher set error',
                    extras: {
                        location: 'useVoucher > voucherSet',
                        voucher: voucherCode,
                    },
                });
                localState = VOUCHER_STATE_RESET;
            } else {
                const discountName = get_(newCheckoutData, ['discountName'], '');
                localState = {
                    ...(newCheckoutData
                        ? transform.checkoutVoucher(newCheckoutData)
                        : {
                              voucher: {
                                  voucherCode: voucherSanitized,
                                  voucherName: discountName,
                              },
                          }),
                    errors: errors,
                };
            }

            voucherStateUpdate(localState);
            return localState;
        });
    };

    const voucherRemove = async (voucherCode) => {
        return await fnDoMutation(mutationUpdateDiscountVoucher, {
            checkout: checkoutBase64Id,
            voucher: null,
        }).then((response) => {
            const newCheckoutData = get_(response, ['data', 'checkoutUpdateVoucher', 'checkout']);
            const errors = get_(response, ['data', 'checkoutUpdateVoucher', 'errors'], []);

            if (!newCheckoutData || errors.length) {
                trackError({
                    label: 'Voucher remove error',
                    extras: {
                        location: 'useVoucher > voucherRemove',
                        voucher: voucherCode,
                        errors: errors,
                    },
                });
                return false;
            }

            /** If same code is returned */
            let voucherStillApplied = get_(newCheckoutData, ['voucherCode']);
            if (voucherStillApplied === voucherCode) return false;

            voucherStateUpdate(VOUCHER_STATE_RESET);
            checkoutUpdate({ newCheckoutData: newCheckoutData });

            return true;
        });
    };

    useEffect(() => {
        if (!voucher || !voucher.voucher) {
            voucherStateUpdate({
                ...transform.checkoutVoucher(checkoutData),
                errors: [],
            });
        }
    }, []);

    return {
        voucher,
        voucherSet,
        voucherRemove,
    };
}
