import { gql } from '@apollo/client';

export const CheckoutUserFieldsFragment = gql`
    fragment CheckoutUserFields on Checkout {
        user {
            id
            firstName
            lastName
        }
    }
`;
