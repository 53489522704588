import { PRODUCT_EXAMPLE } from './productExample';
import { PRODUCT_GIFT } from './productGift';
import { PRODUCT_BUNDLE } from './productIsBundle';
import { PRODUCT_EMPTY } from './productEmpty';

import transformProductOrBundleFromPIM from './productOrBundleFromPIM';
import transformProductFromAlgolia from './productFromAlgolia';
import transformProductFromPIM from './productFromPIM';
import transformBundleFromPIM from './bundleFromPIM';
import transformCheckoutLineFromPIM from './checkoutLine';
import transformOrder from './order';

import { getCheckoutMaxQuantity, getProductBreadcrumb, parseAvailability } from './productUtils';

import {
    formatAddress,
    buildFormattedAddress,
    transformAddress,
    reverseTransformAddress,
} from './address';

export default {
    PRODUCT_EXAMPLE,
    PRODUCT_GIFT,
    PRODUCT_BUNDLE,
    PRODUCT_EMPTY,

    transformProductOrBundleFromPIM,
    transformProductFromAlgolia,
    transformProductFromPIM,
    transformBundleFromPIM,
    transformCheckoutLineFromPIM,
    transformOrder,

    getCheckoutMaxQuantity,
    getProductBreadcrumb,
    parseAvailability,

    formatAddress,
    buildFormattedAddress,
    transformAddress,
    reverseTransformAddress,
};
