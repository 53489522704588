import { get_ } from '../tools';
import money from '../money';
import rules from '../cartRules';
import { parseAvailability, getBlobs, getCertifications, getFacets } from './productUtils';
import { PRODUCT_EMPTY } from './productEmpty';

const transformBundleFromPIM = ({
    bundle,
    quantity = 0,
    checkoutLine = null,
    availability = null,
    nullIfDoesNotExists = false,
}) => {
    if (!bundle && nullIfDoesNotExists) return null;

    if (!bundle)
        return {
            ...PRODUCT_EMPTY,
            ...bundle,
            sku: 'inexistent',
            quantity: quantity,
            quantityDisplay: quantity,
        };

    const bundlePrice = get_(checkoutLine, ['bundlePricing', 'price'], bundle.totalPrice);
    const bundlePriceUndiscounted = get_(checkoutLine, ['bundlePricing', 'priceUndiscounted']);
    const billedQuantity = get_(checkoutLine, ['billedQuantity']);

    const totalPriceUndiscounted = billedQuantity * bundlePrice;
    const parsedPricing = {
        discount: bundlePriceUndiscounted
            ? money.getDiscount(bundlePrice, bundlePriceUndiscounted)
            : null,
        price: bundlePrice,
        priceUndiscounted: bundlePriceUndiscounted,
        formattedPrice: money.formatMoney(bundlePrice),
        formattedPriceUndiscounted: bundlePriceUndiscounted
            ? money.formatMoney(bundlePriceUndiscounted)
            : null,
        formattedTotalPrice: money.formatMoney(get_(checkoutLine, ['totalPrice'])),
        formattedTotalPriceUndiscounted: money.formatMoney(totalPriceUndiscounted),
    };

    const catalogPrice = checkoutLine
        ? {
              sku: checkoutLine.sku,
              isBundle: checkoutLine.isBundle,
              price: get_(checkoutLine, ['bundlePricing', 'priceUndiscounted']),
              discountedPrice: get_(checkoutLine, ['bundlePricing', 'price']),
              baseMeasurePrice: null,
              baseMeasureDiscountedPrice: null,
          }
        : {
              sku: bundle.sku ?? bundle.gtin,
              isBundle: true,
              price: parsedPricing.priceUndiscounted || parsedPricing.price,
              discountedPrice: parsedPricing.price,
              baseMeasurePrice: null,
              baseMeasureDiscountedPrice: null,
          };

    const stock = availability
        ? availability.itemsAvailable
        : bundle.stockQuantity
        ? bundle.stockQuantity
        : null;

    const parsedAvailability = parseAvailability(null, bundle, availability);

    // Find M_X_N type benefit, if applicable
    const oRules = new rules.RulesList(bundle.applicableRules);
    const applicableRuleWithBenefitTypeMxN = oRules.getRulesMatchingBenefitType('M_X_N');
    const applicableBenefitWithTypeMxN = get_(
        applicableRuleWithBenefitTypeMxN,
        [0, 'benefits'],
        []
    ).find((x) => x.type === 'M_X_N');

    const aisles = checkoutLine
        ? get_(checkoutLine, ['variant', 'product', 'aisles'], [])
        : get_(bundle, ['aisles'], []);
    const mainAisle = aisles.find((x) => x.main);
    const mainCategory = [
        get_(mainAisle, ['firstLevel', 'name']),
        get_(mainAisle, ['secondLevel', 'name']),
        get_(mainAisle, ['thirdLevel', 'name']),
    ]
        .filter(Boolean)
        .join(' > ');

    return {
        ...bundle,
        ...checkoutLine,
        isBundle: true,
        type: 'bundle',
        availability: parsedAvailability,
        applicableRules: bundle.applicableRules ?? '',
        pricing: parsedPricing,
        catalogPrice: catalogPrice,
        backgroundColor: '',
        isAvailable: true,
        quantity: quantity,
        quantityDisplay: get_(checkoutLine, ['quantityDisplay'], quantity),
        seoTitle: '',
        badgePromotion: get_(applicableBenefitWithTypeMxN, ['discountString']),
        stock: stock, // @TODO Ludo: should deprecate this field
        aisles: aisles,
        category: mainCategory,
        blobs: getBlobs(bundle.blobs),
        certifications: getCertifications(bundle.certifications),
        facets: getFacets(bundle.facets),
        parsedUnitPricingBaseMeasure: null,
        skus:
            bundle.products && bundle.products.length > 0
                ? bundle.products.map((product) => product.gtin)
                : [],
    };
};

export default transformBundleFromPIM;
