import { PRODUCT_EMPTY } from './productEmpty';
import { get_ } from '../tools';
import money from '../money';
import rules from '../cartRules';
import {
    parseAvailability,
    getBlobs,
    getCertifications,
    getFacets,
    getReviews,
} from './productUtils';

const transformProductFromPIM = ({
    product,
    quantity = 0,
    checkoutLine = null,
    availability = null,
    nullIfDoesNotExists = false,
}) => {
    if (!product && nullIfDoesNotExists) return null;

    if (!product)
        return {
            ...PRODUCT_EMPTY,
            ...product,
            sku: 'inexistent',
            quantity: quantity,
            quantityDisplay: quantity,
        };

    const EMPTY_PRICING = {
        price: null,
        priceUndiscounted: null,
        baseMeasurePrice: null,
        baseMeasurePriceUndiscounted: null,
        // basePrice: null,
        formattedBasePrice: null,
        baseUndiscountedPrice: null,
        formattedBaseUndiscountedPrice: null,
    };

    const pricing = checkoutLine
        ? get_(checkoutLine, ['variantPricing'], EMPTY_PRICING)
        : get_(product, ['variant', 'pricing'], EMPTY_PRICING);

    const productsDiscounted = checkoutLine ? checkoutLine.productsDiscounted : null;
    const billedQuantity = get_(checkoutLine, ['billedQuantity']);
    const totalPriceUndiscounted = billedQuantity * pricing.price;

    const sellingType =
        product.sellingType || (product.stockUnit === 'kg' ? 'por-peso' : 'por-pieza');
    const baseMeasurePricing =
        sellingType !== 'por-pieza'
            ? {
                  // basePrice: pricing.baseMeasurePrice,
                  baseUndiscountedPrice: pricing.baseMeasurePriceUndiscounted,
                  formattedBasePrice: money.formatMoney(pricing.baseMeasurePrice),
                  formattedBaseUndiscountedPrice: money.formatMoney(
                      pricing.baseMeasurePriceUndiscounted
                  ),
              }
            : null;

    const parsedPricing = {
        discount: money.getDiscount(pricing.price, pricing.priceUndiscounted),
        price: pricing.price,
        priceUndiscounted: pricing.priceUndiscounted,
        formattedPrice: money.formatMoney(pricing.price),
        formattedPriceUndiscounted: money.parseUndiscountedPrice(
            pricing.price,
            pricing.priceUndiscounted
        ),
        formattedTotalPrice: money.formatMoney(get_(checkoutLine, ['totalPrice'])),
        formattedTotalPriceUndiscounted: money.formatMoney(totalPriceUndiscounted),
        ...baseMeasurePricing,
    };

    const catalogPrice = checkoutLine
        ? {
              sku: checkoutLine.sku,
              isBundle: checkoutLine.isBundle,
              price: get_(checkoutLine, ['variantPricing', 'priceUndiscounted']),
              discountedPrice: get_(checkoutLine, ['variantPricing', 'price']),
              baseMeasurePrice: get_(checkoutLine, [
                  'variantPricing',
                  'baseMeasurePriceUndiscounted',
              ]),
              baseMeasureDiscountedPrice: get_(checkoutLine, [
                  'variantPricing',
                  'baseMeasurePrice',
              ]),
          }
        : {
              sku: product.gtin,
              isBundle: false,
              price: parsedPricing.priceUndiscounted,
              discountedPrice: parsedPricing.price,
              baseMeasurePrice: baseMeasurePricing?.baseUndiscountedPrice || null,
              // baseMeasureDiscountedPrice: baseMeasurePricing?.basePrice || null,
          };

    // Find M_X_N type benefit, if applicable
    const oRules = new rules.RulesList(product.applicableRules);
    const applicableRuleWithBenefitTypeMxN = oRules.getRulesMatchingBenefitType('M_X_N');
    const applicableBenefitWithTypeMxN = get_(
        applicableRuleWithBenefitTypeMxN,
        [0, 'benefits'],
        []
    ).find((x) => x.type === 'M_X_N');

    const aisles = get_(product, ['aisles'], []);
    const mainAisle = aisles.find((x) => x.main);
    const mainCategory = [
        get_(mainAisle, ['firstLevel', 'name']),
        get_(mainAisle, ['secondLevel', 'name']),
        get_(mainAisle, ['thirdLevel', 'name']),
    ]
        .filter(Boolean)
        .join(' > ');

    const parsedProduct = {
        ...product,
        ...checkoutLine,
        sellingType: sellingType,
        isBundle: false,
        availability: null,
        applicableRules: product.applicableRules ?? '',
        pricing: parsedPricing,
        catalogPrice: catalogPrice,
        isAvailable: true,
        productsGifted: checkoutLine ? checkoutLine.productsGifted : null,
        productsDiscounted: productsDiscounted,
        billedQuantity: billedQuantity,
        isGift: checkoutLine ? checkoutLine.isGift : false,
        quantity: quantity,
        quantityDisplay: get_(checkoutLine, ['quantityDisplay'], quantity),
        badgePromotion: get_(applicableBenefitWithTypeMxN, ['discountString']),
        aisles: aisles,
        category: mainCategory,
        blobs: getBlobs(product.blobs),
        certifications: getCertifications(product.certifications),
        facets: getFacets(product.facets),
        reviews: {
            average: product.reviewAverage ?? 0,
            count: product.reviewCount ?? 0,
            oneStarReviewCount: product.oneStarReviewCount ?? 0,
            twoStarReviewCount: product.twoStarReviewCount ?? 0,
            threeStarReviewCount: product.threeStarReviewCount ?? 0,
            fourStarReviewCount: product.fourStarReviewCount ?? 0,
            fiveStarReviewCount: product.fiveStarReviewCount ?? 0,
            items: getReviews(get_(product, ['reviews', 'edges'], [])),
        },
    };

    parsedProduct.availability = parseAvailability(null, parsedProduct, availability);
    return parsedProduct;
};

export default transformProductFromPIM;
