import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { get_ } from 'sf-modules';
import useStateEngine from '../../useStateEngine';
import useCommerce from '../../useCommerce';
import useSetShippingAddress from './useSetShippingAddress';
import { useCheckoutFunnel } from './useCheckoutFunnel';
import ADDRESS_LIST_QUERY from '../../graphql/queries/user/address-list.gql';

export default function useCustomerAddresses() {
    const userIsLoggedIn = useSelector(({ authentication }) => !!authentication.token);

    const { loading, data } = useQuery(ADDRESS_LIST_QUERY, {
        variables: {},
        fetchPolicy: 'network-only',
        ssr: false,
        skip: !userIsLoggedIn,
    });

    const [{ catalog }] = useStateEngine();
    const { nextFunnelStep } = useCheckoutFunnel();
    const { checkout: checkoutData } = useCommerce();
    const { setShippingAddress } = useSetShippingAddress();

    const [isInitializing, setIsInitializing] = useState(true);
    const [addresses, setAddresses] = useState([]);
    const [selected, setSelected] = useState(null);

    const selectAddress = (addr) => {
        setSelected(addr);
    };

    useEffect(() => {
        if (!loading && data) {
            if (!isInitializing) setIsInitializing(true);

            let customerAddresses = get_(data, ['me', 'addresses'], []);
            setAddresses(customerAddresses);

            // Force show new address form in case user does not have registered addresses
            if (customerAddresses.length === 0 && selected !== 'new') {
                setSelected('new');
                setIsInitializing(false);
                return;
            }

            // Auto select address
            if (customerAddresses.length) {
                let checkoutAddressId = get_(checkoutData, ['shippingAddress', 'addressCopy']);
                let catalogAddressId = get_(catalog, ['addressId']);
                let catalogZipCode = get_(catalog, ['zipCode']);
                let selectedAddressId = checkoutAddressId || catalogAddressId;

                let customerSelectedAddress;

                // Search if selected delivery address exists in user's addresses
                if (selectedAddressId)
                    customerSelectedAddress = customerAddresses.find(
                        (a) => a.id === selectedAddressId
                    );

                // If no address selected or selected address does not exists, try to select based on zipCode
                if ((!selectedAddressId || !customerSelectedAddress) && catalogZipCode)
                    customerSelectedAddress = customerAddresses.find(
                        (a) => a.postalCode === catalogZipCode
                    );

                // ...else, if delivery zipcode is set but no address match, we leave it blank
                // if there is no delivery zipcode set, we continue with automatic selection
                if (!catalogZipCode) {
                    // ...else, we select default address
                    if (!customerSelectedAddress)
                        customerSelectedAddress = customerAddresses.find(
                            (a) => a.id === get_(data, ['me', 'defaultShippingAddress', 'id'])
                        );

                    // ...or, finally, we select the first address
                    if (!customerSelectedAddress) customerSelectedAddress = customerAddresses[0];
                }

                if (customerSelectedAddress && customerSelectedAddress.id) {
                    setSelected(customerSelectedAddress.id);

                    // Call Saleor mutation only if address id is not already the same
                    if (checkoutAddressId !== customerSelectedAddress.id) {
                        setShippingAddress({
                            address: customerSelectedAddress,
                            forceUpdateCheckout: true,
                        }).then(() => {
                            // Skip to next step, in case there was no address previously set in checkout
                            if (!checkoutAddressId) nextFunnelStep();

                            setIsInitializing(false);
                        });
                    } else {
                        setIsInitializing(false);
                    }

                    return;
                }
            }

            setIsInitializing(false);
        }
    }, [loading, data]);

    return {
        loading: loading || isInitializing,
        addresses,
        selected,
        selectAddress,
    };
}
